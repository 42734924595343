import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { Variant } from '../global-nav.types';

@Component({
  selector: 'app-profile-header-dropdown',
  templateUrl: './profile-header-dropdown.component.html',
  styleUrls: ['./profile-header-dropdown.component.scss'],
})
export class ProfileHeaderDropdownComponent {
  @Input() userName: string;
  @Input() accountAriaLabel: string;
  @Input() items: {
    name: string;
    showItem: boolean;
    onClick: null | (() => void);
    disabled: boolean;
  }[] = [];
  @Input() variantType: Variant;
  faUser = faUser;
  isDropdownOpen = false;
  isHoverDropdownListButton = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.isDropdownOpen = false;
    }
  }

  setOpenDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  setHoverDropdownListButton(value: boolean) {
    this.isHoverDropdownListButton = value;
  }
}
