export enum FL_DATA_ITEM_IDS {
    AGENT_NAME_FIRST = 'Agent_FirstName',
    AGENT_NAME_LAST = 'Agent_LastName',
    AGENT_ID = 'Agent_ID',
    AGENT_FIRM_NAME = 'Agent_FirmName',
    AGENT_EMAIL_PRIMARY = 'Agent_Primary_EmailAddress',
    AGENT_PHONE_NUMBER = 'Agent_PhoneNumber',
    AGENT_FIRM_STREET = 'Agent_FirmAddress1',
    AGENT_FIRM_CITY = 'Agent_FirmAddress_City',
    AGENT_FIRM_STATE = 'Agent_FirmAddress_State',
    AGENT_FIRM_ZIP = 'Agent_FirmAddress_Zipcode',

    OWNER_TYPE = 'Owner_Type',
    OWNER_NAME_FIRST = 'Owner_FirstName',
    OWNER_NAME_LAST = 'Owner_LastName',
    OWNER_DOB = 'Owner_DOB',
    OWNER_GENDER = 'Owner_Gender',

    ANNUITY_TAX_QUAL = 'Annuity_TaxQualificaton',

    ANNUITY_PREMIUM_INITIAL_AMOUNT = 'Annuity_Premium_Initial_Amount'
}
export class FirelightDataItemModel {

    constructor(public DataItemId: string | FL_DATA_ITEM_IDS,
                public Value: string) {}
}
