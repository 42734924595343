import {
  FirelightDataItemModel,
  FL_DATA_ITEM_IDS
} from '@product-marketplace/annuity-product/annuity-product-view/firelight/firelight-models/firelight-data-item.model';
import {
  AnnuityIllustrationSummaryModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-illustration-summary/annuity-illustration-summary.model';
import * as moment from 'moment';
import { Utilities } from '@common/utilities/utilities';
import {
  AnnuityUserInputModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-user-input.model';
import {
  AnnuityOesRequestModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-oes-request.model';

export enum FL_ACORD_TYPES_CODES {
  APPLICATION = 103,
  ILLUSTRATION = 111
}


export interface FirelightAccessToken {
  access_token: string,
  token_type: string,
  expires_in: number
}
export interface FirelightActivityResponseModel {
  AccessToken: FirelightAccessToken,
  ActivityId: string,
  Id: string,
  ResultCode: number,
  Messages: any[],
  ExceptionError: any | null,
  OesBdId: number,
  OesBdName: string,
  FinancialAdviser: any | null,
  FinancialAdviserName: any | null
}


export class FirelightActivityRequestModel extends AnnuityUserInputModel {
  id: string;
  jurisdiction: string;
  state: string;
  cusip: string;
  activityName: string;
  acordTypeCode: number;
  carrierCode: string;
  vendorUniqueId: string;
  dataItems: Array<FirelightDataItemModel>;
  productType: string | null;

  static fromIllustrationSummary(summary: AnnuityIllustrationSummaryModel): FirelightActivityRequestModel {
    const firelightActivityRequestModel = new this();
    firelightActivityRequestModel.id = summary?.annuityParams.parameters.id || '8ec75158-a2e0-4dea-9536-5d571aedd2212';
    firelightActivityRequestModel.jurisdiction = summary?.annuityParams.parameters.jurisdiction || 7;
    firelightActivityRequestModel.cusip = summary?.annuityParams.parameters.cusip || 'FSEFA0001';
    // firelightActivityRequestModel.acordTypeCode = summary?.annuityParams.parameters.acordTypeCode || 111;
    firelightActivityRequestModel.acordTypeCode = 111;
    firelightActivityRequestModel.carrierCode = summary?.annuityParams.parameters.carrierCode || 'ITC';
    firelightActivityRequestModel.activityName = summary?.annuityParams.parameters.activityName || 'illustrationActivityOne';
    firelightActivityRequestModel.dataItems = firelightActivityRequestModel.buildDataItemsFromSummary(summary);
    return firelightActivityRequestModel;
  }

  static fromOesRequestModel(annuityOesRequestModel: AnnuityOesRequestModel): FirelightActivityRequestModel {
    const firelightActivityRequestModel = Object.assign(new this(), annuityOesRequestModel?.annuityUserInput);
    firelightActivityRequestModel.acordTypeCode = FL_ACORD_TYPES_CODES.APPLICATION;
    firelightActivityRequestModel.cusip = annuityOesRequestModel?.annuityProduct?.cusip;
    firelightActivityRequestModel.vendorUniqueId = annuityOesRequestModel?.annuityProduct?.vendorUniqueId;
    return firelightActivityRequestModel;
  }

  setActivityNameWithCarrier(carrier: string, customActivityName?: string) {
    const formattedCarrier = carrier ? `_${carrier.replace(/\s+/g, '')}` : '';
    const appendedDate = `_${moment(new Date()).format('MM-DD-YYYY_HHmm')}`;
    this.activityName = customActivityName || `clientApp${formattedCarrier}${appendedDate}`;
  }

  private buildDataItemsFromSummary(summary: AnnuityIllustrationSummaryModel): FirelightDataItemModel[] {
    const firelightDataItemModels = [];
    const [agentFirstName, ...agentRest] = summary.agentName.split(' ');
    const [ownerFirstName, ...ownerRest] = summary.name.split(' ');
    const ownerGender = summary.sex === 'Male' ? '1' : '2';
    const ownerDob = moment().subtract(summary.age, 'years').format('MM/DD/YYYY');
    const initialPremium = Utilities.stripCurrencyFormatting(summary.initialPremium);
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_NAME_FIRST, agentFirstName));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_NAME_LAST, agentRest.join(' ')));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_ID, '99996666'));

    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_FIRM_NAME, 'Luma Fintech'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_PHONE_NUMBER, '1234567890'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_FIRM_STREET, '425 Walnut Street'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_FIRM_CITY, 'Cincinnati'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_FIRM_STATE, '41'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.AGENT_FIRM_ZIP, '45202'));

    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.OWNER_TYPE, '-1'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.OWNER_NAME_FIRST, ownerFirstName));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.OWNER_NAME_LAST, ownerRest.join(' ')));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.OWNER_DOB, ownerDob));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.OWNER_GENDER, ownerGender));

    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.ANNUITY_TAX_QUAL, '45'));
    firelightDataItemModels.push(new FirelightDataItemModel(FL_DATA_ITEM_IDS.ANNUITY_PREMIUM_INITIAL_AMOUNT, initialPremium));
    return firelightDataItemModels;
  }
}

