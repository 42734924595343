import { BaseFilterModel, FilterFields, FilterGroup, RangeFilterType, TwoFieldFilterType } from './base-filter.model';
import { get as lodashGet, map as lodashMap } from 'lodash';
import { FilterNumberIs } from './number-input-filter';
import { RangeFilterModel } from './range-filter';
import { TextSearchFilterModel } from './text-filter';

export class FilterMethods {
  static fullSearch = (fieldValue, searchValue) => fieldValue === searchValue;
  static endSearch = (fieldValue, searchValue) => fieldValue.endsWith(searchValue);
  static preSearch = (fieldValue, searchValue) => fieldValue.startsWith(searchValue);
  static someSearch = (fieldValue, searchValue) => fieldValue.includes(searchValue);

  static get searchMethodMap() {
    return new Map<string, (fieldValue, searchValue) => boolean>([
      ['pre', this.preSearch],
      ['full', this.fullSearch],
      ['some', this.someSearch],
      ['end', this.endSearch]
    ]);
  }

  static getFieldsToCheck(dataToBeFiltered: any, fieldsToBeFiltered: FilterFields[], useFirstNonNullField = false) {
    if (useFirstNonNullField) {
      for (const field of fieldsToBeFiltered) {
        const value = this.getFieldFromData(dataToBeFiltered, field);
        if (value != null) {
          return [value];
        }
      }
      return [null];
    }
    return fieldsToBeFiltered.flatMap(fields => {
      return this.getFieldFromData(dataToBeFiltered, fields);
    });
  }

  static getFieldFromData(dataToBeFiltered: any, field: FilterFields) {
    if (typeof field === 'string') {
      return lodashGet(dataToBeFiltered, field);
    } else if (field.arrayObjectField || field.arrayProperty) {
      const newValue = lodashGet(dataToBeFiltered, field.arrayProperty);
      if (Array.isArray(newValue)) {
        return lodashMap(newValue, field.arrayObjectField);
      } else {
        return lodashGet(newValue, field.arrayObjectField);
      }
    } else if (field.minField && field.maxField) {
      const min = +lodashGet(dataToBeFiltered, field.minField);
      const max = +lodashGet(dataToBeFiltered, field.maxField);
      return {min, max};
    }
  }

  static applyTextFilter(baseFilterModel: BaseFilterModel<any>, dataToBeFiltered: any[], filterType: 'pre' | 'full' | 'some' | 'end' = 'some') {
    const {fieldsToCheck, filterValues, useFirstNonNullField} = baseFilterModel;
    const filterMethod = this.searchMethodMap.get(filterType);
    return dataToBeFiltered.filter(data => {
      return this.findSomeTextMatches(filterValues, data, fieldsToCheck, filterMethod, useFirstNonNullField);
    });
  }

  static doesTextFilterApply(searchValues: string[], dataToBeFiltered: any[],
                             fieldsToBeFiltered: FilterFields[], useFirstNonNullField: boolean, filterType: 'pre' | 'full' | 'some' | 'end' = 'some'): boolean {
    const filterMethod = this.searchMethodMap.get(filterType);
    return dataToBeFiltered.some(data => {
      return this.findSomeTextMatches(searchValues, data, fieldsToBeFiltered, filterMethod, useFirstNonNullField);
    });
  }

  static doesTextListFilterApply(baseFilterModel: BaseFilterModel<any>, dataToBeFiltered: any[]): boolean {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.some(data => {
      return this.getFieldsToCheck(data, fieldsToCheck, useFirstNonNullField).some(fieldValue => {
        return filterValues.includes(fieldValue);
      });
    });
  }

  private static findSomeTextMatches(searchValues: string[], data: any, fieldsToBeFiltered: FilterFields[], filterMethod: (fieldValue, searchValue) => boolean, useFirstNonNullField: boolean) {
    return this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField).some(dataField => {
      if (dataField && typeof dataField === 'string') {
        return searchValues.some(value => {
          return filterMethod(dataField, value);
        });
      }
    });
  }

  // Yes/No Methods
  static applyYesNoFilter(searchValues: boolean[],
                          dataToBeFiltered: any[],
                          fieldsToBeFiltered: FilterFields[],
                          useFirstNonNullField: boolean,
                          additionalNoValues: string[] = ['N/A', '-']) {
    return dataToBeFiltered.filter(data => {
      return this.findSomeYesNoValues(searchValues, data, fieldsToBeFiltered, additionalNoValues, useFirstNonNullField);
    });
  }

  static doesYesNoFilterApply(searchValues: boolean[], dataToBeFiltered: any[],
                              fieldsToBeFiltered: FilterFields[],
                              useFirstNonNullField: boolean,
                              additionalNoValues: string[] = ['N/A', '-']): boolean {
    return dataToBeFiltered.some(data => {
      return this.findSomeYesNoValues(searchValues, data, fieldsToBeFiltered, additionalNoValues, useFirstNonNullField);
    });
  }

  private static findSomeYesNoValues(searchValues: boolean[],
                                     data: any,
                                     fieldsToBeFiltered: FilterFields[],
                                     additionalNoValues: string[],
                                     useFirstNonNullField: boolean) {
    return this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField).some(dataField => {
      return searchValues.some(searchValue => {
        if (!searchValue && (!dataField || additionalNoValues.includes(dataField))) {
          return true;
        } else if (searchValue && dataField && !additionalNoValues.includes(dataField)) {
          return true;
        }
      });
    });
  }


  // Slider Methods
  static applySliderFilter(searchValues: RangeFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], useFirstNonNullField: boolean, filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    return dataToBeFiltered.filter(data => {
      return this.findSomeSliderRanges(searchValues, data, fieldsToBeFiltered, filterType, useFirstNonNullField);
    });
  }

  static doesSliderRangeFilterApply(baseFilterModel: BaseFilterModel<any>,
                                    dataToBeFiltered: any[],
                                    filterType: 'inclusive' | 'exclusive' = 'inclusive'): boolean {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.some(data => {
      return this.findSomeSliderRanges(filterValues, data, fieldsToCheck, filterType, useFirstNonNullField);
    });
  }
  static doesSliderPercentRangeFilterApply(baseFilterModel: BaseFilterModel<any>,
                                           dataToBeFiltered: any[],
                                           filterType: 'inclusive' | 'exclusive' = 'inclusive'): boolean {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.some(data => {
      return this.findSomeSliderPercentRanges(filterValues, data, fieldsToCheck, useFirstNonNullField, filterType);
    });
  }

  private static findSomeSliderPercentRanges(searchValues: RangeFilterType[], data: any, fieldsToBeFiltered: FilterFields[], useFirstNonNullField: boolean, filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField).some(dataField => {
      const fieldData = this.getNumber(dataField);
      return fieldData == null ? true : searchValues.some(value => {
        return this.sliderFilter(value, fieldData * 100);
      });
    });
  }

  private static findSomeSliderRanges(searchValues: RangeFilterType[], data: any, fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive', useFirstNonNullField) {
    return this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField).some(dataField => {
      const fieldData = this.getNumber(dataField);
      return fieldData == null ? true : searchValues.some(value => {
        return this.sliderFilter(value, fieldData);
      });
    });
  }

  private static sliderFilter(searchValue: RangeFilterType, fieldData: number | string) {
    const {min, max, currentFloor, currentCeiling} = searchValue;

    if (currentFloor === min && currentCeiling === max) {
      return true;
    }

    if (currentFloor && currentCeiling) {
      return (fieldData >= currentFloor && fieldData <= currentCeiling);
    } else if (currentFloor && !currentCeiling) {
      return fieldData >= currentFloor;
    } else if (!currentFloor && currentCeiling) {
      return fieldData <= currentCeiling;
    }

    return false;
  }

  static applyNumberInputFilter(inputValue: number,
                                dataToBeFiltered: any[],
                                baseFilterModel: BaseFilterModel<any>,
  ) {
    const {fieldsToCheck, searchType, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.filter(data => {
      return this.checkNumberSearchFilter(data, fieldsToCheck, inputValue, searchType, useFirstNonNullField);
    });
  }

  static doesNumberInputFilterApply(inputValue: number,
                                    dataToBeFiltered: any[],
                                    baseFilterModel: BaseFilterModel<any>
  ): boolean {
    const {fieldsToCheck, searchType, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.some(data => {
      return this.checkNumberSearchFilter(data, fieldsToCheck, inputValue, searchType, useFirstNonNullField);
    });
  }

  static checkNumberSearchFilter(data, fieldsToBeFiltered, inputValue, filterType, useFirstNonNullField) {
    const fieldValues = this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField);
    if (filterType === FilterNumberIs.GREATER_THAN) {
      return !fieldValues.some(x => x < inputValue);
    } else if (filterType === FilterNumberIs.LESS_THAN) {
      return !fieldValues.some(x => x > inputValue);
    } else if (filterType === FilterNumberIs.BETWEEN) {
      return fieldValues[0] <= inputValue && fieldValues[1] >= inputValue;
    } else {
      console.warn('invalid filterType');
      return true;
    }
  }


  // Range Methods
  static applyRangeFilter(baseFilterModel: BaseFilterModel<any>, dataToBeFiltered: any[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.filter(data => {
      return this.findSomeRanges(filterValues, data, fieldsToCheck, useFirstNonNullField, filterType);
    });
  }

  static doesRangeFilterApply(baseFilterModel: BaseFilterModel<any>, dataToBeFiltered: any[], filterType: 'inclusive' | 'exclusive' = 'inclusive'): boolean {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.some(data => {
      return this.findSomeRanges(filterValues, data, fieldsToCheck, useFirstNonNullField, filterType);
    });
  }

  private static findSomeRanges(searchValues: RangeFilterType[], data: any, fieldsToBeFiltered: FilterFields[], useFirstNonNullField: boolean, filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField).some(dataField => {
      const fieldData = this.getNumber(dataField);
      return fieldData == null ? null : searchValues.some(value => {
        return this.rangeFilter(value, fieldData);
      });
    });
  }

  private static rangeFilter(searchValue: RangeFilterType, fieldData: number | string) {
    const {min, max} = searchValue;
    if (min && max) {
      return (fieldData >= min && fieldData <= max);
    } else if (min && !max) {
      return fieldData >= min;
    } else if (!min && max) {
      return fieldData <= max;
    }
    return false;
  }

  private static getNumber(value): number | null {
    if (value == null) {
      return null;
    }

    if (!isNaN(value)) {
      return +value;
    } else if (typeof value === 'string') {
      const numberRegex = /(\d+)./g;
      const match = numberRegex.exec(value);
      if (match && match[0]) {
        return parseFloat(match[0]);
      }
    }
    return null;
  }

  static applySingleRangeFilter(baseFilterModel: BaseFilterModel<any>, dataToBeFiltered: any[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.filter(data => {
      return this.findSomeRanges2(filterValues, data, fieldsToCheck, useFirstNonNullField, filterType);
    });
  }

  static doesSingleRangeFilterApply(baseFilterModel: BaseFilterModel<any>, dataToBeFiltered: any[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    const {filterValues, fieldsToCheck, useFirstNonNullField} = baseFilterModel;
    return dataToBeFiltered.some(data => {
      return this.findSomeRanges2(filterValues, data, fieldsToCheck, useFirstNonNullField, filterType);
    });
  }

  private static findSomeRanges2(searchValues: TwoFieldFilterType[], data: any, fieldsToBeFiltered: FilterFields[], useFirstNonNullField: boolean, filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered, useFirstNonNullField).some((dataField: {
      min: number,
      max: number
    }) => {
      const min = this.getNumber(dataField.min);
      const max = this.getNumber(dataField.max);
      return min == null || max == null ? false : searchValues.some(value => {
        return value.currentVal <= max && value.currentVal >= min;
      });
    });
  }
}
