import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterGroup } from '../../../../filter-lib/filter-group';
import { BaseFilter } from '../../../../filter-lib/base-filter';

@Component({
  selector: 'app-filter-indicator-chip',
  templateUrl: './filter-indicator-chip.component.html',
  styleUrls: ['./filter-indicator-chip.component.scss']
})
export class FilterIndicatorChipComponent implements OnInit {
  _filterGroup: FilterGroup;
  filters: BaseFilter<any>[];
  @Input() set filterGroup(fg: FilterGroup) {
    this._filterGroup = fg;
    this.filters = fg.filters.slice(this.numberOfVisibleFilters);
    this.showDivider = this.filters.some(x => x.filterApplied);
  }
  get filterGroup() {
    return this._filterGroup;
  }
  @Input() numberOfVisibleFilters = 0; // number of filters on screen (to know how many to skip chips on)
  showDivider: boolean;
  filterGroupChangeSub: Subscription;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.filters = this.filterGroup.filters.slice(this.numberOfVisibleFilters);;
    this.showDivider = this.filters.some(x => x.filterApplied);
    this.filterGroupChangeSub = this.filterGroup.onFiltersChange.subscribe(filters => {
      this.showDivider = this.filters.some(x => x.filterApplied);
    });
  }

  removeFilter(filter: BaseFilter<any>) {
    filter.clearFilters( false);
  }
}
