import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { ComplianceTrainingComponent } from './compliance-training/compliance-training.component';
import { IndexLevelsComponent } from './index-levels/index-levels.component';
import { VolumeByIssuerComponent } from './volume-by-issuer/volume-by-issuer.component';
import { VolumeByMonthComponent } from './volume-by-month/volume-by-month.component';
import { VolumeByTermYearComponent } from './volume-by-term-year/volume-by-term-year.component';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@common/material.module';
import { MillionsPipe } from '@pipes/millions.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { YearlySummariesComponent } from './yearly-summaries/yearly-summaries.component';
import { FormsModule } from '@angular/forms';
import { DialogComponent } from './dialog/dialog.component';
import { DisclaimerDialogComponent } from './disclaimer-dialog/disclaimer-dialog.component';
import { IncompleteTrainingsDialogComponent } from './incomplete-trainings-dialog/incomplete-trainings-dialog.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ComplianceTrainingComponent,
    IndexLevelsComponent,
    VolumeByIssuerComponent,
    VolumeByMonthComponent,
    VolumeByTermYearComponent,
    YearlySummariesComponent,
    MillionsPipe,
    DisclaimerDialogComponent,
    DialogComponent,
    IncompleteTrainingsDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ChartsModule,
    FontAwesomeModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule
  ],
  providers: [DecimalPipe]
})
export class DashboardModule {
  constructor() {}
}
