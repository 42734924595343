import { Component, OnInit } from '@angular/core';
import { MultiMonthPickerService } from '@common/services/multi-month-picker.service';

@Component({
  selector: 'app-multi-month-picker',
  templateUrl: './multi-month-picker.component.html',
  styleUrls: ['./multi-month-picker.component.scss']
})
export class MultiMonthPickerComponent {

  public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  currYear = new Date().getFullYear();

  constructor(private multiMonthPickerService: MultiMonthPickerService) { }

  incrementCurrYear() {
    this.currYear++;
  }

  decrementCurrYear() {
    this.currYear--;
  }

  close() {
    this.multiMonthPickerService.close();
  }

  reset() {
    this.multiMonthPickerService.reset();
  }

  getState(month, year) {
    // 0 - invalid
    // 1 - valid, not picked
    // 2 - valid, picked
    if ( this.multiMonthPickerService.validValuesArr.includes(month + ' ' + year)) {
      if (this.multiMonthPickerService.selectedArr.includes(month + ' ' + year)) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return 0;
    }
  }

  toggle(month, year) {
    if (this.getState(month, year) === 2) {
      const index = this.multiMonthPickerService.selectedArr.indexOf(month + ' ' + year);
      if (index > -1) {
        this.multiMonthPickerService.selectedArr.splice(index, 1);
      }
    } else {
      this.multiMonthPickerService.selectedArr.push(month + ' ' + year);
    }
  }

  applySelection() {
    this.multiMonthPickerService.close();
    this.multiMonthPickerService.calChanged();
  }
}
