import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@common/services/user.service';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuardService implements CanActivate {

    constructor(private userService: UserService, public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const permission = route.data.permission;
        const redirect = route.data.redirect;
        if(this.userService.getUserPermissions() !== null && this.userService.getUserPermissions().length !== 0) {
            return of(this.userService.getUserPermissions().includes(permission));
        } else {
            return this.userService.$user.pipe(
                filter(user => user !== null),
                take(1),
                map(user => {
                    if(user.permissions.includes(permission)) {
                        return true;
                    } else {
                        this.router.navigateByUrl(redirect);
                        return false;
                    }
                })
            );
        }
    }
}
