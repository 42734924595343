import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { WhiteLabelUtils } from '../../../admin/company-configuration/white-label.utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges {
  @Input() whiteLabelInfo: WhiteLabelInfo;
  @Input() defaultProgramId: number;
  disclaimer: {title: string, link: string};
  disclaimerMessage: string;
  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.whiteLabelInfo?.currentValue) {
      this.mapValue();
    }
  }

  private mapValue() {
    this.disclaimer = WhiteLabelUtils.getDisclaimerUrl(this.whiteLabelInfo, this.defaultProgramId);
    this.disclaimerMessage = WhiteLabelUtils.getDisclaimerText(this.whiteLabelInfo, this.defaultProgramId);
  }

}
