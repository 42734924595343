import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FrontEndLibraries, Variant } from '../global-nav.types';

import { generateAppUrl } from '../utils/navTabUtils';
import { URL_PATHS } from '../global.nav.utils';
import { LumaColor } from '../utils/luma-colors';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit, OnDestroy {
  @Input() variantType: Variant;
  @Input() contactEmail?: string;
  @Input() userName: string;
  @Input() logout: () => void;
  @Input() accountAriaLabel?: string;
  @Input() activeMobileDropdown: string | null;
  @Output() setActiveMobileDropdown = new EventEmitter<string | null>();
  isMobile = false;
  items = [];
  mobileItems = [];
  private breakpointSubscription: Subscription;

  constructor(private breakPointObserverService: BreakpointObserver) {}

  ngOnInit(): void {
    this.initializeBreakpointObserver();
    this.initializeNavItems();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  initializeBreakpointObserver() {
    this.breakpointSubscription = this.breakPointObserverService
      .observe('(max-width: 916px)')
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = breakpointState.matches;
      });
  }

  initializeNavItems() {
    this.items = [
      {
        name: 'Contact Us',
        showItem: true,
        onClick: () => {
          if (!this.contactEmail) {
            window.open(
              'https://lumafintech.com/contact-us-luma-app/',
              '_blank',
            );
          } else {
            window.location.href = `mailto:${this.contactEmail}`;
          }
        },
        disabled: false,
      },
      {
        name: 'Preferences',
        disabled: false,
        showItem: true,
        onClick: () => {
          const url = generateAppUrl(
            '/' + URL_PATHS.USER_PREFERENCES,
            FrontEndLibraries.REACT,
          );
          window.location.href = url;
        },
      },
      {
        name: 'Log out',
        showItem: true,
        onClick: this.logout,
        disabled: false,
      },
      // TODO: translate 'preferences'
    ];
    this.mobileItems = [
      { name: this.userName, showItem: true, onClick: null, disabled: true },
      ...this.items,
    ];
  }

  setActiveMobileDropdownValue(value: string | null) {
    this.setActiveMobileDropdown.emit(value);
  }
}
