import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DashboardMessage } from '../models/dashboard-message';
import { map } from 'rxjs/operators';

interface DashboardMessageValue {
  messageKey: string;
  messageDisclaimer: string;
  messageImportantAnnouncement: string;
}

interface DashboardMessageReply {
  value: DashboardMessageValue;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardMessageService {
  BUSINESS_CONFIG_SERVICE_ROOT = `${environment.hosts.api_gateway}/api/business-config-service`;


  constructor(private http: HttpClient) {
  }

  getDashboardMessage(): Observable<DashboardMessage> {
    return this.http.get<DashboardMessageReply>(this.BUSINESS_CONFIG_SERVICE_ROOT + '/config/loginDisclaimerMessage').pipe(
      map(m => {
        return new DashboardMessage(m.value['message.key'], m.value['message.disclaimer'], m.value['message.important_announcement']);
      })
    );
  }
}
