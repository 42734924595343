import { ViewWidgets } from '@common/components/save-view/save-view-state.service';
import { SortGroup, SortOptions } from '../../sort-lib/sort-utilities';
import { FilterGroup } from '../../filter-lib/filter-group';



export interface GridViewPreferenceModel {
    _id: string;
    userName: string;
    viewName: string;
    defaultView: boolean;
    desk: string;
    widgetType: ViewWidgets;
    viewType: GridViewTypes;
    additionalData: GridViewModel;
}

export enum GridViewTypes {
    user = 'User',
    desk = 'Desk',
    lumaPreset = 'Luma Preset'
}

export interface GridViewModel {
    filterGroup?: any; // TODO?
    sortGroups?: SortGroup[];
    searchInfo?: any;
    gridInfo?: any;
    other?: any;
}
