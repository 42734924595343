import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

// // Setting React at a window level - should consider a custom webpack config

LicenseManager.setLicenseKey('CompanyName=Luma Financial Technologies, LLC,LicensedApplication=Luma,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017904,ExpiryDate=12_September_2022_[v2]_MTY2MjkzNzIwMDAwMA==fa6279babc79792e4bac4be842243ea3');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
