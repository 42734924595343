import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@common/services/user.service';
import { filter, map, take } from 'rxjs/operators';
import { NavService } from '@common/services/nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor( private userService: UserService,
               public router: Router,
               private navigationService: NavService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)  {
    if(this.userService.getToken() !== null) {
      return this.userService.$user.pipe(
        filter(user => user !== null),
        take(1),
        map(user => {
          return this.navigationService.handleDashboardNavigation(route.routeConfig.path);
        })
      );
    } else {
      this.userService.navigateToLogin(false);
      return false;
    }
  }
}
