import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { NavTabType, NavigateHandlerType, Variant } from '../global-nav.types';

@Component({
  selector: 'app-nav-tab-button',
  templateUrl: './nav-tab-button.component.html',
  styleUrls: ['./nav-tab-button.component.scss'],
})
export class NavTabButtonComponent implements OnInit, OnChanges {
  @Input() tab: NavTabType;
  @Input() variantType: Variant = 'primary';
  @Input() activeTab: string;
  @Input() parentActiveTab: string;
  @Output() setActiveTab = new EventEmitter<string>();
  @Input() handleNavigate: NavigateHandlerType;
  @Output() setParentActiveTab = new EventEmitter<string | undefined>();
  title: string;
  isActive = false;

  ngOnChanges(): void {
    this.isActive =
      this.activeTab === this.title || this.parentActiveTab === this.title;
  }

  ngOnInit(): void {
    this.title = this.tab?.title || 'test';
    this.isActive =
      this.activeTab === this.title || this.parentActiveTab === this.title;
  }

  navTabButtonClick() {
    this.setActiveTab.emit(this.title);
    this.handleNavigate(this.tab);
    this.setParentActiveTab.emit(undefined);
  }
}
