import { Component, OnInit } from '@angular/core';
import { DashboardService, IndexLevels } from '../dashboard.service';

@Component({
  selector: 'app-index-levels',
  templateUrl: './index-levels.component.html',
  styleUrls: ['../mat-card-table.scss']
})
export class IndexLevelsComponent implements OnInit {
  columns: string[] = [
    'index',
    'ticker',
    'closingLevel',
    /* todo 'daily', */
    'yearToDate',
    'firstYear',
    'thirdYear',
    'fifthYear'
  ];
  indexLevels: IndexLevels;

  constructor(private service: DashboardService) {
    this.indexLevels = new IndexLevels();
  }

  ngOnInit() {
    this.service.indexLevels().subscribe((idxLevels: IndexLevels) => {
      this.indexLevels = idxLevels;
    });
  }
}
