import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavService } from '@common/services/nav.service';
import { DASHBOARD_REDIRECT } from '../../app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class RedirectToDashboardGuard implements CanActivate {

  constructor(public router: Router,
              private navigationService: NavService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.navigationService.handleDashboardNavigation(DASHBOARD_REDIRECT);
  }
}
