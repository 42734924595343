import { BaseFilter } from './base-filter';
import { BaseFilterModel, FilterGroup, FilterOption, GenericFilterTypes, RangeFilterType } from './base-filter.model';
import { FilterMethods } from './filter-methods.utilities';
import { FilterComponents } from './filter-types.enum';


export interface RangeFilterModel<U extends GenericFilterTypes = RangeFilterType> extends FilterGroup<U> {
  // Overrides
  searchType: 'inclusive' | 'exclusive';
}

export class RangeFilter<U extends GenericFilterTypes = RangeFilterType> extends BaseFilter<U> {
  // tslint:disable-next-line:variable-name
  constructor(protected _baseFilterModel: RangeFilterModel<U>) {
    super(_baseFilterModel);
    _baseFilterModel.filters?.forEach((filter: FilterOption<U> ) => {
      const searchValue = filter.searchValue as RangeFilterType;
      if (searchValue.currentFloor == null) {
        searchValue.currentFloor = searchValue.min;
      }

      if (searchValue.currentCeiling == null) {
        searchValue.currentCeiling = searchValue.max;
      }
    });
  }

  applyFilter<T>(dataToBeFiltered: T[]): T[] {
    return FilterMethods.applyRangeFilter(this._baseFilterModel, dataToBeFiltered);
  }

  doesFilterPass(dataToBeFiltered: any[]): boolean {
    if (this._baseFilterModel.componentType === FilterComponents.SLIDER_FILTER) {
      return FilterMethods.doesSliderRangeFilterApply(this.baseFilterModel, dataToBeFiltered);
      // pass = FilterMethods.doesSliderRangeFilterApply(this._baseFilterModel.filterValues as any[], dataToBeFiltered, this.baseFilterModel.fieldsToCheck);
      // console.log('pass', pass);
      // return pass;
    }
    // return FilterMethods.doesRangeFilterApply(this._baseFilterModel.filterValues as any[], dataToBeFiltered, this.baseFilterModel.fieldsToCheck);
    return FilterMethods.doesRangeFilterApply(this.baseFilterModel, dataToBeFiltered);
    // pass = FilterMethods.doesRangeFilterApply(this._baseFilterModel.filterValues as any[], dataToBeFiltered, this.baseFilterModel.fieldsToCheck);
    // console.log('pass', pass);
    // return pass;
  }

  rangeUpdated(filterOption: FilterOption<RangeFilterType>) {
    const rangeFilterType = filterOption.searchValue;
    filterOption.selected = !(rangeFilterType.min === rangeFilterType.currentFloor
      && rangeFilterType.max === rangeFilterType.currentCeiling);
    this.filterValuesChanged();
  }

  clearFilters(close = false) {
    if(close) {
      this._baseFilterModel.expanded = false;
    }
    this.baseFilterModel.filters?.forEach((filter: FilterOption<any>) => {
      filter.searchValue.currentFloor = filter.searchValue.min;
      filter.searchValue.currentCeiling = filter.searchValue.max;
      filter.selected = true; // added this to appeal to the 'surrender period' static filter on annuties.
    });
    this._baseFilterModel.expanded = false;
    this.filterApplied = false;
    this.filterValuesChanged(true);
  }

  applyFilterView(filters: FilterGroup<any>) {
    for (const [index, filter] of filters.filters?.entries()) {
      if (!this._baseFilterModel.filters?.[index]) {
        this._baseFilterModel.filters[index] = filter;
      } else {
        const currentFilterValue = this._baseFilterModel.filters[index].searchValue as RangeFilterType;
        const incomingFilterValue = filters.filters[index].searchValue as RangeFilterType;
        if (currentFilterValue.max < incomingFilterValue.max) {
          currentFilterValue.max = incomingFilterValue.max;
        }
        if (currentFilterValue.min > incomingFilterValue.min) {
          currentFilterValue.min = incomingFilterValue.min;
        }
        if (incomingFilterValue.max !== incomingFilterValue.currentCeiling) {
          currentFilterValue.currentCeiling = incomingFilterValue.currentCeiling;
        }
        if (incomingFilterValue.min !== incomingFilterValue.currentFloor) {
          currentFilterValue.currentFloor = incomingFilterValue.currentFloor;
        }
      }
    }
    // currently not needed due to cascade changes by what calls this,
    // but if we decide to call this function explicitly anywhere we may need a param to trigger this
    // if (todoParam) {
    // this.filterValuesChanged(true);
    // }
  }
}
