import { Component, Input, OnInit } from '@angular/core';
import { DashboardService, VolumeSummaries } from '../dashboard.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '@common/services/user.service';

@Component({
    selector: 'app-yearly-summaries',
    templateUrl: './yearly-summaries.component.html',
    styleUrls: ['./yearly-summaries.component.scss']
})
export class YearlySummariesComponent implements OnInit {
    @Input() showCurrencyBasedValues = true;
    volumeSummaries: VolumeSummaries;
    volumeError: any;

    constructor(private service: DashboardService) {
    }

    ngOnInit() {
        this.service.volumeSummaries()
            .subscribe((summaries: VolumeSummaries) => {
                    this.volumeSummaries = summaries;
                },
                (error: HttpErrorResponse) => {
                    this.volumeError = error;
                });
    }

    showSpinners() {
        return this.volumeSummaries == null && !this.volumeError;
    }
}
