import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { LumaFont } from "../utils/luma-font";
import { LumaColor } from "../utils/luma-colors";

@Component({
  selector: "app-powered-by-luma-logo",
  templateUrl: "./powered-by-luma-logo.component.html",
  styleUrls: ["./powered-by-luma-logo.component.scss"],
})
export class PoweredByLumaLogoComponent implements OnDestroy {
  breakpointSubscription: Subscription;
  isMobile = false;

  constructor(private breakPointObserverService: BreakpointObserver) {
    this.breakpointSubscription = this.breakPointObserverService
      .observe("(max-width: 916px)")
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = breakpointState.matches;
      });
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  getPoweredTextStyles(): { [k: string]: string } {
    return {
      "font-size": this.isMobile ? "9px" : "11px",
      "font-family": LumaFont.DEFAULT.REGULAR,
      color: LumaColor.PRIMARY_80_DAINTREE,
    };
  }
}
