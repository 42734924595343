import { FilterGroup as FG } from './base-filter.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { BaseFilter } from './base-filter';
import { FilterTypesEnum } from './filter-types.enum';

export class FilterGroup {
  someFiltersApplied = false;
  private subs$: Subscription[] = [];
  initialFilters: BaseFilter<any>[];
  // tslint:disable-next-line:variable-name
  private _onFiltersChange = new Subject<BaseFilter<any>[]>();
  constructor(public filters: BaseFilter<any>[]) {
    this.init();
  }

  private init() {
    this.filters?.forEach(filter => {
      const sub$ = filter.onFilterChange.subscribe(val => {
        this.updateEmit();
        this.someFiltersApplied = this.filters.some(f => f.filterApplied);
      });
      this.subs$.push(sub$);
    });
  }

  public destroy() {
    this.subs$?.forEach(sub => sub?.unsubscribe());
  }

  cascadeChanges() {
    this.filters.forEach(filter => filter.filterValuesChanged(true));
  }

  updateEmit() {
    this._onFiltersChange.next(this.filters);
  }

  clearFilters(close?: boolean) {
    this.filters?.forEach(filter => filter.clearFilters(close));
  }

  selectAll(emitEvent?: boolean) {
    this.filters?.forEach(filter => filter.selectAll(emitEvent));
  }

  get onFiltersChange(): Observable<BaseFilter<any>[]> {
    return this._onFiltersChange.asObservable();
  }

  getAsObject(): {filters: FG<any>[]} {
    return {
      filters: this.filters.map(filter => filter.baseFilterModel)
    };
  }

  applySavedView(filters: FG<any>[]) {
    if(!filters) { return; }
    for(const filter of filters) {
      const currentFilter = this.filters.find(x => x.baseFilterModel.displayName === filter.displayName);
      if(filter.filterType === FilterTypesEnum.NUMBER_SEARCH) {
        currentFilter.baseFilterModel.filterValues = filter.filterValues;
      } else {
        if(filter.filters) {
          if(!currentFilter.baseFilterModel.filters) {
            currentFilter.baseFilterModel.filters = filter.filters;
          } else {
            currentFilter?.applyFilterView(filter);
          }
        }
      }
      }

    this.cascadeChanges();
  }
}
