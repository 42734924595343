import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridViewPreferenceModel } from '@common/models/grid-view-preference.model';

@Injectable({
  providedIn: 'root'
})
export class ViewManagementService {
  PREFERENCE_API_ROOT = `${environment.hosts.api_gateway}/api/preference-service`;

  constructor(private http: HttpClient) { }

  getUserViews(): Observable<GridViewPreferenceModel[]> {
    return this.http.get<GridViewPreferenceModel[]>(this.PREFERENCE_API_ROOT + '/customuserviews');
  }

  getDeskViews(deskName: string): Observable<GridViewPreferenceModel[]> {
    return this.http.get<GridViewPreferenceModel[]>(this.PREFERENCE_API_ROOT + '/customuserviews?desk=' + deskName);
  }

  saveView(view: GridViewPreferenceModel): Observable<GridViewPreferenceModel> {
    return this.http.post<GridViewPreferenceModel>(this.PREFERENCE_API_ROOT + '/customuserviews/', view);
  }

  deleteView(viewId: string): Observable<any> {
    return this.http.delete(this.PREFERENCE_API_ROOT + '/customuserviews/' + viewId);
  }

  getPresetViews(): Observable<GridViewPreferenceModel[]> {
    return this.http.get<GridViewPreferenceModel[]>(this.PREFERENCE_API_ROOT + '/customuserviews/preset');
  }
}
