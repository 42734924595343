import { Moment } from 'moment';

export class AnnuityIndicesModel {
    code: string;
    liveDate: string | Date | Moment;
    oldestDate: string | Date | Moment;
    mostRecentDate: string | Date | Moment;

    get isValid() {
        return this.liveDate && this.oldestDate;
    }

    convertToDateObjects() {
        if (typeof this.liveDate === 'string') {
            this.liveDate = new Date(this.liveDate);
        }

        if (typeof this.mostRecentDate === 'string') {
            this.mostRecentDate = new Date(this.mostRecentDate);
        }

        if (typeof this.oldestDate === 'string') {
            this.oldestDate = new Date(this.oldestDate);
        }
    }
}
