import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utilities } from '@common/utilities/utilities';


@Component({
  selector: 'app-dialog-product-invalid',
  templateUrl: './dialog-product-invalid.component.html'
})
export class DialogProductInvalidComponent {

  public productToFetch: string;

  constructor(public dialogProductInvalidComponentReference: MatDialogRef<DialogProductInvalidComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.productToFetch = Utilities.productNotFound(data.cusipToFetch);
  }

  ok() {
    this.dialogProductInvalidComponentReference.close();
  }

}
