
import { LONG_DASH } from '@common/utilities/utilities';
import { ProductInfo } from '@common/components/product-details-modal/models/product-info';

export const isErrorCusip = cusip => cusip === LONG_DASH;

export const ERROR_PRODUCT: ProductInfo = {
    issuer: 'Issuer',
    issuerLogoUrl: '',
    underliers: [{symbol: 'Underlier', name: 'underlier', source: 'LUMA', weight: 0}],
    basketType: 'Single Basket',
    annualizedCoupon: '—',
    principalBarrierLevel: '—',
    paymentBarrier: '—',
    couponType: '—',
    paymentFrequency: '—',
    callType: '—',
    callObservationFrequency: '—',
    tenor: '—',
    tenorRemaining: '—',
    tenorUnit: '—',
    initialStrikeDate: '—',
    tradeDate: '—',
    settlementDate: '—',
    finalValuationDate: '—',
    maturityDate: '—',
    currency: '—',
    productIdentifier: '—',
    protectionType: 'Barrier',
    returnType: '—',
    productStatus: 'Live',
    productStage: 'Post Trade',
    structureType: 'Product Type',
    hockeyStickFallbackImage: null
};
