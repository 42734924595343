import Big from 'big.js';

export class ArithmeticUtilities {

    static add(param1, param2): string {
        return new Big(param1).plus(new Big(param2)).toString();
    }

    static subtract(param1, param2): string {
        return new Big(param1).minus(new Big(param2)).toString();
    }

    static multiply(param1, param2): string {
        return new Big(param1).times(new Big(param2)).toString();
    }

    static divide(param1, param2): string {
        return new Big(param1).div(new Big(param2)).toString();
    }

    // if you need to chain big operators like .round(2), use the 'big' functions
    static bigAdd(param1, param2): Big {
        return new Big(param1).plus(new Big(param2));
    }

    static bigSubtract(param1, param2): Big {
        return new Big(param1).minus(new Big(param2));
    }

    static bigMultiply(param1, param2): Big {
        return new Big(param1).times(new Big(param2));
    }

    static bigDivide(param1, param2): Big {
        return new Big(param1).div(new Big(param2));
    }

    // To be extended for any further arithmetic operations for exact computation without TS precision
}
