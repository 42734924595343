import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities/utilities';
import { HCRangeSelectorButtons } from '@common/models/hc-range-selector-buttons';
import { HCStockUnderlier } from '@common/models/hc-stock-underlier';
import { Subscription } from 'rxjs';
import { Underlier } from '@common/models/underlier';
import { GRID_LINE_COLOR_ORDER } from '@common/components/product-details-modal/models/grid-line-color-order';
import * as moment from 'moment';
import * as Highcharts from 'highcharts/highstock';
import { v1 as uuidv1 } from 'uuid';
import { CusipService } from '@common/services/cusip.service';

declare var require: any;

const boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const more = require('highcharts/highcharts-more');
const exporting = require('highcharts/modules/exporting');
const exportData = require('highcharts/modules/export-data');
const date = new Date(new Date().toISOString());
const exportUnderlierHistory = 'Export_Underlier_History_'.concat((date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear());

boost(Highcharts);
noData(Highcharts);
more(Highcharts);
noData(Highcharts);
exporting(Highcharts);
noData(Highcharts);
exportData(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-product-details-modal-underlier-chart',
  templateUrl: './product-details-modal-underlier-chart.component.html',
  styleUrls: ['./product-details-modal-underlier-chart.component.scss']
})
export class ProductDetailsModalUnderlierChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() demoMode: boolean;
  @Input() underliers: string[];
  underlierSub: Subscription;

  hcStockUnderlierPerformanceChart;
  chartSeriesOptions;
  dynColor;
  dynData: Array<Array<number>>;
  dynName: string;
  highChartsTemplateHook = '';
  rangeSelectorButtons: HCRangeSelectorButtons = new HCRangeSelectorButtons();
  seriesOptions = [];
  rangeSelectorOptions: any = { buttons: this.rangeSelectorButtons.defaultRangeSelectionButtonStack };
  showChartSpinner;
  chartApiError = false;
  noData = false;
  underlierInfo = [];
  showErrorModal = false;
  showErrorInfo = false;

  constructor(
      private cusipService: CusipService
  ) { }

  ngOnInit() {
    this.highChartsTemplateHook = 'container_' + uuidv1();
    this.chartSeriesOptions = {
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            menuItems: Highcharts.getOptions().exporting.buttons.contextButton.menuItems.slice(0, 6)
          }
        },
        chartOptions: {
          credits: {
            text: Utilities.getExportDisclaimer(),
            enabled: true,
            position: {
              align: 'center',
              y: -5
            },
            style: {
              fontSize: '5pt'
            }
          },
        },
        filename: exportUnderlierHistory
      },
      chart: {
        style: {
          fontFamily: 'Montserrat'
        }
      },
      legend: {
        enabled: false,
      },
      title: null,
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          compare: 'percent',
          showInNavigator: true,
          showCheckbox: false
        }
      },
      tooltip: {
        formatter() {
          // If you want the x label to not show, make the first value an empty string: return[''].this.points...
          return [Highcharts.dateFormat('%m/%d/%Y', this.x)].concat(this.points.map(point => {
            const color = point.series.color;
            const seriesName  = point.series.name;
            const formattedYValue = Highcharts.numberFormat(point.y, 2, '.', ',');
            const change = Highcharts.numberFormat(point.point.change, 2, '.', ',');
            return `<span style="color:${color}">${seriesName}</span>: <b>${formattedYValue}</b> (${change}%)<br>`;
          }));
        },
        split: true,
        backgroundColor: 'rgba(247, 247, 247, 0.90)',
      },
      series: this.seriesOptions,
      xAxis: {
        type: 'datetime',
        crosshair: true,
        labels: {
          formatter: function() {
            return Highcharts.dateFormat('%m/%d/%Y', this.value);
          },
          y: 20,
        }
      },
      yAxis: {
        labels: {
          formatter: (something) => {
            return (something.value > 0) ? '+' + something.value + '%' : something.value + '%';
          }
        }
      }
    };
  }

  extractPayloadForChart(payloadToParse: Array<HCStockUnderlier>) {
    this.seriesOptions = [];
    this.underlierInfo = [];
    const initialDate = moment(payloadToParse?.[0]?.data?.[0]?.[0]);
    const monthsSinceStart = moment().diff(initialDate, 'months');
    if (monthsSinceStart >= 24) {
      this.rangeSelectorOptions.selected = 6;
    } else if (monthsSinceStart >= 12) {
      this.rangeSelectorOptions.selected = 5;
    } else if (monthsSinceStart >= 6) {
      this.rangeSelectorOptions.selected = 3;
    } else if (monthsSinceStart >= 3) {
      this.rangeSelectorOptions.selected = 2;
    } else if (monthsSinceStart >= 1) {
      this.rangeSelectorOptions.selected = 1;
    } else {
      this.rangeSelectorOptions.selected = 0;
    }
    payloadToParse.map((payloadItem, idx) => {
      this.dynColor = GRID_LINE_COLOR_ORDER[idx % GRID_LINE_COLOR_ORDER.length];
      this.dynData = payloadItem.data;
      this.dynName = payloadItem.name;
      this.seriesOptions.push({name: this.dynName, data: this.dynData, color: this.dynColor, boostThreshold: 0});
      this.underlierInfo.push({name: this.dynName, color: this.dynColor});
    });
    this.chartSeriesOptions.series = this.seriesOptions;
    this.hcStockUnderlierPerformanceChart = Highcharts.chart(this.highChartsTemplateHook, this.chartSeriesOptions);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(!this.demoMode) {
        this.loadChartData();
      }
    }, 100);
  }

  loadChartData() {
    if(this.underliers?.length) {
      this.chartApiError = false;
      this.showChartSpinner = true;
      this.underlierSub?.unsubscribe();
      this.underlierSub = this.cusipService.getUnderlierPerformancePayload(this.underliers).subscribe((data: any) => {
        this.showChartSpinner = false;
        this.showErrorInfo = false;
        this.showErrorModal = false;
        data = data.filter(el => el);
        if(data.length === 0) {
          this.showErrorModal = true;
        }
        this.extractPayloadForChart(data.map(el => ({...el})));
      });
    }
  }

  ngOnDestroy() {
    this.underlierSub?.unsubscribe();
  }
}
