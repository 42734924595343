import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import { UserService } from '@common/services/user.service';

@Component({
  selector: 'app-incomplete-trainings-dialog',
  templateUrl: './incomplete-trainings-dialog.component.html',
  styleUrls: ['./incomplete-trainings-dialog.component.scss']
})
export class IncompleteTrainingsDialogComponent {

  learningCenterAccess;
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<IncompleteTrainingsDialogComponent>,
              private router: Router,
              private userService: UserService ) {
                this.learningCenterAccess = this.userService.getUserPermissions().includes(WidgetPermissions.AccessLearningCenter);
                this.title = this.learningCenterAccess ? 'Product Training Reminder' : 'Product Trainings incomplete';
                this.message = this.learningCenterAccess ? `Please complete your firm's required trainings prior to viewing the product catalogue.` :
                  `You must complete the required product training to gain access to the product catalogue.`;
              }

  viewTrainings() {
    if(this.learningCenterAccess) {
      this.router.navigate(['/learning-center']);
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
