import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { AnnuityUtils } from '@product-marketplace/annuity-product/annuity.utils';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * This is a work in progress, please reach out to Zach Fowles for any questions<br>
 * Include (zfowlesluma) on any code reviews modifying this component
 */
@Component({
  selector: 'app-luma-month-picker',
  templateUrl: './luma-month-picker.component.html',
  styleUrls: ['./luma-month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LumaMonthPickerComponent),
      multi: true
    }
  ]
})
export class LumaMonthPickerComponent  implements ControlValueAccessor, OnInit {

  // TODO
  @Input() minDate?;
  @Input() maxDate?;
  @Input() filter?;
  @Input() formLabel = '';
  @Input() formPlaceHolder = '';
  @Input() fc = new FormControl();
  @HostBinding('class') class;

  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Moment>;

  onTouched: () => void;
  localFormControl = new FormControl(); // TODO - Gross
  onChange: (val: FormControl | AbstractControl) => void = () => {};

  ngOnInit(): void {
    this.localFormControl.valueChanges.subscribe(val => {
      if (val) {
        this.writeValue(val);
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.localFormControl.disable({onlySelf: true});
    } else {
      this.localFormControl.enable({onlySelf: true});
    }
  }

  writeValue(obj: any): void {
    this.fc.setValue(obj, {emitEvent: false});
    this?.onChange(obj);
  }

  monthSelected(event) {
    this.datepicker.close();
    this.localFormControl.setValue(event);
  }

  // TODO - Make a param
  getChartErrMsg(formControl: AbstractControl): string {
    return AnnuityUtils.getDatePickerErrorMsg(formControl);
  }

}
