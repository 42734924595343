import { Injectable } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { USER_TOKEN_COOKIE } from './app.component';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { SpinnerService } from '@common/services/spinner.service';
import { Utilities } from '@common/utilities/utilities';
import { NavigationConstants } from '@constants/navigation.constants';

@Injectable()
export class SessionService {
  allNavEvents: Observable<any>;
  filteredNavStart: Observable<NavigationStart>;
  filteredNavEnd: Observable<NavigationEnd>;
  filteredStartUrl: Observable<string>;

  constructor(private userService: UserService,
              private cookieService: CookieService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinnerService: SpinnerService) {
    this.allNavEvents = router.events;

    this.filteredNavStart = router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ) as Observable<NavigationStart>;

    this.filteredNavEnd = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;


    this.allNavEvents.subscribe(this.closeSpinnerOnNavEvents);
    this.filteredNavEnd.subscribe(this.checkForActiveUser);
    this.filteredStartUrl = this.filteredNavStart.pipe(
      mergeMap((navigationStart: NavigationStart)  => {
        const cleanUrl = Utilities.cleanUrl(navigationStart.url);
        if (cleanUrl === NavigationConstants.LOGIN && this.userService.getUser()) {
          this.router.navigate(['dashboard']);
          return EMPTY;
        }
        return of(cleanUrl);
      })
    );
  }

  // closes spinner
  closeSpinnerOnNavEvents = (routerEvent: any) => {
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.spinnerService.hideSpinner();
    }
  }

  checkForActiveUser = (routerEvent: NavigationEnd) => {
    const noUserToken = !this.cookieService.get(USER_TOKEN_COOKIE) && !this.userService.getToken();
    if ( noUserToken && (window.location.pathname !== NavigationConstants.LOGIN)) {
      this.router.navigate([NavigationConstants.LOGIN], {queryParams: this.activatedRoute.snapshot.queryParams});
    } // no access to query parameters on prior events
  }
}
