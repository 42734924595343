import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';

export interface BusinessDay {
    isBusinessDay: boolean;
    nextBusinessDay: Date;
    previousBusinessDay: Date;
}

interface BusinessDayWithStringDates {
    isBusinessDay: boolean;
    nextBusinessDay: string;
    previousBusinessDay: string;
}

export interface VolumeSummaries {
    ytdTickets: 0;
    lastYearTickets: 0;
    ytdVolume: 0;
    lastYearVolume: 0;
}

interface IndexLevelResult {
    fiftyTwoWeekPercentage: 0;
    fiveYearPriceChange: 0;
    lastPrice: 0;
    oneYearPriceChange: 0;
    securityName?: string;
    threeYearPriceChange: 0;
    tickerSymbol?: string;
    tradingDate?: string;
    url?: string;
}

export class IndexLevels {
    data: IndexLevel[];
    closingDate: Date;
    apiError: any;
}

export class IndexLevel {
    index: string;
    ticker: string;
    closingLevel: string;
    daily: string;
    yearToDate: string;
    firstYear: string;
    thirdYear: string;
    fifthYear: string;
    tradingDate: Date;

    constructor(x: IndexLevelResult) {
        this.index = x.securityName; // todo is this right?
        this.ticker = x.tickerSymbol;
        this.closingLevel = `${x.lastPrice}`;
        // this.daily = ;
        this.yearToDate = `${x.fiftyTwoWeekPercentage / 100}`; // todo can we have the server return raw values
        this.firstYear = `${x.oneYearPriceChange / 100}`;
        this.thirdYear = `${x.threeYearPriceChange / 100}`;
        this.fifthYear = `${x.fiveYearPriceChange / 100}`;

        if (x.tradingDate) {
            const tradingDateParts = x.tradingDate.split('-');
            this.tradingDate = new Date(parseInt(tradingDateParts[0], 10), parseInt(tradingDateParts[1], 10) - 1, parseInt(tradingDateParts[2], 10));
        }

    }
}

export interface ClientTraining {
    complianceLinkName?: string;
    expiryDate?: string;
    productType?: string;
    testName?: string;
    trainingDate?: string;
    trainingId?: number;
    trainingScore?: string;
    trainingStatus?: string;
    trainingType?: string;
    userId?: string;
}

export interface MonthVolume {
    month?: string;
    quantity: 0;
}

export interface IssuerVolume {
    issuer?: string;
    quantity: 0;
}

export interface TermVolume {
    name?: string;
    value: 0;
}

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    API_ROOT = `${environment.hosts.api_gateway}/api/dashboard-service`;
    cachedBusinessDay: BehaviorSubject<BusinessDay>;
    cachedIndexLevels: BehaviorSubject<IndexLevels>;

    constructor(private http: HttpClient) {
        this.indexLevels();
    }

    businessDay(): Subject<BusinessDay> {
        if (!this.cachedBusinessDay) {
            this.cachedBusinessDay = new BehaviorSubject<BusinessDay>(null);
            this.http.get<BusinessDayWithStringDates>(this.API_ROOT + '/calendar/businessDay')
                .subscribe((data: BusinessDayWithStringDates) => {
                    // Foolproof way to force browser not to do time zone conversions and get mixed up by DST is to directly parse date my yy-mm-dd
                    // https://stackoverflow.com/questions/5619202/converting-a-string-to-a-date-in-javascript
                    const prevDateParts = data.previousBusinessDay.split('-');
                    const nextDateParts = data.nextBusinessDay.split('-');

                    this.cachedBusinessDay.next(
                        {
                            isBusinessDay: data.isBusinessDay,
                            previousBusinessDay: new Date(parseInt(prevDateParts[0], 10), parseInt(prevDateParts[1], 10) - 1, parseInt(prevDateParts[2], 10)),
                            nextBusinessDay: new Date(parseInt(nextDateParts[0], 10), parseInt(nextDateParts[1], 10) - 1, parseInt(nextDateParts[2], 10))
                        }
                );
            });
        }
        return this.cachedBusinessDay;
    }

    volumeSummaries(): Observable<VolumeSummaries> {
        return this.http.get<VolumeSummaries>(this.API_ROOT + '/summaries');
    }

    indexLevels(): Subject<IndexLevels> {
        if (!this.cachedIndexLevels) {
            this.cachedIndexLevels = new BehaviorSubject<IndexLevels>({ data: [], closingDate: null, apiError: null });
            this.http.get<IndexLevelResult[]>(this.API_ROOT + '/indexes/all')
                .subscribe((levels: IndexLevelResult[]) => {
                        const indexLevel = new IndexLevels();
                        if (levels != null) {
                            indexLevel.data = levels.map(x => new IndexLevel(x));
                            indexLevel.closingDate = new Date(Math.max.apply(null, indexLevel.data.map(x => x.tradingDate)));
                        } else {
                            indexLevel.data = [];
                            indexLevel.closingDate = null;
                        }
                        indexLevel.apiError = null;
                        this.cachedIndexLevels.next(indexLevel);
                    },
                    (error) => {
                        this.cachedIndexLevels.next({
                            data: null,
                            closingDate: null,
                            apiError: error
                        });
                    }
                );
        }
        return this.cachedIndexLevels;
    }

    clientTraining(): Observable<ClientTraining[]> {
        return this.http.get<ClientTraining[]>(this.API_ROOT + '/training/client');
    }

    volumeMonthDetails(): Observable<MonthVolume[]> {
        return this.http.get<MonthVolume[]>(this.API_ROOT + '/volume/details/month');
    }

    volumeIssuerDetails(): Observable<IssuerVolume[]> {
        return this.http.get<IssuerVolume[]>(this.API_ROOT + '/volume/issuer/month/details');
    }

    volumeTermDetails(): Observable<TermVolume[]> {
        return this.http.get<TermVolume[]>(this.API_ROOT + '/volume/term/month/details');
    }
}

