import { Component, Input } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CusipService } from '@common/services/cusip.service';
import { DialogProductInvalidComponent } from '@common/dialog-product-invalid/dialog-product-invalid.component';
import { MultipleResultsDialogComponent } from '@common/global-product-search/multiple-results-dialog/multiple-results-dialog.component';

@Component({
    selector: 'app-global-product-search',
    templateUrl: './global-product-search.component.html'
})
export class GlobalProductSearchComponent {
    @Input() acceptProductSymbol = false;

    product = [];
    public productIdentifier: string;
    productSearchForm = new FormGroup({
        productIdentifier: new FormControl('')
    });

    constructor(private dialog: MatDialog, private router: Router, private cusipService: CusipService) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    validateProductInputOnSearch(): void {
        const controlToFind = 'productIdentifier';
        const productToFetchFromForm = this.productSearchForm.controls[controlToFind].value;

        this.cusipService.getProduct(productToFetchFromForm).subscribe((someProductObject) => {
            this.product = someProductObject;
            if (this.product.length === 0) {
                this.showProductInvalidModal();
            } else if(this.product.length > 1) {
                const dialogRef = this.dialog.open(MultipleResultsDialogComponent, { data: {productList: this.product}});
                dialogRef.afterClosed().subscribe(result => {
                    this.router.navigate(['/product-details/overview/'.concat(result.productGeneral.cusip || result.productGeneral.isin)]);
                });
            } else {
                this.router.navigate(['/product-details/overview/'.concat(this.product[0].productGeneral.cusip || this.product[0].productGeneral.isin)]);
            }
        }, (error) => {
            console.log(error);
        });
    }

    showProductInvalidModal(): MatDialogRef<DialogProductInvalidComponent> {
        const controlToFind = 'productIdentifier';
        const productToFetchFromForm = this.productSearchForm.controls[controlToFind].value;
        this.productSearchForm.reset();

        return this.dialog.open(DialogProductInvalidComponent, {
            width: '50%',
            data: {cusipToFetch: productToFetchFromForm}
        });
    }
}
