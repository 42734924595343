export class DashboardMessage {
    messageKey: string;
    disclaimer: string;
    importantAnnouncement: string;

    constructor(key: string, disclaimer: string, announcement: string) {
        this.messageKey = key;
        this.disclaimer = disclaimer;
        this.importantAnnouncement = announcement;
    }
}
