import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import {
  NavTabType,
  NavigateHandlerType,
  RouteChild,
  Variant,
} from '../global-nav.types';

@Component({
  selector: 'app-dropdown-nav-tab',
  templateUrl: './dropdown-nav-tab.component.html',
  styleUrls: ['./dropdown-nav-tab.component.scss'],
})
export class DropdownNavTabComponent implements OnInit, OnChanges {
  @Input() variantType: Variant;
  @Input() tab: NavTabType;
  @Input() activeTab: string;
  @Output() setActiveTab = new EventEmitter<string>();
  @Input() handleNavigate: NavigateHandlerType;
  @Input() parentActiveTab?: string;
  @Output() setParentActiveTab = new EventEmitter<string | undefined>();
  title: string;
  children: RouteChild[];
  isDropdownOpen = false;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  isActive = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.isDropdownOpen = false;
    }
  }

  ngOnInit(): void {
    this.title = this.tab?.title;
    this.children = this.tab?.children;
  }

  ngOnChanges() {
    this.title = this.tab?.title;
    this.children = this.tab?.children;

    if (this.parentActiveTab && this.activeTab) {
      this.isActive =
        this.activeTab === this.title || this.parentActiveTab === this.title;
    } else {
      this.isActive = false;
    }
  }

  isHeader(item: RouteChild) {
    return item.title && !item.url && !item.route;
  }

  isDropdownWithHeader(parentTitle: string, item: RouteChild): boolean {
    if (parentTitle === 'MARKETPLACE' && (item.url || item.route)) {
      return true;
    }
    return false;
  }

  onDropdownClick() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  listItemClick(item: RouteChild) {
    if (!this.isHeader(item)) {
      const skipTabUpdate = this.handleNavigate(item);
      this.isDropdownOpen = false;

      if (skipTabUpdate) {
        return;
      }
      this.setActiveTab.emit(item.title);
      this.setParentActiveTab.emit(this.title);
    }
  }
}
