import { HttpParams } from '@angular/common/http';
import { Utilities } from '@common/utilities/utilities';
import * as moment from 'moment';


export const buildDiaSpiaParamsObject = (req: DiaSpiaRequestModel) => {
  const mappedRequest = {
    cBase: Utilities.stripCurrencyFormatting(req.costBasis),
    premium: Utilities.stripCurrencyFormatting(req.premium), // not required || or income
    desc: req.desc, // not required
    agent: req.agent,
    income: Utilities.stripCurrencyFormatting(req.income), // not required || or premium
    aType: req.annuityType,
    rState: req.residenceState,
    iState: req.issueState,
    owner: req.owner,
    annuitant: req.annuitant,
    pDob: req.pDob,
    pGender: req.primaryGender,
    continuation: req.continuation?.toString(), // not required
    gType: req.guaranteeType,
    fType: req.fundType,
    gYear: req.guaranteeYear,
    gMonth: req.guaranteeMonth,
    ropCd: req.returnOfPremiumCd,
    pDate: req.purchaseDate,
    pFrequency: req.paymentFrequency,
    fPayDate: req.firstPaymentDate,
    jType: req.jointType, // not required
    jName: req.jointName, // not required
    jDob: req.jDob, // not required
    jGender: req.jointGender, // not required
    jContinuation: req.jointContinuation, // not required
    spouse: req.spouse, // not required
    qlac: req.qlac?.toString(), // not required
    iType: req.indexType,
    iRate: req.indexRate,
    bd: req.brokerDealer
  };

  let httpParams = new HttpParams();

  Object.keys(mappedRequest).forEach(param => {
    if (mappedRequest[param] != null) {
      httpParams = httpParams.set(param, mappedRequest[param]);
    }
  });

  return httpParams;
};


export interface DiaSpiaResponseModel {
  carrier: string | null;
  carrierCode: string | number | null;
  cusip: string | null;
  guaranteedEnd: string | null;
  illustrationId: string | number | null;
  income: number | null;
  lumaCarrier?: { shortName: string, carrierCode: string } | null;
  notes: string[] | null;
  premium: number | null;
  productName: string | null;
  productType: string | null;
  rating: any | null;
  taxablePortion: number | null;
}


export class DiaSpiaRequestModel {
  costBasis: string = null;
  premium: string;
  desc: string;
  agent: string;
  income: string;
  annuityType: string;
  residenceState: string;
  issueState: string;
  owner: string;
  annuitant: string;
  primaryDob: string;
  primaryGender: 'M' | 'F';
  continuation: number;
  guaranteeType: string;
  fundType: string;
  guaranteeYear: number;
  guaranteeMonths: number;
  guaranteeMonth: number;
  returnOfPremiumCd: string;
  purchaseDate: string;
  paymentFrequency: string;
  firstPaymentDate: string;
  jointType: string;
  jointName: string;
  jointDob: string;
  jointGender: 'M' | 'F';
  jointContinuation: string;
  spouse: string;
  qlac: string | boolean;
  indexType: string;
  indexRate: string;
  brokerDealer: string;

  buildHttpParams(): HttpParams {
    let httpParams = new HttpParams();
    const params = buildDiaSpiaParamsObject(this);

    Object.keys(params).forEach(param => {
      if (params[param] != null) {
        httpParams = httpParams.set(param, params[param]);
      }
    });

    return httpParams;
  }

  get pDob() {
    return this.formatDob(this.primaryDob);
  }

  get jDob() {
    return this.formatDob(this.jointDob);
  }

  private formatDob(dob) {
    const d = moment(dob);
    return d.isValid() ? d.format('YYYY/MM/DD') : null;
  }
}
