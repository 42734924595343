import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { formatCurrencyValue } from '@product-marketplace/annuity-product/annuity-product-view/annuity-column-defs/annuity-shared-grid-functions.utility';
import { ANNUITY_PRODUCT_TYPES, ANNUITY_SHELL_PRODUCT } from '@product-marketplace/annuity-product/annuity-constants';
import { SortOptions } from '../../../../../sort-lib/sort-utilities';
import { Subscription } from 'rxjs';
import { Utilities } from '@common/utilities/utilities';
import { LeftSideColumnInput } from '@product-marketplace/common/cell-renderer-components/card-view-columns/left-column-card/left-column-card.component';
import { RightColumnInput } from '@product-marketplace/common/cell-renderer-components/card-view-columns/right-column-card/right-column-card.component';
import { ViewWidgets } from '@common/components/save-view/save-view-state.service';
import {
    annuityMarketplaceSortInfos,
    annuityRateSortInfos,
    savedConfigurationSortInfos
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-column-defs/annuity-sort-views';
import { AnnuityMisfitUtils } from '@product-marketplace/annuity-product/annuity-misfit.utils';
import moment from 'moment';
import { ArithmeticUtilities } from '@common/utilities/arithmetic-utilities';
import {
    GeneralAnnuityProduct
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';
import Big from 'big.js';

// This is mirroring the AnnuityUtil static method
// Was causing circular dependency when AnnuityUtil was imported
function isShellProduct_TEMP(annuityProduct: GeneralAnnuityProduct, defaultVal = false): boolean {
    return annuityProduct?.vendorUniqueId?.startsWith(ANNUITY_SHELL_PRODUCT) || defaultVal;
}

export enum annuityViewNames {
    GENERAL_PRODUCT = 'Marketplace',
    DIA_SPIA = 'DIA/SPIA',
    INCOME_COMPARISON = 'Income Comparison',
    FIA_RILA_RATES = 'Product Rates',
    SAVED_CONFIGURATIONS = 'Saved Configurations'
}

export interface AnnuityView {
    name: annuityViewNames;
    colDef?: ColDef[];
    sortOptions?: SortOptions;
    filterGroup?: any; // todo: type?
    filterSub?: Subscription;
    widgetType?: ViewWidgets;
}

export function filterNullConfigProps(configProps: any[] = []) {
    return configProps.filter(propConfig => propConfig.value && propConfig.value !== '-');
}

export const defaultColDefAnnuities = {
    cellClass: ['no-border', 'annuities-grid'],
};

export const annuityColIds = {
    DOCUMENT: 'document',
    CARRIER: 'carrier',
    FINANCIAL_RATING: 'financialRating',
    PRODUCT_TYPE: 'productType',
    ACCOUNT_TYPE: 'accountType',
    PRODUCT_NAME: 'productName',
    SURRENDER_YEARS: 'surrenderYears',
    SURRENDER_CHARGE: 'surrenderCharge',
    MEFEE: 'formattedMefee',
    MIN_PREMIUM: 'minPremium',
    INDICES: 'indices',
    GLWB: 'glwb',
    GMWB: 'gmwb',
    EEB: 'eeb',
    DB: 'db',
    HAS_PREMIUM_BONUS: 'hasPremiumBonus',
    HAS_INTEREST_BONUS: 'interestBonus',
    RATE_BANDING: 'rateBanding',
    MVA: 'mva',
    ROP: 'rop',
    WAIVERS: 'waivers',
    ACTION: 'action',
    CLIENT_NEED: 'clientNeed',
    SUB_ACCOUNTS: 'subAccounts',
    SHARE_CLASS: 'shareClassId',

    RT_PREMIUM_BAND_START: 'premiumBandStart',
    RT_INDEX: 'underlieIndex',
    RT_TERM: 'term',
    RT_STRATEGY: 'creditingStrategy',
    RT_CAP: 'capRate',
    RT_PAR_RATE: 'parRate',
    RT_SPREAD: 'spread',
    RT_PERF_TRIG: 'performanceTrigger',
    RT_PERF_TRIG_CRED: 'performanceTriggerCredit',
    RT_SURRENDER_YEARS: 'surrenderYears',
    RT_FLOOR: 'floor',
    RT_BUFFER: 'buffer',
    RT_DOWNSIDE_PAR: 'downsideParRate',
    RT_FIXED_RATE: 'fixedRate',
    RT_BONUS: 'bonus',
    RT_MVA: 'mva',
    RT_ROP: 'rop',
    RT_EFFECTIVE_DATE: 'effectiveDate',
    RT_RATE_KEY: 'rateKey',
    RT_INDEX_CODE: 'indexCode',
    RT_CUSTOM_FORMATTED_PERIOD: 'formattedTermPeriod',
    RT_ALLOW_CONFIG: 'allowConfig',

    RD_RIDER_NAME: 'riderName',
    RD_RIDER_TYPE: 'riderType',
    RD_COST_SINGLE: 'riderSingleCost',
    RD_COST_JOINT: 'riderJointCost',
    RD_DEFER_CREDIT: 'deferralCredit',
    RD_DEFER_TERM: 'deferralTerm',
    RD_CALC_METHOD: 'calcMethod',

    RD_EFF_DATE: 'effectiveDate',
    RD_EFF_END: 'effectiveEnd',
    RD_MIN_AGE: 'incomeStartMinAge',
    RD_MAX_AGE: 'incomeStartMaxAge',
    RD_MIN_REF_AGE: 'incomeRefMinAge',
    RD_MAX_REF_AGE: 'incomeRefMaxAge',
    RD_RIDER_ID: 'riderId',

    FX_CARRIER: 'carrier',
    FX_PRODUCT: 'fullName',
    FX_TYPE: 'type',
    FX_SURRENDER_YEARS: 'surrenderYears',
    FX_SURRENDER_CHARGE: 'cdscSchedule',
    FX_MIN_PREMIUM: 'minimumPremium',
    FX_PREMIUM_BAND: 'premiumBand',
    FX_GMIR: 'gmir',
    FX_GUARANTEE_PERIOD: 'guaranteePeriod',
    FX_SURRENDER_PERIOD: 'surrenderPeriod',
    FX_BASE_RATE: 'baseRate',
    FX_PREMIUM_BONUS: 'premiumBonus',
    FX_INTEREST_BONUS: 'interestBonus',
    FX_HAS_PREMIUM_BONUS: 'hasPremiumBonus',
    FX_HAS_INTEREST_BONUS: 'hasInterestBonus',
    FX_FIRST_YR_YIELD: 'firstYearYield',
    FX_GUARANTEE_PERIOD_YIELD: 'guaranteePeriodYield',
    FX_SURRENDER_PERIOD_YIELD: 'surrenderPeriodYield',
    FX_MVA: 'mva',
    FX_ROP: 'rop',
    FX_EFFECTIVE_DATE: 'effectiveDate',

    VA_NAME: 'name',
    VA_YTD_RETURN: 'ytdReturn',
    VA_ONE_YEAR_RETURN: 'oneYearReturn',
    VA_THREE_YEAR_RETURN: 'threeYearReturn',
    VA_FIVE_YEAR_RETURN: 'fiveYearReturn',
    VA_TEN_YEAR_RETURN: 'tenYearReturn',
    VA_NET_EXPENSE_RATIO: 'netExpenseRatio'
};


const leftSideComponent = {
    headerName: 'Product',
    pinned: 'left',
    field: 'left',
    cellStyle: {padding: 0},
    autoHeight: true,
    width: 290,
    maxWidth: 290,
    minWidth: 290,
    suppressAutoSize: true,
    cellRenderer: 'leftSideComponent',
    getQuickFilterText: params => {
        return Utilities.stringifyObjectProperties(params.data) +
        params.data.rop ?? 'rop' + params.data.mva ?? 'MVA';
    },
    cellRendererParams: (params: ICellRendererParams) => {
        const chips = [];

        if (params.data.productType) {
            chips.push({
                  display: params.data.productType,
                  type: 'primary'
              }
            );
        }
        if (params.data.mva) {
            chips.push({
                display: 'MVA',
                type: 'secondary'
            });
        }

        if (params.data.rop) {
            chips.push(        {
                display: 'ROP',
                type: 'secondary'
            });
        }

        let check = null;

        if (params.colDef.colId === 'leftSelectable') {
            const notShellProduct = !isShellProduct_TEMP(params.data);
            const hasConfigData = !params?.data?.disConf;
            const hasPermission = params?.data?.configureBtnPermission;
            const allowedProduct = [
                ANNUITY_PRODUCT_TYPES.FIA,
                ANNUITY_PRODUCT_TYPES.RILA,
                ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES
            ].includes(params.data?.productType);
            check = {
                selected: false,
                show: hasPermission && notShellProduct && allowedProduct && hasConfigData,
                onSelectionChange: function mainGridSelection(event) {
                    if (!this || !this.leftSideConfig?.check?.show) {
                        return;
                    }

                    let enabled = this.params.node.isSelected();
                    if (!enabled) {
                        const selectedRows = this.params.api.getSelectedNodes();
                        const nodeProductType = this.data.productType;
                        enabled = notShellProduct && allowedProduct;
                        if (enabled && selectedRows.length > 0) {
                            const firstProductType = selectedRows[0].data.productType;
                            const isVaProductSelected = firstProductType === ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES;
                            let maxVaCompares = 2;
                            if (this.userService?.isUserMorningstar()) {
                                maxVaCompares = 3;
                            }
                            const maxSelectable = isVaProductSelected ? maxVaCompares : 3;
                            let productTypeMatches;

                            if (isVaProductSelected) {
                                productTypeMatches = nodeProductType === ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES;
                            } else {
                                productTypeMatches = [ANNUITY_PRODUCT_TYPES.FIA, ANNUITY_PRODUCT_TYPES.RILA].includes(nodeProductType);
                            }

                            enabled = this.params.node.isSelected() || (productTypeMatches && (selectedRows.length < maxSelectable));
                        }
                    }
                    this.leftSideConfig.check.disabled = !enabled;
                }
            };
        }

        return {
            leftSideConfig: {
                carrierInformation: {
                    img: params.data.carrierLogo,
                    carrier: params.data.carrier,
                },
                productName: params.data.productName,
                check,
                chips,
                bottomRightIcon: params.data.isYellowFlagged ?
                  {
                      iconProp: ['fas', 'exclamation-triangle'],
                      hoverText:
                        `The rates for this product are not provided directly from issuing carrier and are provided on Luma as is.\n\nThese rates have been obtained from third party or publicly available sources which have demonstrated to be accurate`,
                      iconColor: 'red'
                  }
                  : undefined
            } as LeftSideColumnInput
        };
    },
};

const rightSideComponent = {
    headerName: 'Actions',
    menuTabs: [],
    field: 'right',
    pinned: 'right',
    autoHeight: true,
    suppressAutoSize: true,
    width: 250,
    maxWidth: 250,
    minWidth: 250,
    cellStyle: { padding: 0},
    cellRenderer: 'rightColumnCardComponent',
    cellRendererParams:(params) => {
        const text = [];
        if (params.data.effectiveDate != null && params.data.effectiveDate) {
            const date = Utilities.formatISODateUSA(new Date(params.data.effectiveDate));
            text.push(`Rate Effective: ${date}`);
        }
        return {
            rightSideConfig: {
                supportText: text,
            } as RightColumnInput
        };
    },
};

const financialRatingColumn = {
    headerName: 'Ratings',
    colId: 'financialRatings',
    autoHeight: true,
    minWidth: 180,
    flex: 1,
    cellRenderer: 'dynamicLabelAndValue',
    cellRendererParams:(params) => {
        const config: any[] = [
            {
                label: 'Carrier Financial Ratings',
                values: [
                    `A.M. Best: ${params.data?.amBest}`,
                    `Fitch: ${params.data?.fitch}`,
                    `Moody's: ${params.data?.moodys}`,
                    `Standard & Poor's: ${params.data?.sAndP}`,
                ]
            },
        ];
        return {config};
    },
};

export const benefitCol = {
    headerName: 'Benefit',
    colId: 'rider',
    autoHeight: true,
    minWidth: 150,
    flex: 1,
    cellRenderer: 'dynamicLabelAndValue',
    cellRendererParams:(params) => {
        const isShellProduct = isShellProduct_TEMP(params.data);
        const productType = params.data.productType;
        const config: any[] = [
            {
                label: 'Income Rider / Fee',
                value: `${params.data?.ibRiderRule ?? params.data?.incomeRider} / ${params.data?.ibRiderFee ? 'Yes' : 'No'}`
            },
            {
                label: 'DB Rider / Fee',
                value: `${params.data?.dbRiderRule ?? params.data?.dbRider} / ${params.data?.dbRiderFee ? 'Yes' : 'No'}`
            },
        ];

        if (productType === 'FIA' || productType === 'RILA') {
            config.push(            {
              label: 'Premium Bonus',
              value: params.data?.bonus ? +new Big(params.data.bonus).times(100) + '%' : 'No'
            });
        }

        return {config};
    }
};

const premiumCol = {
    headerName: 'Premium',
    colId: 'premium',
    autoHeight: true,
    minWidth: 150,
    flex: 1,
    cellRenderer: 'dynamicLabelAndValue',
    cellRendererParams:(params) => {
        const config: any[] = [
            {
                label: 'Minimum Premium',
                value: formatCurrencyValue(params.data?.minPremium)
            },
            {
                label: 'Maximum Premium',
                value: formatCurrencyValue(params.data?.maxPremium)
            },
            {
                label: 'Add-On Premium',
                value: formatCurrencyValue(params.data?.addOnPremium)
            },
        ];
        return {config};
    }
};

const accountCol = {
      headerName: 'Account',
      colId: 'account',
      autoHeight: true,
      minWidth: 180,
      flex: 1,
      cellRenderer: 'dynamicLabelAndValue',
      cellRendererParams:(params) => {
          const config: any[] = [
              {
                  label: 'Account Type',
                  value: params.data?.accountType
              },
              {
                  label: 'Surrender Schedule',
                  value: params.data.surrenderCharge?.replaceAll(',', ', ') || '-'
              },
              {
                  label: 'Surrender Years',
                  value: params.data?.surrenderYears ? `${params.data?.surrenderYears}yrs` : null
              },
          ];
          return {config};
      },
  };

export const savedConfigColDef: ColDef[] = [
    leftSideComponent,
    {
        colId: 'client',
        autoHeight: true,
        minWidth: 180,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [
                {
                    label: 'Client',
                    value: params.data?.prodConfigName
                },
                {
                    label: 'Configuration ID',
                    value: params.data?.id
                },
            ];
            return {config};
        },
    },
    {
        colId: 'premium',
        autoHeight: true,
        minWidth: 180,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [
                {
                    label: 'Premium',
                    value: formatCurrencyValue(params.data?.premium)
                },
            ];
            return {config};
        },
    },
    {
        headerName: 'Benefit',
        colId: 'rider',
        autoHeight: true,
        minWidth: 180,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [
                {
                    label: 'Income Benefit',
                    value: params.data?.incomeRiderName ?? 'N/A'
                },
                {
                    label: 'Death Benefit',
                    value: params.data?.dbRiderName ?? 'N/A'
                }
            ];
            return {config};
        }
    },
    {
        colId: 'age',
        autoHeight: true,
        minWidth: 180,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [
                {
                    label: 'Primary Age',
                    value: params?.data?.purchaseAge ?? 'N/A'
                },
                {
                    label: 'Joint Age',
                    value: params?.data?.purchaseJointAge ?? 'N/A'
                },
                {
                    label: 'Income Start Age',
                    value: params?.data?.incomeStartAge ?? 'N/A'
                },
            ];
            return {config};
        },
    },
    {
        colId: 'rateStatus',
        autoHeight: true,
        minWidth: 180,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [
                {
                    label: 'Saved Date',
                    value: moment(params?.data?.createTime).format('MM/DD/YYYY')
                },
                {
                    label: 'Rate Status',
                    value: params?.data?.rateChanged ? 'Outdated': 'Current'
                },
            ];
            return {config};
        },
    },
    rightSideComponent,
];

export const formatRate = (rate) => {
    const configs: any[] = [];
    const indexConfig = [];
    if (rate.productType === ANNUITY_PRODUCT_TYPES.FIXED_RATE) {
        indexConfig.push(                {
              label: 'Base Rate',
              value: Utilities.formatPercentageFromDecimal(rate?.baseRate) ?? '-'
          },
          {
              label: 'GMIR',
              value: Utilities.formatPercentageFromDecimal(rate?.gmir) ?? '-'
          });
    } else {
        indexConfig.push({
            label: 'Index',
            value: AnnuityMisfitUtils.formatMisfitUnderlier(rate) ?? 'N/A'
        });
    }

    const strategyConfig: any[] = [];
    if (rate.productType === ANNUITY_PRODUCT_TYPES.FIXED_RATE) {
        strategyConfig.push({
              label: 'Premium Bonus Rate',
              value: rate?.premiumBonus ? `${rate?.premiumBonus}%` : 'N/A'
          },
          {
              label: 'Interest Rate Bonus',
              value: Utilities.formatPercentageFromDecimal(rate?.interestBonus) ?? 'N/A'
          });
    } else {
        strategyConfig.push({
            label: 'Strategy',
            value: rate?.creditingStrategy ?? 'N/A'
        },
        {
            label: 'Name',
            value: rate?.name ?? 'N/A'
        });
    }

    const creditRates: any[] = [];
    if (rate.productType === ANNUITY_PRODUCT_TYPES.FIXED_RATE) {
        creditRates.push(
          {
              label: '1st Year Yield',
              value: Utilities.formatPercentageFromDecimalYieldRates(rate?.firstYearYield) ?? 'N/A'
          },
          {
              label: 'Guarantee Period Yield',
              value: Utilities.formatPercentageFromDecimalYieldRates(rate?.guaranteePeriodYield) ?? 'N/A'
          },
          {
              label: 'Surrender Period Yield',
              value: Utilities.formatPercentageFromDecimalYieldRates(rate?.surrenderPeriodYield) ?? 'N/A'
          });
    } else {
        const rates = filterNullConfigProps([
            {
                label: 'Fixed Rate',
                value: rate?.fixedRate ? Utilities.formatPercentageFromDecimal(rate?.fixedRate): null
            },
            {
                label: 'Cap Rate',
                value: rate?.capRate ? Utilities.formatPercentageFromDecimal(rate?.capRate): null
            },
            {
                label: 'Par Rate',
                value: rate?.parRate ? Utilities.formatPercentageFromDecimal(rate?.parRate): null
            },
            {
                label: 'Spread Rate',
                value: rate?.spread ? Utilities.formatPercentageFromDecimal(rate?.spread): null
            },
            {
                label: 'Performance Trigger Rate',
                value: rate?.performanceTrigger ? Utilities.formatPercentageFromDecimal(rate?.performanceTrigger): null
            },
            {
                label: 'Perf. Trigger Credit',
                value: rate?.performanceTriggerCredit ? Utilities.formatPercentageFromDecimal(rate?.performanceTriggerCredit): null
            },
            {
                label: 'Strategy Fee',
                value: rate?.strategyFee ? Utilities.formatPercentageFromDecimal(rate?.strategyFee): null
            },
        ]);

        if (rates.length === 0) {
            rates.push({
                  label: 'Credit Rates',
                  value: 'N/A'
              },
            );
        }

        creditRates.push(...rates);
    }

    configs.push(indexConfig, strategyConfig, creditRates);
    return configs;
};

export const annuityFiaRilaRatesColDefs: ColDef[] = [
    {
        headerName: 'Protection',
        colId: 'protection',
        autoHeight: true,
        minWidth: 150,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [];
            if (params.data.productType === ANNUITY_PRODUCT_TYPES.FIXED_RATE) {
                config.push({
                    label: 'Guarantee Period',
                    value: params.data?.guaranteePeriod
                });
            } else {
                let protectionType = 'N/A';
                let protection = null;
                let protectionValue = null;
                const isDataNotEmpty = field => field && field !== 'N/A' && field !== '-';
                if(isDataNotEmpty(params.data?.buffer)) {
                    protectionType = 'Buffer';
                    protection = 'Buffer';
                    protectionValue = params.data?.buffer;
                } else if (isDataNotEmpty(params.data?.floor)) {
                    protectionType = 'Floor';
                    protection = 'Floor';
                    protectionValue = params.data?.floor;
                } else if (isDataNotEmpty(params.data?.downsideParRate)) {
                    protectionType = 'Downside Par Rate';
                    protection = 'Downside Par Rate';
                    protectionValue = Utilities.formatPercentageFromDecimal(params.data?.downsideParRate);
                }

                config.push(...filterNullConfigProps([
                    {
                        label: 'Protection Type',
                        value:  protectionType
                    },
                    {
                        label: protection,
                        value:  protectionValue
                    },
                ]));
            }
            return {config};
        },
        getQuickFilterText: params => {
            if (params.data.productType === ANNUITY_PRODUCT_TYPES.FIXED_RATE) {
                return params.data?.guaranteePeriod;
            } else {
                let protectionType = null;
                if(params.data?.buffer && params.data?.buffer !== 'N/A') {
                    protectionType = 'Buffer';
                } else if (params.data?.floor && params.data?.floor !== 'N/A') {
                    protectionType = 'Floor';
                } else if (params.data?.downsideParRate && params.data?.downsideParRate !== 'N/A') {
                    protectionType = 'Downside Par Rate';
                }
                return protectionType;
            }
        },
    },
    {
        headerName: 'Premium Band',
        colId: 'premiumBand',
        autoHeight: true,
        minWidth: 165,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const maxPlus = 99999999.99;
            const min = 0;
            let val = '';
            let fundType = '';
            if (params.data?.premiumBandStart != null && params.data?.premiumBandEnd != null) {
                const start = +params.data?.premiumBandStart;
                const end = +params.data?.premiumBandEnd;
                if (start === min && end === maxPlus) {
                    val = 'Any';
                } else if(start > min && end === maxPlus) {
                    val = `${formatCurrencyValue(start)} +`;
                } else if(start === min && end < maxPlus) {
                    val = `Up to ${formatCurrencyValue(end)}`;
                } else {
                    if (params.data?.fundType) {
                        let label = '';
                        if (params.data?.fundType === 'Qualified') {
                            label = 'Q';
                        } else if (params.data?.fundType === 'Non-qualified') {
                            label = 'NQ';
                        }
                        fundType = label ? ` <strong>${label}</strong>` : '';
                    }

                    val = `${formatCurrencyValue(start)} - ${formatCurrencyValue(end)}${fundType}`;
                }
            } else {
                val = 'N/A';
            }

            const config: any[] = [
                {
                    label: 'Premium Band',
                    value: val
                },
                {
                    label: 'Free Withdrawal',
                    value: params.data?.formattedFrwd ? params.data?.formattedFrwd
                      : params.data?.hasFrwdText ?  'See Product Profile' : '-',
                },
            ];
            return {config};
        }
    },
];

export const annuityFiARilaTermColDef: ColDef = {
      headerName: 'Term',
      colId: 'term',
      autoHeight: true,
      minWidth: 150,
      flex: 1,
      cellRenderer: 'dynamicLabelAndValue',
      cellRendererParams:(params) => {
          const config: any[] = [];
          if (params.data.productType !== ANNUITY_PRODUCT_TYPES.FIXED_RATE) {
              config.push({
                    label: 'Term',
                    value: params.data?.term ?? 'N/A'
                },
                {
                    label: 'Guaranteed Term',
                    value: params.data?.formattedTermPeriod ?? 'N/A'
                });
          }
          return {config};
      },
  };

export const annuityProductRateColDefsLeft: ColDef[] =  [
    leftSideComponent,
    {
        headerName: 'Rate Information',
        colId: 'rateInfo',
        autoHeight: true,
        minWidth: 500,
        flex: 1,
        cellStyle: {padding: 0},
        cellRenderer: 'rateRenderer',
        cellRendererParams:(params) => {
            const rows = [];
            rows.push(formatRate(params.data));

            params.data.misfitRates?.forEach(data => {
                rows.push(formatRate(data));
            });

            return {rows};
        },
        getQuickFilterText: (params) => {
            return formatRate(params.data) + params.data.misfitRates?.map(misfit => formatRate(misfit)).join(',');
        }
    },
  accountCol
];

export const annuityProductRateColDefsRight: ColDef[] =  [
    rightSideComponent
];

export const annuityMarketplaceColDefs: ColDef[] =  [
    {
        ...leftSideComponent,
        colId: 'leftSelectable'
    },
    financialRatingColumn,
    accountCol,
    premiumCol,
    benefitCol,
    {
        headerName: 'Free Withdrawal',
        colId: 'frwd',
        autoHeight: true,
        minWidth: 165,
        flex: 1,
        cellRenderer: 'dynamicLabelAndValue',
        cellRendererParams:(params) => {
            const config: any[] = [
                {
                    label: 'Free Withdrawal',
                    value: params.data?.formattedFrwd ? params.data?.formattedFrwd
                      : params.data?.hasFrwdText ?  'See Product Profile' : '-',
                },
                {
                    label: 'M&E',
                    value: params.data?.formattedMefee ?? 'N/A'
                },
                {
                    label: 'Product Fee',
                    value: params.data?.formattedProductFee ?? 'N/A'
                }

            ];
            return {config};
        }
    },
    rightSideComponent
];

export const annuityViews: AnnuityView[] = [
    {
        name: annuityViewNames.GENERAL_PRODUCT,
        colDef: annuityMarketplaceColDefs,
        sortOptions: {
            sortInfos: annuityMarketplaceSortInfos,
            sortGroups: null
        },
        widgetType: ViewWidgets.PM_AN_GRID_MARKETPLACE,
    },
    {
        name: annuityViewNames.FIA_RILA_RATES,
        colDef: [],
        sortOptions: {
            sortInfos: annuityRateSortInfos,
            sortGroups: null
        },
        widgetType: ViewWidgets.PM_AN_GRID_RATES,
    },
    {
        name: annuityViewNames.SAVED_CONFIGURATIONS,
        colDef: savedConfigColDef,
        widgetType: ViewWidgets.PM_AN_SAVED_CONFIGURATIONS,
        sortOptions: {
            sortInfos: savedConfigurationSortInfos,
            sortGroups: null
        },
    }
];

export const savedConfigAnnuityView: AnnuityView = {
    name: annuityViewNames.SAVED_CONFIGURATIONS,
    colDef: savedConfigColDef,
    widgetType: ViewWidgets.PM_AN_SAVED_CONFIGURATIONS,
    sortOptions: {
        sortInfos: savedConfigurationSortInfos,
        sortGroups: null
    },
};



export const defaultAnnuityGridOptions = {
    defaultColDef: defaultColDefAnnuities,
    headerHeight: 0,
    rowSelection: 'multiple',
    loadingOverlayComponent: 'customLoadingOverlay',
    suppressAggFuncInHeader: true,
    suppressRowDeselection: true,
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    animateRows: false,
    suppressColumnMoveAnimation: true,
    suppressAnimationFrame: true,
    rowBuffer: 25,
} as GridOptions;
