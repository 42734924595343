import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { TextSearchFilter } from '../../../../filter-lib/text-filter';
import { MatSelect } from '@angular/material/select';
import { FilterOption } from '../../../../filter-lib/base-filter.model';
import { filter as rxjsFilter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss']
})
export class MultiSelectFilterComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatSelect) matSelect: MatSelect;
  private _filter: TextSearchFilter<any>;
  get filter() {
    return this._filter;
  }

  @Input() set filter(filterInput: TextSearchFilter<any>) {
    this._filter = filterInput;
    this.options = this.filter?.baseFilterModel?.filters;
    this.label = this.filter?.baseFilterModel?.displayName;
    this.selectedFilters = [...this.filter.getSelectedFilters()];
    this.updateSelectAllProperties();
    this.changeSub?.unsubscribe();
    this.changeSub = this.filter.onFilterChange.subscribe(change => {
      this.selectedFilters = [...this.filter.getSelectedFilters()];
      this.updateSelectAllProperties();
    });
  }




  @Input() includeLabel = false;
  @Input() onlyShowPlaceholder = false;

  options: FilterOption<any>[];
  label: string;
  selectedFilters: FilterOption<any>[];
  displayLabel: string;
  extraLabel: string;
  optionChanges$: Subscription;

  selectAllIsIndeterminate: boolean;
  selectAllIsChecked: boolean;

  changeSub: Subscription;
  ngOnDestroy(): void {
    this.optionChanges$?.unsubscribe();
    this.changeSub?.unsubscribe();
  }


  ngAfterViewInit(): void {
    this.optionChanges$ = this.matSelect.optionSelectionChanges
      .pipe(rxjsFilter(event => event.isUserInput))
      .subscribe(this.updateFilter);
  }

  updateFilter = (event) => {
    this.filter.setSelectedFilters(this.selectedFilters);
    this.updateSelectAllProperties();
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.selectedFilters = [...this.options];
      this.filter.selectAll();
    } else {
      this.selectedFilters = [];
      this.filter.deselectAll();
    }
    this.updateSelectAllProperties();
  }

  updateSelectAllProperties() {
    if(this.selectedFilters?.length > 0 && this.options?.length > this.selectedFilters?.length) {
      this.selectAllIsChecked = false;
      this.selectAllIsIndeterminate = true;
    } else if(this.options?.length === this.selectedFilters?.length) {
      this.selectAllIsChecked = true;
      this.selectAllIsIndeterminate = false;
    } else {
      this.selectAllIsChecked = false;
      this.selectAllIsIndeterminate = false;
    }
    this.updateLabel();
  }

  updateLabel() {
    let extraLabel = '';
    if (this.includeLabel) {
      extraLabel = ` ${this.filter.getSelectedFilters().map(filter => filter.displayName)}`;
      if (this.options.length === this.selectedFilters.length) {
        extraLabel = `All`;
      }

      this.extraLabel = extraLabel.trim();
      this.displayLabel = `${this.label}${this.extraLabel ? ': ' : ''}`;
    } else {
      this.displayLabel = this.label;
      this.extraLabel = '';
    }
  }
}
