import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart-legend-key',
  templateUrl: './chart-legend-key.component.html',
  styleUrls: ['./chart-legend-key.component.scss']
})
export class ChartLegendKeyComponent {
  @Input() colors: string[];
  @Input() label;
  @Input() icon: 'line' | 'bar';
}
