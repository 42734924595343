import { Component, Input } from "@angular/core";
import { LumaColor } from "../utils/luma-colors";
import { Variant } from "../global-nav.types";
import { VARIANT_MAP } from "../global.nav.utils";

@Component({
  selector: "app-top-nav-bar",
  templateUrl: "./top-nav-bar.component.html",
  styleUrls: ["./top-nav-bar.component.scss"],
})
export class TopNavBarComponent {
  @Input() variantType: Variant = "secondary";
  @Input() logoWidth: string;
  @Input() logoHeight: string;
  @Input() contactEmail: string;
  @Input() userName: string;
  @Input() issuerLogo: string;
  @Input() logout: () => void;
  @Input() accountAriaLabel?: string;
  lumaLogo = "assets/lumaLogo.svg";

  constructor() {}

  getTopNavBarContainerStyles(): { [key: string]: string } {
    return { "background-color": LumaColor.NEUTRAL_00_WHITE };
  }

  getTopNavBarWrapperStyles(): { [key: string]: string } {
    return {
      "background-color": VARIANT_MAP[`${this.variantType}`].topNavBackground,
      "border-bottom": `1px solid
        ${VARIANT_MAP[`${this.variantType}`].topNavBorder}`,
    };
  }

  getLogContainerStyles(): { [key: string]: string } {
    return {
      width: this.logoWidth ? this.logoWidth : "144px",
      height: this.logoHeight ? this.logoHeight : "34px",
    };
  }
}
