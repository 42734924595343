import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { NavTabType, NavigateHandlerType, Variant } from "../global-nav.types";
import { DisplayDeskConfig, VARIANT_MAP } from "../global.nav.utils";
import { isTabDropdown } from "../utils/navTabUtils";
import { LumaColor } from "../utils/luma-colors";

@Component({
  selector: "app-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.scss"],
})
export class MobileMenuComponent {
  @Input() logout: () => void;
  @Input() userName: string;
  @Input() issuerLogo?: string;
  @Input() logoWidth?: string;
  @Input() logoHeight?: string;
  @Input() variantType: Variant;
  @Input() menuAriaLabel: string;
  @Input() tabs: NavTabType[];
  @Input() activeTab: string;
  @Output() setActiveTab = new EventEmitter<string>(); /* (value: string) => void */
  @Input() handleNavigate: NavigateHandlerType;
  @Input() parentActiveTab: string | undefined;
  @Output() setParentActiveTab = new EventEmitter<string | undefined>();
  @Input() contactEmail?: string;
  @Input() displayConfig: DisplayDeskConfig;
  isOpen = false;
  activeMobileDropdown: string | null;
  faTimes = faTimes;
  faBars = faBars;
  lumaLogo = "assets/lumaLogo.svg";

  constructor() {}

  getMobileMenuWrapperStyles(): { [k: string]: string } {
    return {
      "min-height": this.isOpen ? "100vh" : "64px",
      "background-color": VARIANT_MAP[`${this.variantType}`].topNavBackground,
      "border-bottom-right-radius": this.isOpen ? "0px " : "24px",
      "box-shadow": `0 1px 1px 0 ${LumaColor.BOX_SHADOW_4}`,
      border: `1px solid ${VARIANT_MAP[`${this.variantType}`].topNavBorder}`,
    };
  }

  getMobileNavWrapperStyles(): { [k: string]: string } {
    return {
      "border-bottom-right-radius": this.isOpen ? "0px " : "24px",
      "background-color": VARIANT_MAP[`${this.variantType}`].topNavBackground,
    };
  }

  getMobileMenuIconStyles(): { [k: string]: string } {
    return {
      color: VARIANT_MAP[`${this.variantType}`].activeTextColor,
    };
  }

  getLogoContainerStyles(): { [k: string]: string } {
    return {
      width: this.logoWidth ? this.logoWidth : "144px",
      height: this.logoHeight ? this.logoHeight : "28px",
    };
  }

  setMenuOpen() {
    this.isOpen = !this.isOpen;
  }

  setActiveMobileDropdown(value: string) {
    this.activeMobileDropdown = value;
  }

  isTabDropdown(tab: NavTabType) {
    return isTabDropdown(tab);
  }

  setActiveTabValue(value: string) {
    this.setActiveTab.emit(value);
  }

  setParentActiveTabValue(value: string | undefined) {
    this.setParentActiveTab.emit(value);
  }
}
