import { FormControl } from '@angular/forms';
import { OrderFile } from '@product-marketplace/common/models/order-file';

export class OrderFileForm {
    documentCompanyKey = new FormControl();
    documentFileName = new FormControl();
    documentKey = new FormControl();
    documentName = new FormControl();
    documentType = new FormControl();
    documentUploadTimestamp = new FormControl();
    documentUploadedBy = new FormControl();
    documentUrl = new FormControl();
    documentWrapperType = new FormControl();


    constructor(file: OrderFile) {
        this.documentCompanyKey.setValue(file.documentCompanyKey);
        this.documentFileName.setValue(file.documentFileName);
        this.documentKey.setValue(file.documentKey);
        this.documentName.setValue(file.documentName);
        this.documentType.setValue(file.documentType);
        this.documentUploadTimestamp.setValue(file.documentUploadTimestamp);
        this.documentUploadedBy.setValue(file.documentUploadedBy);
        this.documentUrl.setValue(file.documentUrl);
        this.documentWrapperType.setValue(file.documentWrapperType);
    }
}
