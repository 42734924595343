import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  AsyncValidatorFn,
  Validators
} from '@angular/forms';
import { UserService } from '@common/services/user.service';
import { KeyValue } from '@angular/common';
import { Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { Observable, of, tap } from 'rxjs';
import { NavigationConstants } from '@constants/navigation.constants';


const PASSWORD_UPDATED_MESSAGE = 'Your password has successfully been updated.';


@Component({
  selector: 'app-password-update-form',
  templateUrl: './password-update-form.component.html',
  styleUrls: ['./password-update-form.component.scss', '../login-form/login-form.component.scss']
})
export class PasswordUpdateFormComponent implements OnInit {

  @Input() tempAccessToken: string;
  @Input() verificationCode: string;
  @Output() messageEmitter = new EventEmitter();

  disableSubmit = false;
  hidePassword = true;
  hidePasswordConfirm = true;
  validationMessages = {
    minlength: 'Is longer than 7 characters',
    containsUserInfoError: 'Does not match or contain your username, email address, or your name',
    containsSpecialCharacterError: 'Contains a special character (ie: ~`!@#$)',
    containsUpperCaseError: 'Contains an upper case letter (A-Z)',
    containsLowerCaseError: 'Contains a lowercase letter (a-z)',
    containsNumberError: 'Contains a number (0-9)',
  };

  formGroup = new FormGroup({
    password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.containsUserInfoValidator(),
        this.containsSpecialCharacterValidator(),
        this.containsUpperCaseValidator(),
        this.containsLowerCaseValidator(),
        this.containsNumberValidator()
    ],
    [this.passwordCompromisedValidator()]), // async validators only run after the regular validators all pass
    passwordConfirm: new FormControl('', Validators.required),
  }, this.passwordsMatchValidator());

  constructor(private userService: UserService,
              private router: Router) {
  }

  ngOnInit() {
    if(!this.tempAccessToken) {
      this.updateTimeExpired();
    }
    this.formGroup
      .get('password')
      .setErrors({ passwordCompromisedError: true });
  }

  backToLogin() {
    this.emitMessage(null, 'exclamation', '#af2a2a');
    this.router.navigate(['login']);
  }

  updatePassword() {
    const newPassword = this.formGroup.get('password').value;
    if (!newPassword) {
      return;
    }
    this.disableSubmit = true;
    this.userService.resetPassword( newPassword, this.verificationCode, this.tempAccessToken).pipe(catchError(err => {
      this.disableSubmit = false;
      console.log('error: reset password failure', err);
      if(err.status === 400) {
        this.emitMessage(err.message, 'exclamation','#af2a2a');
      } else if(err.status === 401) {
        this.updateTimeExpired();
      }
      return of(null);
    })).subscribe((response: boolean) => {
      this.disableSubmit = false;
      if (response) {
        this.router.navigate([NavigationConstants.LOGIN]);
        this.emitMessage(PASSWORD_UPDATED_MESSAGE, 'check-square', '#46b11d'); // $confirm-green
      }
    });
  }

  emitMessage(message, icon, color) {
    this.messageEmitter.emit({ message, icon, color });
  }

  containsUserInfoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const user = this.userService.getUser();
      if (!user || !control.value?.length) {
        return;
      }

      const lowerVal = control.value.toLowerCase();
      return lowerVal.includes(user.id.toLowerCase())
          || lowerVal.includes(user.name.toLowerCase())
          || lowerVal.includes(user.firstName.toLowerCase())
          || lowerVal.includes(user.lastName.toLowerCase())
          ? { containsUserInfoError: true } : null;
    };
  }

  containsSpecialCharacterValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /[!"#$%&'()*+,\-./\\:;<=>?@\[\]^_`{|}~]+/.test(control.value) ? null : { containsSpecialCharacterError: true };
    };
  }

  containsUpperCaseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /[A-Z]+/.test(control.value) ? null : { containsUpperCaseError: true };
    };
  }

  containsLowerCaseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /[a-z]+/.test(control.value) ? null : { containsLowerCaseError: true };
    };
  }

  containsNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return /\d+/.test(control.value) ? null : { containsNumberError: true };
    };
  }

  passwordsMatchValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors => {
      return group.get('password').value === group.get('passwordConfirm').value
          ? null : { passwordsMatchError: true };
    };
  }

  passwordCompromisedValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        tap((value) => {
          control.setErrors({ passwordLoadingError: true }); // Creates the spinner icon
        }),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((value) =>
          // Valid passwords do not appear in the HIBP database of leaked passwords
          this.userService.checkPasswordIsValid(value, this.tempAccessToken)
        ),
        map((valid: string) => {
          // IF the password has been in any data leak, it is comrpomised and the user must choose a new password
          return valid.includes('false')
            ? { passwordCompromisedError: true }
            : null;
        }),
        first()
      ); // important to make observable finite
    };
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0; // prevents keyvalue pipe from alphabetizing the checkboxes
  }

  /**
   * When the 'password update' link is expired/token has timed out
   */
   updateTimeExpired() {
    this.router.navigate([NavigationConstants.LOGIN],  { queryParams: { passwordReset: 'true' } });
    this.emitMessage('Your password update link has expired. Please try again.', 'exclamation','#af2a2a');
  }
}
