import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterOption, TwoFieldFilterType, ValueFormat } from '../../../../filter-lib/base-filter.model';
import { FilterTypesEnum } from '../../../../filter-lib/filter-types.enum';
import { LabelType } from '@angular-slider/ngx-slider';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FilterUtilities } from '@common/components/filters/filter.utilities';
import { NumberRangeTwoFields } from '../../../../filter-lib/number-range-two-fields-filter';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Utilities } from '@common/utilities/utilities';

@Component({
  selector: 'app-single-filter',
  templateUrl: './single-range-filter.component.html',
  styleUrls: ['./single-range-filter.component.scss']
})
export class SingleRangeFilterComponent implements AfterViewInit {
  @Input() filter: NumberRangeTwoFields<any>;
  @Input() showSlider: boolean = false; // keep 'showSlider' defaulted to false since if it's not in a filter's config it will [input]='false' anyways
  @Output() filterValueChange = new EventEmitter();
  private newValueSub = new ReplaySubject<number>(1);
  selected = false;
  formattedValue: string;

  get currentValue(): number {
    return this.filterValue.currentVal;
  }

  set currentValue(value: number) {
    this.filterValue.currentVal = value;
    this.formattedValue = value.toString();
    this.newValueSub.next(value);
  }

  constructor(private currencyPipe: CurrencyPipe,
              private decimalPipe: DecimalPipe) { }

  addPercentFormat = false;
  ngxSliderOptions;
  filterValue: TwoFieldFilterType;

  ngAfterViewInit(): void {
    this.filterValue = this.singleFilter?.searchValue;
    if(!this.filterValue) { return; }
    this.addPercentFormat = this.filter.baseFilterModel.filterType === FilterTypesEnum.PERCENT_RANGE;
    if (this.filterValue.step == null) {
      FilterUtilities.completeFilterConfigExisting([this.filter]);
    }
    this.ngxSliderOptions = {
      floor: this.filterValue.min,
      ceil: this.filterValue.max,
      hidePointerLabels: true,
    };

    this.ngxSliderOptions.translate = (value: number, label: LabelType): string => {

      if (label === LabelType.Floor) {
        return 'All';
      }

      let newValue = '';
      switch(this.filter?.baseFilterModel?.formatting) {
        case ValueFormat.CURRENCY:
          newValue = this.currencyPipe.transform(value, 'USD', 'symbol','1.0-2');
          break;
        case ValueFormat.PERCENT:
          newValue = this.decimalPipe.transform(value, '1.0-3') + ' %';
          break;
        case ValueFormat.NUMBER: // fallthrough
        default:
          newValue = this.decimalPipe.transform(value, '1.0-3');
      }

      return label === LabelType.Ceil ? `${newValue}+` : newValue;
    };

    this.formattedValue = this.currentValue.toString();
    this.newValueSub.pipe(distinctUntilChanged(), debounceTime(200)).subscribe({
      next: value => {
        this.filter.valueUpdated();
      }
    });
  }

  userChangeStart(event) {
   this.selected = true;
  }

  userChangeEnd(event) {
    this.selected = false;
  }

  private get singleFilter(): FilterOption<NumberRangeTwoFields | any > {
    return this.filter?.baseFilterModel?.filters?.[0];
  }

  onValueChange(event) {
    this.currentValue = +Utilities.stripCurrencyFormatting(event);
    this.formattedValue = event;
  }

  resetFilter(event?) {
    this.filter.clearFilters(false);
  }
}
