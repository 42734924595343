import { trigger, state, style, transition, animate, query, animateChild, group } from '@angular/animations';

export const animateText = trigger('animateText', [
  state('hide',
    style({
      display: 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      display: 'inline',
      opacity: 1,
    })
  ),
  transition('close => open', animate('350ms ease-in')),
  transition('close => openExpanded', animate('350ms ease-in')),
  transition('open => close', animate('200ms ease-out')),
  transition('openExpanded => close', animate('200ms ease-out')),
]);

export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      width: '55px'
    })
  ),
  state('open',
    style({
      'min-width': '200px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('close => openExpanded', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
  transition('openExpanded => close', animate('250ms ease-in')),
]);

export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '55px'
    })
  ),
  state('openExpanded',
    style({
      'margin-left': '275px'
    })
  ),
  state('open',
    style({
      'margin-left': '200px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('close => openExpanded', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in')),
  transition('openExpanded => close', animate('250ms ease-in')),
]);

export const pulse = trigger('pulse', [
  state('hide',
    style({
      display: 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      display: 'flex',
      opacity: 1,
    })
  ),
  transition('hide=>show', animate('700ms ease-in')),
  transition('show=>hide', animate('700ms ease-in'))
]);

export const animateProductDetails = trigger('animateProductDetails', [
  state('hide',
    style({
      display: 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      display: 'flex',
      opacity: 1,
    })
  ),
  transition('hide=>show', animate('700ms ease-in')),
  transition('show=>hide', animate('700ms ease-in'))
]);

export const slideContent = trigger('slideContent', [
  state('destinationOnStage',
    style({
      position: 'relative',
      left: 0
    })
  ),
  state('destinationLeftOfStage',
    style({
      position: 'relative',
      left: 'calc(-1 * 100vw)'
    })
  ),
  state('destinationRightOfStage',
    style({
      position: 'relative',
      left: 'calc(100vw)',
    })
  ),
  state('destinationBackStage',
    style({
      display: 'none',
      opacity: 0
    })
  ),
  transition('void => destinationOnStage', animate('350ms ease-in')),
  transition('void => destinationRightOfStage', animate('350ms ease-in')),
  transition('void => destinationLeftOfStage', animate('350ms ease-in')),
  transition('void => destinationBackStage', animate('350ms ease-in')),

  transition('destinationBackStage => destinationRightOfStage', animate('350ms ease-in')),
  transition('destinationBackStage => destinationLeftOfStage', animate('350ms ease-in')),
  transition('destinationBackStage => destinationOnStage', animate('350ms ease-in')),

  transition('destinationRightOfStage => destinationOnStage', animate('350ms ease-in')),
  transition('destinationRightOfStage => destinationLeftOfStage', animate('700ms ease-in')),
  transition('destinationRightOfStage => destinationBackStage', animate('10ms ease-in')),

  transition('destinationLeftOfStage => destinationOnStage', animate('350ms ease-in')),
  transition('destinationLeftOfStage => destinationRightOfStage', animate('700ms ease-in')),
  transition('destinationLeftOfStage => destinationBackStage', animate('10ms ease-in')),

  transition('destinationOnStage => destinationRightOfStage', animate('350ms ease-in')),
  transition('destinationOnStage => destinationLeftOfStage', animate('350ms ease-in')),
  transition('destinationOnStage => destinationBackStage', animate('20ms ease-in')),
]);

export const slideInAnimation =
  trigger('productDetailsRouteAnimations', [
    transition('HomePage <=> AboutPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> FilterPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%' })
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%' }))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ]);

export let slideUp = trigger('slideUp', [
  transition(':enter', [
    style({ transform: 'translateY(100vh)' }),
    animate('1000ms cubic-bezier(.61, .29, .07, 1.02)')
  ])
]);

export let fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [
    animate(2000)
  ])
]);

export let fadeOut = trigger('fadeOut', [
  state('in', style({ opacity: 1 })),
  transition(':leave',
    animate(2000, style({ opacity: 0 })))
]);

export let fadeOutFast = trigger('fadeOutFast', [
  state('in', style({ opacity: 1 })),
  transition(':leave',
    animate(1000, style({ opacity: 0 })))
]);

export const expandCollapse = trigger('expandCollapse', [
  state('collapsed', style({height: '0px', minHeight: '0'})),
  state('expanded', style({height: '*'})),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);

export const expandCollapseTableRows = trigger('expandCollapseTableRows', [
  state('collapsed', style({height: '0px', minHeight: '0', visibility: 'collapse'})),
  state('expanded', style({height: '*'})),
  transition('expanded => collapsed', animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  transition('collapsed => expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
