import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { GridViewModel, GridViewPreferenceModel, GridViewTypes } from '@common/models/grid-view-preference.model';
import { ViewManagementService } from '@common/services/view-management.service';
import { Utilities } from '@common/utilities/utilities';
import { MiniNotificationComponent } from '@common/components/mini-notifcation/mini-notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '@common/services/user.service';
import { map, takeWhile } from 'rxjs/operators';

export enum ViewWidgets  {
  PM_SP_GRID_MAIN = 'pm_sp_grid_main',
  PM_AN_GRID_MARKETPLACE = 'pm_an_grid_marketplace',
  PM_AN_GRID_RATES = 'pm_an_grid_rates',
  PM_AN_SAVED_CONFIGURATIONS = 'pm_an_grid_saved_configs'
}

export interface SaveViewDetailsModel {
  viewName: string;
  section: GridViewTypes;
  desk: string;
  adminView: boolean;
  widgetType: ViewWidgets;
}

export class WidgetTypeViews {
  userViews = new ReplaySubject<GridViewPreferenceModel[]>(1);
  deskViews = new ReplaySubject<GridViewPreferenceModel[]>(1);
  adminViews = new ReplaySubject<GridViewPreferenceModel[]>(1);
  presetViews = new ReplaySubject<GridViewPreferenceModel[]>(1);
  selectedView = new ReplaySubject<GridViewPreferenceModel>(1);
}

@Injectable({
  providedIn: 'root'
})
export class SaveViewStateService {
  public additionalDataMap = new Map<ViewWidgets, GridViewModel>();
  private widgetTypeViewMap = new Map<ViewWidgets, WidgetTypeViews>();
  resetViews = new Subject<string>();
  $resetViews = this.resetViews.asObservable();

  constructor(
      private viewManagementService: ViewManagementService,
      private matSnackBar: MatSnackBar,
      private userService: UserService
  ) {
    this.widgetTypeViewMap.set(ViewWidgets.PM_AN_SAVED_CONFIGURATIONS, new WidgetTypeViews());
    this.widgetTypeViewMap.set(ViewWidgets.PM_AN_GRID_RATES, new WidgetTypeViews());
    this.widgetTypeViewMap.set(ViewWidgets.PM_AN_GRID_MARKETPLACE, new WidgetTypeViews());
  }

  getUserView() {
    this.viewManagementService.getUserViews().subscribe(userViews => {
      for (const [widgetType, replays] of this.widgetTypeViewMap) {
        const filteredArray = userViews.filter(view => view.viewType === GridViewTypes.user && view.widgetType === widgetType);
        replays.userViews.next(filteredArray);
      }
    });
  }

  getDeskView(deskName) {
    this.viewManagementService.getDeskViews(deskName).subscribe(deskViews => {
      for (const [widgetType, replays] of this.widgetTypeViewMap) {
        const filteredArray = deskViews.filter(view => view.viewType === GridViewTypes.desk && view.widgetType === widgetType);
        replays.deskViews.next(filteredArray);
      }
    });
  }

  getAdminView(deskName) {
    this.viewManagementService.getDeskViews(deskName).subscribe(deskViews => {
      for (const [widgetType, replays] of this.widgetTypeViewMap) {
        const filteredArray = deskViews.filter(view => view.viewType === GridViewTypes.desk && view.widgetType === widgetType);
        replays.adminViews.next(filteredArray);
      }
    });
  }

  getPresetViews() {
    this.viewManagementService.getPresetViews().subscribe(deskViews => {
      for (const [widgetType, replays] of this.widgetTypeViewMap) {
        const filteredArray = deskViews.filter(view => view.viewType === GridViewTypes.lumaPreset && view.widgetType === widgetType);
        replays.presetViews.next(filteredArray);
      }
    });
  }

  getUserView1(widgetType: ViewWidgets): ReplaySubject<GridViewPreferenceModel[]> {
    return this.getAvailableView(widgetType).userViews;
  }

  getDeskView1(widgetType: ViewWidgets): ReplaySubject<GridViewPreferenceModel[]> {
    return this.getAvailableView(widgetType).deskViews;
  }

  getAdminDeskView1(widgetType: ViewWidgets): ReplaySubject<GridViewPreferenceModel[]> {
    return this.getAvailableView(widgetType).adminViews;
  }

  getPresetView1(widgetType: ViewWidgets): ReplaySubject<GridViewPreferenceModel[]> {
    return this.getAvailableView(widgetType).presetViews;
  }

  getSelectedView1(widgetType: ViewWidgets): ReplaySubject<GridViewPreferenceModel> {
    return this.getAvailableView(widgetType).selectedView;
  }

  viewSelected(widgetType: ViewWidgets, view) {
    this.getAvailableView(widgetType).selectedView.next(view);
  }

  async selectViewById(widgetType: ViewWidgets, id: string) {
    return new Promise<{wasViewFound: boolean, view: GridViewPreferenceModel}>(resolve => {
      const savedViews = this.getAvailableView(widgetType);
      let alive = true;
      if (savedViews) {
        combineLatest([savedViews.deskViews, savedViews.userViews])
          .pipe(
            takeWhile(_ => alive),
            map(value => value.flatMap(val => val))).subscribe(values => {
          const viewFound = values.find(value => value._id === id);
          resolve({wasViewFound: !!viewFound, view: viewFound});
          this.viewSelected(widgetType, viewFound);
          alive = false;
        });
      }
    });
  }

  resetViews1(viewType: ViewWidgets): void {
    this.resetViews.next(viewType);
  }

  saveNewView(viewDetails: SaveViewDetailsModel): void {
    if (!this.additionalDataMap.has(viewDetails.widgetType) || !this.additionalDataMap.get(viewDetails.widgetType)) {
      Utilities.launchSnackBar(MiniNotificationComponent.getErrorMessage('No Data was available to save'), this.matSnackBar);
      return;
    }

    const view: GridViewPreferenceModel = {
      _id: null,
      userName: this.userService.getUser().id,
      viewName: viewDetails.viewName,
      defaultView: false,
      desk: viewDetails.desk || null,
      widgetType: viewDetails.widgetType,
      viewType: viewDetails.section,
      additionalData: this.additionalDataMap.get(viewDetails.widgetType)
    };

    this.viewManagementService.saveView(view).subscribe({
      next: (newView: GridViewPreferenceModel) => {
        this.updateView(newView);
        this.viewSelected(view.widgetType, newView);
        Utilities.launchSnackBar(MiniNotificationComponent.getSuccessMessage('You have successfully saved your view'), this.matSnackBar);
      },
      error: () => {
        Utilities.launchSnackBar(MiniNotificationComponent.getErrorMessage('View save was unsuccessful. Please try again later.'), this.matSnackBar);
      }
    });
  }

  deleteView(view: GridViewPreferenceModel): void {
    this.viewManagementService.deleteView(view._id).subscribe({
      next: data => {
        this.updateView(view);
        Utilities.launchSnackBar(MiniNotificationComponent.getSuccessMessage('You have successfully deleted your view'), this.matSnackBar);
      },
      error: () => {
        Utilities.launchSnackBar(MiniNotificationComponent.getErrorMessage('View delete was unsuccessful. Please try again later.'), this.matSnackBar);
      }
    });
  }

  updateView(view: GridViewPreferenceModel): void {
    if(view.viewType === GridViewTypes.user) {
      this.getUserView();
    } else if(view.viewType === GridViewTypes.desk) {
      this.getDeskView(view.desk);
      this.getAdminView(view.desk);
    } else if (view.viewType === GridViewTypes.lumaPreset) {
      this.getPresetViews();
    }
  }

  private getAvailableView(widgetType): WidgetTypeViews {
    if (this.widgetTypeViewMap.has(widgetType)) {
      return this.widgetTypeViewMap.get(widgetType);
    }

    this.widgetTypeViewMap.set(widgetType, new WidgetTypeViews());
    return this.widgetTypeViewMap.get(widgetType);
  }
}
