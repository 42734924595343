export const LumaColor = {
  // SYSTEM COLORS
  COLOR_SYSTEM_BORDER: "#554d5633",
  COLOR_SYSTEM_FONT: "#554d56",
  COLOR_SYSTEM_COLOR: "#979197",

  // ACCENT COLORS
  ACCENT_10: "#faffe3",
  ACCENT_20: "#eff9c3",
  ACCENT_30: "#e1f0a2",
  ACCENT_40: "#cee180",
  ACCENT_50: "#aac743",
  ACCENT_60: "#95ab3c",
  ACCENT_70: "#708420",
  ACCENT_80: "#4a5a0c",
  ACCENT_90: "#273201",

  // NEGATIVE COLORS
  NEGATIVE_10: "#f4d4d5",
  NEGATIVE_20: "#efacad",
  NEGATIVE_30: "#e68485",
  NEGATIVE_40: "#d95d5e",
  NEGATIVE_50: "#b62a27",
  NEGATIVE_60: "#961b1e",
  NEGATIVE_70: "#831518",
  NEGATIVE_80: "#5c0b0b",
  NEGATIVE_90: "#341011",

  // NEAUTRAL COLORS
  NEUTRAL_00_WHITE: "#ffffff",
  NEUTRAL_10: "#f2f4f5",
  NEUTRAL_20: "#e7ecee",
  NEUTRAL_30: "#d8dfe2",
  NEUTRAL_40: "#c4cdd1",
  NEUTRAL_50: "#a9b3b8",
  NEUTRAL_60: "#858f93",
  NEUTRAL_70: "#5a6368",
  NEUTRAL_75: "#454b4e",
  NEUTRAL_80: "#2f3336",
  NEUTRAL_85: "#1a1d1f",
  NEUTRAL_90: "#070708",

  // POSITIVE COLORS
  POSITIVE_10: "#eef4db",
  POSITIVE_20: "#d5e6aa",
  POSITIVE_30: "#bbd97e",
  POSITIVE_40: "#9fcd5a",
  POSITIVE_50: "#82c241",
  POSITIVE_60: "#65a343",
  POSITIVE_70: "#487939",
  POSITIVE_80: "#314c22",
  POSITIVE_90: "#192313",

  // PRIMARY COLORS
  PRIMARY_10: "#e0f4ff",
  PRIMARY_20: "#a0dcf8",
  PRIMARY_30: "#61c0ed",
  PRIMARY_40: "#26a0dc",
  PRIMARY_50: "#007dc2",
  PRIMARY_60: "#02629d",
  PRIMARY_70: "#004570",
  PRIMARY_80_DAINTREE: "#00263e",
  PRIMARY_90: "#00090f",

  // WARNINGS
  WARNING_10: "#fbf7cc",
  WARNING_20: "#f7e78a",
  WARNING_30: "#efdb51",
  WARNING_40: "#dfcb22",
  WARNING_50: "#c9b82d",
  WARNING_60: "#a99e34",
  WARNING_70: "#847c30",
  WARNING_80: "#5a5624",
  WARNING_90: "#333115",

  // BOX SHADOWS
  /** rgba(0, 0, 0, 0.15) */
  BOX_SHADOW_1: "#00000026",

  /** rgba(0, 0, 0, 0.2) */
  BOX_SHADOW_2: "#00000033",

  /** rgba(0, 0, 0, 0.3) */
  BOX_SHADOW_3: "#0000004d",

  /** rgba(47, 51, 54, 0.1) */
  BOX_SHADOW_4: "#2f33361a",

  /** rgba(47, 51, 54, 0.32) */
  BOX_SHADOW_5: "#2f333652",

  /** rgba(47, 51, 54, 0.56) */
  BOX_SHADOW_6: "#2f33368f",

  // BACKGROUNDS
  /** rgba(0, 0, 0, 0.4) */
  BACKGROUND_1: "#00000066",

  /** rgba(0, 0, 0, 0.37) */
  BACKGROUND_2: "#0000005e",

  /** rgba(47, 54, 65, 0.9) */
  BACKGROUND_3: "#2f3641e6",

  /** rgba(147, 195, 213, 0.2) */
  BACKGROUND_4: "#93c3d533",

  /** rgba(255, 255, 255, 0.9) */
  BACKGROUND_5: "#ffffffe6",

  BACKGROUND_6: "#f4f6f8",

  /** rgba(0, 0, 0, 0.5) */
  DRAWER_BACKGROUND: "#00000080",

  /** rgba(0, 0, 0, 0.7) */
  MODAL_BACKGROUND_OVERLAY: "#000000b3",

  /** rgba(0, 0, 0, 0.75) */
  DARKENING_BACKGROUND: "#000000bf",

  PRODUCT_INFORMATION_PRODUCT_SECTION_BACKGROUND_HOVER: "#d2d2d2",
  PRODUCT_INFORMATION_PRODUCT_SECTION_BACKGROUND_ACTIVE: "#e5e5e5",

  // STATUS COLORS
  ERROR_RED: "#af2a2a",
  FAIL_RED: "#E7A39A",
  MESSAGE_WARN: "#E7D29A",
  SUCCESS_GREEN: "#D1E19A",

  // STANDARD, NAMED COLORS
  RED_DARKER: "#C10000",
  RED_BRIGHT: "#d0021b",
  RED: "#D83232",
  AMBER: "#f4a53e",
  YELLOW_DARKER: "#FF961C",
  YELLOW_DARK: "#FFA312",
  YELLOW_MEDIUM: "#FFB005",
  YELLOW: "#FFB901",
  YELLOW_LIGHT: "#FEC10B",
  YELLOW_LIGHTER: "#FED133",
  GREEN_LIGHTER: "#D7E79D",
  GREEN_LIGHT: "#D0E28D",
  GREEN: "#C9DE7D",
  GREEN_MEDIUM: "#BCD65C",
  GREEN_DARK: "#B4D14D",
  GREEN_DARKER: "#A4C431",
  LIME_GREEN: "#39D86B",
  CONFIRM_GREEN: "#46b11d",
  OCEAN_LIGHTER: "#B6D6E2",
  OCEAN: "#93C3D5",
  OCEAN_LIGHT: "#89BED1",
  OCEAN_MEDIUM: "#6DADC5",
  OCEAN_DARK: "#4F9EBA",
  OCEAN_DARKER: "#4594B0",
  OCEAN_35: "#93C3D559",
  BLUE_LIGHTER: "#004B7A",
  BLUE_MEDIUM: "#003252",
  BLUE_LIGHT: "#003F66",
  BLUE_DARK: "#001929",
  BLUE_DARKER: "#000D14",
  GREY_FADE: "#EFF2F4",
  GREY_LIGHTER: "#DBDBDB",
  GREY_LIGHTEST: "#C2C2C2",
  GREY_LIGHT: "#999999",
  GREY_LIGHT_75: "#999999BF",
  GREY_MEDIUM_LIGHT: "#888888",
  GREY_MEDIUM: "#666666",
  TEXT_GREY: "#333538",
  GREY: "#292929",
  GREY_DARK: "#333333",
  GREY_DARKER: "#0A0A0A",
  BLACK: "#000000",

  // OTHER COLORS
  MEDIUM_GREY_1: "#6e6e6e",
  MEDIUM_GREY_2: "#717171",
  DISABLED_TEXT_GREY: "#868686",
  SELECTED_GREY: "#bbb",
  INPUT_FIELD_GREY: "#cdcdcd",
  HOVER_GREY: "#dadada",
  DISABLED_GREY: "#e8e8e8",
  ENTRY_FIELD_GREY: "#f1f1f1",
  PALE_GRAY: "#f7f7f7",

  GRAPHING_BLUE_GREY_1: "#435560",
  GRAPHING_BLUE_GREY_2: "#546a78",
  GRAPHING_BLUE_GREY_3: "#647f90",
  GRAPHING_BLUE_GREY_4: "#7a92a2",
  GRAPHING_BLUE_GREY_5: "#93a7b4",

  GRID_BLUE_1: "#7f929e",
  GRID_BLUE_2: "#d9dfe2",
  GRID_BLUE_3: "#bfc9cf",

  PLATFORM_NAVIGATION_REGULAR_USER_ADMIN_DESELECTED: "#033956",

  LINK_BLUE: "#2a86d1",

  LIGHT_NAV_BLUE: "#2B577D",

  GRAPHING_LUMA_DAINTREE_1: "#113d55",
  GRAPHING_LUMA_DAINTREE_2: "#185677",
  GRAPHING_LUMA_DAINTREE_3: "#1e6e99",
  GRAPHING_LUMA_DAINTREE_4: "#2585b9",
  GRAPHING_LUMA_DAINTREE_5: "#339dd7",

  GRAPHING_LUMA_HALF_BAKED_1: "#2f6274",
  GRAPHING_LUMA_HALF_BAKED_2: "#3b7a91",
  GRAPHING_LUMA_HALF_BAKED_3: "#4792ae",
  GRAPHING_LUMA_HALF_BAKED_4: "#5fa5be",
  GRAPHING_LUMA_HALF_BAKED_5: "#7db5ca",

  GRAPHING_LUMA_YELLOW_GREEN_1: "#434f17",
  GRAPHING_LUMA_YELLOW_GREEN_2: "#5d6e21",
  GRAPHING_LUMA_YELLOW_GREEN_3: "#788d2a",
  GRAPHING_LUMA_YELLOW_GREEN_4: "#93ae34",

  CTA_TABS_FILL_ACTIVE: "#cbe880",

  GRAPHING_LUMA_SELECTIVE_YELLOW_1: "#876708",
  GRAPHING_LUMA_SELECTIVE_YELLOW_2: "#ae850a",
  GRAPHING_LUMA_SELECTIVE_YELLOW_3: "#d4a20c",
  GRAPHING_LUMA_SELECTIVE_YELLOW_4: "#f2bb17",
  GRAPHING_LUMA_SELECTIVE_YELLOW_5: "#f4c63e",
  LUMA_SELECTIVE_YELLOW: "#f5b901",
} as const;
