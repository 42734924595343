export enum CHPermissions {
  IndicativeQuoteRequest = "CAN_CREATE_INDICATIVE_QUOTE_REQUEST",
  LiveQuoteRequest = "CAN_CREATE_LIVE_QUOTE_REQUEST",
  EstimateQuoteRequest = "CAN_CREATE_ESTIMATED_QUOTE_REQUEST",
  ManualQuoteRequest = "CAN_CREATE_MANUAL_QUOTE_REQUEST",
  Issuer = "IS_Issuer",
  Administrator = "IS_ADMINISTRATOR",
  CanRequestAutoPrice = "CAN_REQUEST_AUTOPRICE_DIRECTLY",
  CanRequestManualPrice = "CAN_REQUEST_MANUALPRICE_DIRECTLY",
  CanTrade = "CH2_CAN_TRADE",
  CH2ReadOnly = "CH2_ACCESS_READ_ONLY",
  CH2Advisor = "CH2_ADVISOR",
  ProgramAdvisor = "CAN_ACCESS_CREATION_HUB",
  CH2Approver = "IS_CH2_APPROVER",
  CH2ComplianceApprover = "CH2_COMPLIANCE_APPROVER",
  CH2IssuerAccess = "CH2_ISSUER_ACCESS",
  GeneralPricingToolAccess = "CAN_ACCESS_GENERAL_PRICING_TOOL",
  LumaLitePricingToolAccess = "CAN_ACCESS_LUMA_LITE_PRICING_TOOL",
  CalendarDealPricingToolAccess = "CAN_ACCESS_CALENDAR_DEAL_PRICING_TOOL",
}
