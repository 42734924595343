import {
  FilterGroup,
  RangeFilterType,
  SingleNumberRangeType, TwoFieldFilterType,
  ValueFormat
} from '../../../filter-lib/base-filter.model';
import { FilterComponents, FilterTypesEnum } from '../../../filter-lib/filter-types.enum';
import { BaseFilter } from '../../../filter-lib/base-filter';
import { basicCompareFn } from '../../../sort-lib/sort-utilities';
import { FilterMethods } from '../../../filter-lib/filter-methods.utilities';
import { FilterContext } from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';


export class SliderConfig {
  floor: number;
  ceil: number;
  step: number;
}

export interface FilterOptions {
  options?: any[];
  sliderConfig?: SliderConfig;
}

export class FilterUtilities {
  static hydrateFilterConfig(data: any[], filterConfig: FilterGroup<any>[]) {
    this.resetFilters(filterConfig);
    for (const row of data) {
      for (const filter of filterConfig) {
        if (!filter.static && filter.fieldsToCheck) {
          FilterMethods.getFieldsToCheck(row, filter.fieldsToCheck).filter(field => field != null).forEach(field => {
            this.updateFilterProperty(filter, field);
          });
        }
      }
    }
    this.completeFilterConfig(filterConfig); // post data loop operations? ex: setting step dynamically
  }


  static updateFilterGroup(data: any[], existingFilters: BaseFilter<any>[]) {
    for (const filter of existingFilters) {
      if (filter.baseFilterModel.static || !filter.filterApplied) {
        this.resetFilters([filter.baseFilterModel]);
      } else {
        switch (filter.baseFilterModel.filterType) {
          case FilterTypesEnum.TEXT_LIST_SEARCH: // fallthrough
          case FilterTypesEnum.NUMBER_RANGE_TWO_FIELDS: // fallthrough
          case FilterTypesEnum.TEXT_SEARCH:
            filter.baseFilterModel.filters = filter.baseFilterModel.filters.filter(x => x.selected);
            break;
          default: // do nothing, likely handled in the updateFilterProperty loop below
            break;
        }
      }
    }
    for (const row of data) {
      for (const filter of existingFilters) {
        if (!filter.baseFilterModel.static && filter.baseFilterModel.fieldsToCheck) {
          FilterMethods.getFieldsToCheck(row, filter.baseFilterModel.fieldsToCheck).filter(field => field != null).forEach(field => {
            this.updateFilterProperty(filter.baseFilterModel, field);
          });
        }
      }
    }
    this.completeFilterConfigExisting(existingFilters);
  }


  static updateFilterProperty(filter: FilterGroup<any>, value) {
    if (!filter.static) {
      switch (filter.filterType) {
        case FilterTypesEnum.NUMBER_SEARCH:
          if (value && !filter.isApplicable) {
            filter.isApplicable = true;
          }
          break;
        case FilterTypesEnum.NUMBER_RANGE:
          if (value !== '-' && value !== 'N/A') {
            this.adjustFloorAndCeiling(value, filter);
          }
          break;
        case FilterTypesEnum.PERCENT_RANGE:
          if (value !== '-' && value !== 'N/A') {
            this.adjustFloorAndCeiling(value, filter, true);
          }
          break;
        case FilterTypesEnum.TEXT_LIST_SEARCH:
          if (!filter.filters) {
            filter.filters = [];
          }
          if (value) {
            if (Array.isArray(value)) {
              for (const val of value) {
                if (filter.filters.findIndex(filterOption => filterOption.searchValue === val) === -1) {
                  filter.filters.push({
                    displayName: val,
                    searchValue: val
                  });
                }
              }
            } else {
              if (filter.filters.findIndex(filterOption => filterOption.searchValue === value) === -1) {
                filter.filters.push({
                  displayName: value,
                  searchValue: value
                });
              }
            }
          }
          break;
        case FilterTypesEnum.YES_NO_BOOLEAN:
          if (!filter.filters) {
            filter.filters = [
              {
                searchValue: true,
                displayName: 'Yes',
              },
              {
                searchValue: false,
                displayName: 'No',
              }
            ];
          }
          break;
        case FilterTypesEnum.NUMBER_RANGE_TWO_FIELDS:
          if (!filter.filters) {
            filter.filters = [];
          }
          if (value.min != null && value.max != null) {
            this.adjustSingleFilter(value, filter);
          }
          break;
        default:
          if (!filter.filters) {
            filter.filters = [];
          }
          if (filter.filters.findIndex(filterOption => filterOption.searchValue === value) === -1) {
            filter.filters.push({
              displayName: value,
              searchValue: value,
            });
          }
          break;
      }
    }
  }

  // mostly the same as complete filter config but we don't set current floor/ceiling values for ranges
  static completeFilterConfigExisting(filters: BaseFilter<any>[]) {
    for (const filter of filters) {
      switch (filter.baseFilterModel.filterType) {
        case FilterTypesEnum.NUMBER_SEARCH:
          filter.baseFilterModel.isApplicable = true; // number_search is always applicable
          break;
        case FilterTypesEnum.TEXT_LIST_SEARCH: // fallthrough
        case FilterTypesEnum.YES_NO_BOOLEAN: // fallthrough
        case FilterTypesEnum.TEXT_SEARCH:
          filter.baseFilterModel.filters = filter.baseFilterModel.filters?.sort((a, b) => basicCompareFn(a.displayName, b.displayName, false));
          filter.baseFilterModel.isApplicable = filter.baseFilterModel.filters?.length > 1;
          break;
        case FilterTypesEnum.NUMBER_RANGE: // fallthrough
        case FilterTypesEnum.PERCENT_RANGE: // fallthrough
          filter.baseFilterModel.filters?.forEach((fil: any) => {
            const diff = (fil.searchValue.max - fil.searchValue.min);
            if (diff) { // if the values are not the same
              fil.searchValue.step = +(diff / 50);
            }
            this.adjustForRoundingErrors(fil.searchValue);
          });
          filter.baseFilterModel.isApplicable = filter.baseFilterModel.filters?.some(x => x.searchValue?.step != null && x.searchValue.min !== x.searchValue.max) || false;
          break;
        case FilterTypesEnum.NUMBER_RANGE_TWO_FIELDS:
          filter.baseFilterModel.isApplicable = filter.baseFilterModel.filters?.some(x => x.searchValue?.min != null && x.searchValue?.max != null) || false;
          break;
      }
    }
  }

  static completeFilterConfig(filterConfig: FilterGroup<any>[]) {
    for (const filter of filterConfig) {
      switch (filter.filterType) {
        case FilterTypesEnum.NUMBER_SEARCH:
          // do nothing, is applicable is set in the 'hydrateView' function
          break;
        case FilterTypesEnum.TEXT_LIST_SEARCH: // fallthrough
        case FilterTypesEnum.YES_NO_BOOLEAN: // fallthrough
        case FilterTypesEnum.TEXT_SEARCH:
          filter.filters = filter.filters?.sort((a, b) => basicCompareFn(a.displayName, b.displayName, false));
          filter.isApplicable = filter.filters?.length > 1;
          break;
        case FilterTypesEnum.PERCENT_RANGE: // fallthrough
        case FilterTypesEnum.NUMBER_RANGE:
          filter.filters?.forEach((fil: any) => {
            const diff = (fil.searchValue.max - fil.searchValue.min);
            if (diff) { // if the values are not the same
              fil.searchValue.step = +(diff / 50);
              fil.searchValue.currentCeiling = +fil.searchValue.max;
              fil.searchValue.currentFloor = +fil.searchValue.min;
            }
            this.adjustForRoundingErrors(fil.searchValue);
          });
          filter.isApplicable = filter.filters?.some(x => x.searchValue?.step != null && x.searchValue?.min !== x.searchValue?.max) || false;
          break;
        case FilterTypesEnum.NUMBER_RANGE_TWO_FIELDS:
          filter.isApplicable = filter.filters?.some(x => x.searchValue?.min != null && x.searchValue?.max != null) || false;
          break;
      }
    }
  }

  static adjustForRoundingErrors(filterSearchValue) {
    filterSearchValue.max = this.removeRoundingError(filterSearchValue.max);
    filterSearchValue.min = this.removeRoundingError(filterSearchValue.min);
    filterSearchValue.step = this.removeRoundingError(filterSearchValue.step);
    filterSearchValue.currentCeiling = this.removeRoundingError(filterSearchValue.currentCeiling);
    filterSearchValue.currentFloor = this.removeRoundingError(filterSearchValue.currentFloor);
  }


  static removeRoundingError(value) {
    if (!value) {
      return value;
    }
    return +value.toFixed(12);
  }

  static adjustSingleFilter(dataValue: { min: number, max: number }, filterGroup: FilterGroup<any>) {

    if (!filterGroup.filters || filterGroup.filters?.length < 1) {
      filterGroup.filters = [
        {
          searchValue: {
            min: null,
            max: null,
          }
        }
      ];
    }

    filterGroup.filters.forEach(filter => {
      const ignoreMax = filterGroup.fieldsToCheck.some((field: SingleNumberRangeType) => field.ignoreBoundaries && field.maxAmount === +dataValue.max);
      const ignoreMin = filterGroup.fieldsToCheck.some((field: SingleNumberRangeType) => field.ignoreBoundaries && field.minAmount === +dataValue.min);

      const max = ignoreMax && +filter.searchValue.max < +dataValue.min && dataValue.max ? +dataValue.min : +dataValue.max;
      const useMin = max === +dataValue.min;

      if ((useMin || !ignoreMax) && (filter.searchValue.max === null || filter.searchValue.max < max)) {
        filter.searchValue.max = max;
      }

      if (!ignoreMin && (filter.searchValue.min === null || filter.searchValue.min > +dataValue.min)) {
        filter.searchValue.min = dataValue.min;
      }
    });
  }

  static adjustFloorAndCeiling(dataValue, filterGroup: FilterGroup<any>, convertToPercent: boolean = false) {
    let values;
    if (typeof dataValue === 'number') {
      values = [dataValue];
    } else {
      values = dataValue.replaceAll('%', '').split('-').map(val => +(val?.trim())); // handle cases where the value ~~ '10% - 100%'
    }
    if (!values) {
      return;
    }
    if (convertToPercent) {
      values = values.map(value => value * 100);
    }
    if (!filterGroup.filters || filterGroup.filters?.length < 1) {
      filterGroup.filters = [
        {
          searchValue: {
            min: null,
            max: null,
          }
        }
      ];
    }
    filterGroup.filters.forEach(filter => { // literally only ever should have 1??
      const ceilVal = +values[values.length > 1 ? 1 : 0];
      if (filter.searchValue.max === null || filter.searchValue.max < ceilVal) {
        filter.searchValue.max = ceilVal;
      }
      if (filter.searchValue.min === null || filter.searchValue.min > +values[0]) {
        filter.searchValue.min = +values[0];
      }
    });
  }


  static resetFilters(filterConfig: FilterGroup<any> []) {
    for (const filter of filterConfig) {
      if (!filter.static) {
        filter.filters = null;
      }
    }
  }

  static buildFilterExportObject(filterGroup: any): FilterContext[] {
    const filtersForExport: FilterContext[] = [];
    filterGroup?.filters?.forEach((baseFilter: BaseFilter<any>) => {
      if (baseFilter.filterApplied) {
        let formattedFilters;
        if (baseFilter.baseFilterModel.componentType === FilterComponents.SLIDER_FILTER) {
          formattedFilters = baseFilter.baseFilterModel.filterValues.map((filterValue) => {
            const {currentFloor, currentCeiling} = (filterValue as RangeFilterType);

            let formattedFloor = '';
            let formattedCeiling = '';

            switch (baseFilter.baseFilterModel.formatting) {
              case ValueFormat.CURRENCY:
                formattedFloor = currentFloor.toString();
                formattedCeiling = formattedCeiling.toString();
                break;
              case ValueFormat.NUMBER:
                formattedFloor = currentFloor.toString();
                formattedCeiling = formattedCeiling.toString();
                break;
            }
            return currentFloor + currentCeiling;
          });
        } else if (baseFilter.baseFilterModel.componentType === FilterComponents.SINGLE_SLIDER_FILTER) {
          formattedFilters = baseFilter.baseFilterModel.filterValues.map((filterValue) => {
            let formattedValue = '';

            const {currentVal} = (filterValue as TwoFieldFilterType);

            switch (baseFilter.baseFilterModel.formatting) {
              case ValueFormat.CURRENCY:
                formattedValue = currentVal.toString();
                break;
              case ValueFormat.NUMBER:
                formattedValue = currentVal.toString();
                break;
            }
            return formattedValue;
          });
        } else {
          formattedFilters = baseFilter.baseFilterModel.filterValues;
        }

        const filterText = formattedFilters.join(', ');

        filtersForExport.push({
          filterText,
          filterName: baseFilter.baseFilterModel.displayName
        });
      }
    });
    return filtersForExport;
  }

}


