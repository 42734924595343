export class AnnuityOrderRequestModel {
    cusip: string;
    clientFirstName: string;
    clientMiddleName: string;
    clientLastName: string;
    clientAge: number;
    clientState: string;
    jointFirstName: string;
    jointMiddleName: string;
    jointLastName: string;
    jointAge: number;
    jointState: string;
    clientAccount: string;
    premium: number;
    currency: string;
    riders: string[];
    activityName?: string;
    // Use User's UUID
    financialAdviser: string;
    financialAdviserName: string;
    transactionTime: string;
    transactionType: string;
    reference: string;
    vendorUniqueId: string;
    oesVendor: string;
    oesVendorParam: string;
    oesBrokerDealer: string;
    eventType: number; // TODO - Map Events
    status: number; // TODO - MAP Status
    carrier: string;
    annuityType: string;
    productName: string;
    brokerDealerShortName?: string;
    constructor() {
        this.setDefaultValuesForTesting();
    }


    // FOR TESTING ONLY
    private setDefaultValuesForTesting(useJoint = false) {
        this.clientFirstName = 'firstName';
        this.clientMiddleName = 'middleName';
        this.clientLastName = 'lastName';
        this.clientAccount = 'clientAccount';
        this.currency = 'USD';
        this.riders = ['A list of rider names'];
        this.financialAdviser = 'Full name of the advisor';
        this.transactionType = 'OES';
        this.vendorUniqueId = '0000-1234567';
        this.oesVendor = 'oesVendorLuma';
        this.status = 0;

        if (useJoint) {
            this.jointFirstName = 'jointApplicantFirstName';
            this.jointMiddleName = 'jointApplicantMiddleName';
            this.jointLastName = 'jointApplicantLastName';
            this.jointAge = 55;
            this.jointState = 'OH';
        }
    }

}
