import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnnuitySessionIdService } from '@product-marketplace/annuity-product/annuity-session-id.service';

@Injectable({
  providedIn: 'root'
})
export class SessionIdInterceptor implements HttpInterceptor {
  constructor(private annuitySessionIdService: AnnuitySessionIdService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('inject-session-id')) {
      const headers = request.headers.delete('inject-session-id');

      const modifiedReq = request.clone({
        headers,
        params: request.params.set('sessionid', this.annuitySessionIdService.getSessionId())
      });
      return next.handle(modifiedReq);
    }

    return next.handle(request);
  }
}
