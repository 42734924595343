import { ChangeDetectorRef, Component, ContentChild, Input, OnChanges, TemplateRef } from '@angular/core';
import { UnderlineTabsComponent } from '@common/components/underline-tabs/underline-tabs.component';

/*
 * This component is used in conjunction with underline-tabs to have a standard tab setup
 *
 * This takes an input of tabTitle which is what will be displayed as the name of the tab
 *
 * Also it should be noted that if the content passed to the underline-tab component is surrounded by ng-template, ng-container
 * or other similar tags that the tab will be destroyed when switching tabs
 * This component detects if the content is surrounded in template tags or not and uses ngIf/hidden to create this behavior
 *
 */

@Component({
  selector: 'app-underline-tab',
  templateUrl: './underline-tab.component.html',
  styleUrls: ['./underline-tab.component.scss']
})
export class UnderlineTabComponent{
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() tabTitle: string;
  active = false;

  constructor(
      tabs: UnderlineTabsComponent
  ) {
    tabs.addTab(this);
  }
}
