import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavigateHandlerType, RouteChild, Variant } from "../global-nav.types";
import { VARIANT_MAP } from "../global.nav.utils";

@Component({
  selector: "app-mobile-tab-button",
  templateUrl: "./mobile-tab-button.component.html",
  styleUrls: ["./mobile-tab-button.component.scss"],
})
export class MobileTabButtonComponent implements OnInit {
  @Input() item: RouteChild;
  @Input() variantType: Variant;
  @Input() activeTab: string;
  @Output() setActiveTab = new EventEmitter<string>();
  @Input() menuOpen: boolean;
  @Output() setMenuOpen = new EventEmitter<void>();
  @Input() handleNavigate: NavigateHandlerType;
  @Output() setParentActiveTab = new EventEmitter<string | undefined>();
  title: string;

  constructor() {}

  ngOnInit(): void {
    this.title = this.item.title;
  }

  getMobileTabWrapperStyles(): { [k: string]: string } {
    return {
      "border-bottom": `1px solid ${VARIANT_MAP[`${this.variantType}`].mobileBorder}`,
    };
  }

  getMobileButtonStyles(): { [k: string]: string } {
    const isActive = this.title === this.activeTab;

    return {
      "font-weight": isActive ? VARIANT_MAP[`${this.variantType}`].activeFontWeight : "normal",
      color: isActive
        ? VARIANT_MAP[`${this.variantType}`].activeTextColor
        : VARIANT_MAP[`${this.variantType}`].textColor,
    };
  }

  getActiveButtonHighlightStyles(): { [k: string]: string } {
    const isActive = this.title === this.activeTab;

    return {
      width: isActive ? "104px " : null,
      height: isActive ? "3px " : null,
      "background-color": VARIANT_MAP[`${this.variantType}`].activeHighlightColor,
    };
  }

  onClickMobileTabButton() {
    this.setActiveTab.emit(this.item.title);
    this.handleNavigate(this.item);
    this.setMenuOpen.emit();
    this.setParentActiveTab.emit(undefined);
  }
}
