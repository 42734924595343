import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product } from '@common/models/product';

@Component({
  selector: 'app-multiple-results-dialog',
  templateUrl: './multiple-results-dialog.component.html',
  styleUrls: ['./multiple-results-dialog.component.scss']
})
export class MultipleResultsDialogComponent {
  selectedProduct: any;
  productList: any[];

  constructor(public dialogRef: MatDialogRef<MultipleResultsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.productList = data.productList;
  }

  confirm() {
    this.dialogRef.close(this.selectedProduct);
  }
  cancel() {
    this.dialogRef.close('deny');
  }

  close() {
    this.dialogRef.close();
  }

}
