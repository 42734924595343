import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective implements OnInit {

  constructor() { }

  @Input() color = '#f5fcff';
  @Input() dragColor = '#9ecbec';
  @Input() enabled = true;
  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') private background;
  @HostBinding('style.opacity') private opacity = 'default';

  ngOnInit(): void {
    this.background = this.color;
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    if (this.enabled) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = this.dragColor;
      this.opacity = '0.8';
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    if (this.enabled) {
      evt.preventDefault();
      evt.stopPropagation();
      this.background = this.color;
      this.opacity = '1';
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (this.enabled) {
      event.preventDefault();
      this.background = this.color;
      this.opacity = '1';
      const { dataTransfer } = event;
      if (dataTransfer.items) {
        // const file = dataTransfer.files[0];  //todo: why not/test
        const file = dataTransfer.items[0].getAsFile();
        dataTransfer.items.clear();
        this.fileDropped.emit(file);
      }
    }
  }
}
