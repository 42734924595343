import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-or-cancel-modal',
  templateUrl: './confirm-or-cancel-modal.component.html',
  styleUrls: ['./confirm-or-cancel-modal.component.scss']
})
export class ConfirmOrCancelModalComponent {

  message: string;
  title: string;
  singleButton = false;
  lumaContact = false;
  warningTitle = false;
  singleButtonText = 'Close';
  confirmButtonText = 'Confirm';
  cancelButtonText = 'Cancel';
  exitIcon = false;
  constructor(public dialogRef: MatDialogRef<ConfirmOrCancelModalComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
              this.message = data.message;
              this.title = data.title;
              if(data.lumaContact) { this.lumaContact = data.lumaContact; }
              if(data.singleButton) { this.singleButton = data.singleButton; }
              if(data.warningTitle) { this.warningTitle = data.warningTitle; }
              if(data.singleButtonText) { this.singleButtonText = data.singleButtonText; }
              if(data.confirmButtonText) { this.confirmButtonText = data.confirmButtonText; }
              if(data.cancelButtonText) { this.cancelButtonText = data.cancelButtonText; }
              if(data.exitIcon) { this.exitIcon = data.exitIcon; }
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
  cancel() {
    this.dialogRef.close('deny');
  }

  close() {
    this.dialogRef.close();
  }
}
