import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FilterGroup, FilterOption } from '../../../../filter-lib/base-filter.model';
import { BaseFilter } from '../../../../filter-lib/base-filter';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-multi-select-search-filter',
  templateUrl: './multi-select-search-filter.component.html',
  styleUrls: ['./multi-select-search-filter.component.scss']
})
export class MultiSelectSearchFilterComponent implements OnInit, OnDestroy {


  @Input() maxVisible = null;
  @Input() multiple = true;
  @Input() closeOnSelect = false;

  items: FilterOption<any>[];
  label: string;
  filterValue: FilterOption<any>[];
  filterModel: FilterGroup<any>;
  changeSub: Subscription;

  // tslint:disable-next-line:variable-name
  private _filter: BaseFilter<any>;
  get filter() {
    return this._filter;
  }

  @Input() set filter(filterInput: BaseFilter<any>) {
    this._filter = filterInput;
    this.filterModel = filterInput.baseFilterModel;
    this.items = this.filterModel.filters;
    this.label = this.filterModel.displayName;
    this.filterValue = this._filter.getSelectedFilters();
  }

  @ViewChild('select') select: NgSelectComponent;
  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.select.close();
    }
  }

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll, true);
    this.changeSub = this.filter.onFilterChange.subscribe(change => {
      this.filterValue = [...this.filter.getSelectedFilters()];
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
    this.changeSub?.unsubscribe();
  }

  onChange(newValue) {
    this.filter.setSelectedFilters(newValue);
  }

  selectAll() {
    this.filter.selectAll();
    this.filterValue = this._filter.getSelectedFilters();
  }

  unselectAll() {
    this.select.handleClearClick();
    this.filter.clearFilters( false);
    this.filterValue = [];
  }

  searchFunction(term: string, item: any) {
    return item?.displayName?.toLowerCase().includes(term?.toLowerCase());
  }
}
