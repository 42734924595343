import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-active-inactive-toggle',
  templateUrl: './active-inactive-toggle.component.html',
  styleUrls: ['./active-inactive-toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ActiveInactiveToggleComponent),
    multi: true
  }]
})
export class ActiveInactiveToggleComponent implements ControlValueAccessor {

  @Input() toggleOnProp = 'Active';
  @Input() toggleOffProp = 'Inactive';
  @Input() activeLabel = 'Active';
  @Input() inactiveLabel = 'Inactive';
  constructor() { }

  onChange;
  value: boolean;
  disabled;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // we don't need, but it's part of the interface
  }

  writeValue(valueReceived: string): void {
    this.value = valueReceived === this.toggleOnProp;
  }

  toggleClicked() {
    if(!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value ? this.toggleOnProp : this.toggleOffProp);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

}
