export interface WhiteLabelDisclaimerText {
    disclaimerText: string;
    disclaimerTextEnabled: boolean;
}

export interface WhiteLabelDisclaimerUrl {
    disclaimerUrlAddress: string;
    disclaimerUrlText: string;
    disclaimerUrlEnabled: boolean;
}

export interface PerformanceReportDisclaimer {
    perfDisclaimerText: string;
    useDisclaimerText: boolean;
    hideLumaDisclaimerText: boolean;
}

export type WhiteLabelProgramOverride = {
    shortName: string;
    programId: number;
    isActive: boolean;
    programLogo: string;
    performanceReportDisclaimer: PerformanceReportDisclaimer;
    disclaimerUrl: WhiteLabelDisclaimerUrl;
    disclaimerText: WhiteLabelDisclaimerText;
    eventNotificationDisclaimer: WhiteLabelDisclaimerText;
};

export class WhiteLabelInfo {
    companyUri: string;
    contactEmail: string;
    whiteLabelDisclaimerUrl: WhiteLabelDisclaimerUrl;
    whiteLabelDisclaimerText: WhiteLabelDisclaimerText;
    eventNotificationDisclaimer: WhiteLabelDisclaimerText;
    performanceReportDisclaimer: PerformanceReportDisclaimer;
    whiteLabelProgramOverrides: WhiteLabelProgramOverride[];
}
