import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-progress-spinner',
  templateUrl: './dialog-progress-spinner.component.html',
  styleUrls: ['./dialog-progress-spinner.component.scss']
})
export class DialogProgressSpinnerComponent {
  constructor() {}
}

