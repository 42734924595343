import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { faAngleDown, faAngleUp, faUser } from "@fortawesome/pro-solid-svg-icons";
import { Variant } from "../global-nav.types";
import { LumaColor } from "../utils/luma-colors";
import { VARIANT_MAP } from "../global.nav.utils";

@Component({
  selector: "app-profile-mobile-dropdown",
  templateUrl: "./profile-mobile-dropdown.component.html",
  styleUrls: ["./profile-mobile-dropdown.component.scss"],
})
export class ProfileMobileDropdownComponent implements OnChanges {
  @Input() userName: string;
  @Input() items: {
    name: string;
    showItem: boolean;
    onClick: null | (() => void);
    disabled: boolean;
  }[] = [];
  @Input() activeMobileDropdown: string;
  @Output() setActiveMobileDropdown = new EventEmitter<string | null>();
  @Input() variantType: Variant;
  isDropdownOpen = false;
  isActiveMobileListButton = false;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;

  constructor() {}

  ngOnChanges(): void {
    this.isDropdownOpen = this.activeMobileDropdown === "profile";
  }

  dropdownButtonClick(name: string) {
    if (this.isDropdownOpen) {
      this.setActiveMobileDropdown.emit(null);
    } else {
      this.setActiveMobileDropdown.emit(name);
    }
  }

  getDropdownIconStyles(): { [k: string]: string } {
    return {
      color: VARIANT_MAP[`${this.variantType}`].accountIconMainColor,
    };
  }

  getMobileDropdownWrapperStyles(): { [k: string]: string } {
    return {
      "border-bottom": `1px solid ${VARIANT_MAP[`${this.variantType}`].mobileBorder}`,
    };
  }

  getMobileListButtonStyles(item: any): { [k: string]: string } {
    const isUsername = item.name === this.userName;

    return {
      color: isUsername ? LumaColor.NEUTRAL_60 : VARIANT_MAP[`${this.variantType}`].activeTextColor,
      ...(this.isActiveMobileListButton &&
        isUsername && {
          "font-weight": VARIANT_MAP[`${this.variantType}`].activeFontWeight,
          color: VARIANT_MAP[`${this.variantType}`].activeTextColor,
        }),
    };
  }

  getMobileTabWrapperStyles(): { [k: string]: string } {
    return {
      "border-bottom": this.isDropdownOpen ? null : `1px solid ${VARIANT_MAP[`${this.variantType}`].mobileBorder}`,
    };
  }

  getMobileTabButtonStyles(): { [k: string]: string } {
    return {
      color: VARIANT_MAP[`${this.variantType}`].textColor,
    };
  }

  setActiveMobileListButton(value: boolean) {
    this.isActiveMobileListButton = value;
  }
}
