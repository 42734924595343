import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavTabType, NavigateHandlerType, Variant } from '../global-nav.types';
import { DisplayDeskConfig } from '../global.nav.utils';
import { isTabDropdown } from '../utils/navTabUtils';
import { LumaColor } from '../utils/luma-colors';

@Component({
    selector: 'app-nav-tab-bar',
    templateUrl: './nav-tab-bar.component.html',
    styleUrls: ['./nav-tab-bar.component.scss'],
})
export class NavTabBarComponent {
    @Input() displayConfig: DisplayDeskConfig;
    @Input() tabs: NavTabType[] = [];
    @Input() activeTab: string;
    @Input() variantType: Variant;
    @Output() setActiveTab = new EventEmitter<string>();
    @Input() handleNavigate: NavigateHandlerType;
    @Input() parentActiveTab?: string;
    @Output() setParentActiveTab = new EventEmitter<string | undefined>();

    constructor() {
    }

    getNavTabBarWrapperStyles(): { [key: string]: string } {
        return {
            'box-shadow': `0 1px 1px 0 ${LumaColor.BOX_SHADOW_6}`,
            'background-color': LumaColor.NEUTRAL_00_WHITE,
        };
    }

    isTabDropdown(tab: NavTabType) {
        return isTabDropdown(tab);
    }

    setActiveTabValue(value: string) {
        this.setActiveTab.emit(value);
    }

    setParentActiveTabValue(value: string) {
        this.setParentActiveTab.emit(value);
    }
}
