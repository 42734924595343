import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginUserInfo } from '../login.component';
import { NavigationConstants } from '@constants/navigation.constants';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss', '../login-form/login-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit {

  @Input() userInfo: LoginUserInfo;
  @Input() username: string;
  @Output() messageEmitter = new EventEmitter();

  formGroup = new FormGroup({
    username: new FormControl(''),
  });

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.formGroup.get('username').setValue(this.username || this.userInfo?.username || '');
  }

  backToLogin() {
    this.emitMessage();
    this.router.navigate(['login']);
  }

  sendPasswordReset() {
    const username = this.formGroup.get('username').value;
    if (!username) {
      return;
    } else {
      const currentRoute = window.location.href.split(NavigationConstants.LOGIN)[0];
      window.location.href = `${currentRoute}/login?username=${username.trim()}&passwordReset=true` ;
    }
  }

  emitMessage(message: string = null, icon: string = 'exclamation', color: string = '#af2a2a') { // $luma-red
    this.messageEmitter.emit({ message, icon, color });
  }

}
