import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyRelationship } from '@common/models/company-relationship.model';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpServiceUtils } from '@creation-hub/services/http-services.utils';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { DeskService } from '@common/services/desk.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  SERVICE_ROOT = `${environment.hosts.api_gateway}/api/company`;
  CURRENCY_CODE_URL = this.SERVICE_ROOT + '/currency/user/all';
  WHITE_LABEL_INFO = this.SERVICE_ROOT + '/whitelabelinfo';
  WHITE_LABEL = `${this.SERVICE_ROOT}/whitelabel`;
  constructor(private http: HttpClient,
              private deskService: DeskService) { }

  /**
   * @deprecated This api may be refactored in the future
   */
  getCompaniesV1() {
    return this.http.get(`${this.SERVICE_ROOT}/companies`);
  }

  getCompanies() {
    return this.http.get(`${this.SERVICE_ROOT}/v2/companies`);
  }

  getCompanyRelationships() {
    return this.http.get(`${this.SERVICE_ROOT}/relationships`);
  }

  getCurrencyCodes(payload): Observable<any> {
    return this.http
      .get<string[]>(this.CURRENCY_CODE_URL,  { headers: {'Content-Type': 'application/json'}, params: payload })
      .pipe(catchError(HttpServiceUtils.handleError));
  }

  addRelationship(companyRelationship: CompanyRelationship) {
    return this.http.post(`${this.SERVICE_ROOT}/relationships`, companyRelationship);
  }

  removeRelationship(companyRelationshipId: number) {
    return this.http.delete(`${this.SERVICE_ROOT}/relationships/${companyRelationshipId}`);
  }

  getWhiteLabelInfo(): Observable<WhiteLabelInfo> {
    return this.http.get<WhiteLabelInfo>(this.WHITE_LABEL_INFO);
  }

  getCompanySummary(shortname): Observable<any> {
    const params = new HttpParams().set('shortName', shortname);
    return this.http.get(`${this.SERVICE_ROOT}/company/summary`, {params});
  }

  getCompanyWhiteLabel(shortname): Observable<any> {
    const params = new HttpParams().set('name', shortname);
    return this.http.get(`${this.WHITE_LABEL}`, {params});
  }

  getCompanyWhiteLabelAndSummary(shortname): Observable<any> {
    const requests = [
      this.getCompanySummary(shortname),
      this.getCompanyWhiteLabel(shortname),
      this.deskService.getTradeDesksForName(shortname)
    ];
    return forkJoin(requests).pipe(map(([companySummary, companyWhitelabel, companyInfo]) => {
      return {
        companySummary,
        companyWhitelabel,
        companyInfo
      };
    }));
  }

  private purgeUnusedFieldsFromWhiteLabelBody(whiteLabelBody) {
    const {
      createdBy,
      createdOn,
      updatedBy,
      updatedOn,
      ...remainingWhiteLabelFields
    } = whiteLabelBody;
    return {
      ...remainingWhiteLabelFields
    };
  }

  saveWhiteLabel(whiteLabel, logo): Observable<any>  {
    const cleanedBody = this.purgeUnusedFieldsFromWhiteLabelBody(whiteLabel);
    const formData = new FormData();
    formData.append('info', JSON.stringify(cleanedBody));

    if (logo) {
      formData.append('logo', logo);
    }

    if (whiteLabel.id) {
      return this.http.put(this.WHITE_LABEL, formData);
    } else {
      return this.http.post(this.WHITE_LABEL, formData);
    }

  }

  saveWhiteLabelWithNewProgramLogo(whiteLabel, logo, programId): Observable<any>  {
    const formData = new FormData();
    const cleanedBody = this.purgeUnusedFieldsFromWhiteLabelBody(whiteLabel);
    formData.append('info', JSON.stringify(cleanedBody));
    formData.append('logo', logo);
    formData.append('programId', programId);

    return this.http.put(`${this.WHITE_LABEL}/withProgramLogo`, formData);
  }

  async getFileFromUrl(url) {
    const filename = url?.substring(url?.lastIndexOf('/') + 1);

    const imageObservable$ = this.http.get(url, {responseType: 'blob'})
        .pipe(
            map(response => new File([response], filename),
            catchError(err => null))
        );

    return lastValueFrom(imageObservable$).then(value => value).catch(reason => null);
  }
}
