import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@common/material.module';

@NgModule({
    declarations: [SideMenuComponent],
    exports: [
        SideMenuComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        MaterialModule
    ]
})
export class SideMenuModule {}
