import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import {
  NavTabTypeWithChildren,
  NavigateHandlerType,
  RouteChild,
  Variant,
} from '../global-nav.types';
import { VARIANT_MAP } from '../global.nav.utils';

@Component({
  selector: 'app-mobile-dropdown',
  templateUrl: './mobile-dropdown.component.html',
  styleUrls: ['./mobile-dropdown.component.scss'],
})
export class MobileDropdownComponent
  implements OnInit, /*  DoCheck */ OnChanges
{
  @Input() tab: NavTabTypeWithChildren;
  @Input() variantType: Variant;
  @Input() activeTab: string;
  @Output() setActiveTab = new EventEmitter<string>();
  @Input() menuOpen: boolean;
  @Output() setMenuOpen = new EventEmitter<void>() /* () => void */;
  @Input() handleNavigate: NavigateHandlerType;
  @Input() activeMobileDropdown: string | null;
  @Output() setActiveMobileDropdown = new EventEmitter<
    string | null
  >(); /* (value: string | null) => void */
  @Input() parentActiveTab: string | undefined;
  @Output() setParentActiveTab = new EventEmitter<string | undefined>();
  @Input() testInput: string;
  title: string;
  children: RouteChild[];
  dropdownOpen = false;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  isActiveMobileListButton = false;

  constructor() {}

  ngOnInit(): void {
    this.title = this.tab.title;
    this.children = this.tab.children;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tab']?.firstChange) {
      this.title = this.tab.title;
      this.children = this.tab.children;
    } else {
      this.dropdownOpen = this.title === this.activeMobileDropdown;
    }
  }

  dropdownButtonClick(name: string) {
    if (this.dropdownOpen) {
      this.setActiveMobileDropdown.emit(null);
    } else {
      this.setActiveMobileDropdown.emit(name);
    }
  }

  isHeader(item: RouteChild) {
    return item.title && !item.url && !item.route;
  }

  listItemClick(item: RouteChild) {
    if (!this.isHeader(item)) {
      this.setActiveTab.emit(item.title);
      this.handleNavigate(item);
      this.setMenuOpen.emit();
      this.setActiveMobileDropdown.emit(null);
      this.setParentActiveTab.emit(this.title);
    } else {
      this.setMenuOpen.emit();
      this.setActiveMobileDropdown.emit(null);
    }
  }

  getMobileTabWrapperStyles(): { [k: string]: string } {
    return {
      'border-bottom': this.dropdownOpen
        ? null
        : `1px solid ${VARIANT_MAP[`${this.variantType}`].mobileBorder}`,
    };
  }

  getMobileTabButtonStyles(): { [k: string]: string } {
    const isActive = this.title === this.parentActiveTab;

    return {
      'font-weight': isActive
        ? VARIANT_MAP[`${this.variantType}`].activeFontWeight
        : 'normal',
      color: isActive
        ? VARIANT_MAP[`${this.variantType}`].activeTextColor
        : VARIANT_MAP[`${this.variantType}`].textColor,
    };
  }

  getDropdownIconStyles(): { [k: string]: string } {
    return {
      color: VARIANT_MAP[`${this.variantType}`].accountIconMainColor,
    };
  }

  getActiveButtonHighlightStyles(): { [k: string]: string } {
    const isActive =
      this.title === this.activeTab || this.parentActiveTab === this.title;

    return {
      width: isActive ? '104px ' : null,
      height: isActive ? '3px ' : null,
      'background-color':
        VARIANT_MAP[`${this.variantType}`].activeHighlightColor,
    };
  }

  getMobileDropdownWrapperStyles(): { [k: string]: string } {
    return {
      'border-bottom': `1px solid ${VARIANT_MAP[`${this.variantType}`].mobileBorder}`,
    };
  }

  getMobileListButtonStyles(): { [k: string]: string } {
    return {
      color: VARIANT_MAP[`${this.variantType}`].activeTextColor,
      ...(this.isActiveMobileListButton && {
        'font-weight': VARIANT_MAP[`${this.variantType}`].activeFontWeight,
        color: VARIANT_MAP[`${this.variantType}`].activeTextColor,
      }),
    };
  }

  setActiveMobileListButton(value: boolean) {
    this.isActiveMobileListButton = value;
  }
}
