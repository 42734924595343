export class SharedRegexUtility {
    public static readonly basicLink: RegExp =
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;

    public static readonly urlStartWithNewRoot = /^(https?:)?(\/\/)/;

    public static wholeNumberOnly = /^\d*$/;

    public static readonly numbersWithOptionalDecimal = /^\d*\.?\d*$/;

    public static readonly termYearAndMonthGetDigits = /^(\d)(?:\s(?:Year)(?:s?)\s)(\d)(?:\s(?:Month)(?:s))$/gi;
}
