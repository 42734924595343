export const EXCHANGE_MIN_AGE = 40;
export const EXCHANGE_MAX_AGE = 100;
export const EXCHANGE_STRATEGY_MAX = 4;

export const ANNUITY_SHELL_PRODUCT = 'SHELL';

export const ANNUITY_PRODUCT_TYPES = {
    ALL: 'ALL',
    DIA_SPIA: 'DIA/SPIA',
    FIA: 'FIA',
    FIXED_RATE: 'Fixed Rate',
    RILA: 'RILA',
    VARIABLE_ANNUITIES: 'Variable Annuities'
};

export const ANNUITY_USER_TYPES = {
    BROKER_DEALER: 'Distributor',
    CARRIER: 'Carrier'
};

export const FRONTIER_SERIES = {
    MAIN_SERIES: {
        index: 0,
        radius: 2,
        name: 'Main'
    },
    ALLOCATED_POINT: {
        index: 1,
        radius: 7,
        name: 'Allocated Point'
    }
};

export const VA_BUCKETS = {
    US_SMALL_CAP_EQ: 'LMAUSSMALL',
    US_SMALL_MID_CAP_EQ: 'LMAUSMID',
    US_MID_CAP_BALANCE_EQ: 'LMAUSMCAPB',
    US_MID_LARGE_EQ: 'LMAMLCAP',
    US_LARGE_CAP_EQ: 'LMAUSLCAP',
    US_ALL_CAP_EQ: 'LMAUSACAPE',
    WORLD_EQ: 'LMAWLDE',
    FOREIGN_EQ: 'LMAFORNE',
    EMERGING_MARKETS: 'LMAEMEMARK',
    US_HIGH_QUALITY_FIXED_INC: 'LMAHQFXIC',
    US_MED_QUALITY_FIXED_INC: 'LMAMQFXIC',
    US_LOW_QUALITY_FIXED_INC: 'LMALQFXIC',
    GLOBAL_FIXED_INC: 'LMAGLBFXIC',
    MONEY_MARKET_CASH_EQUIV: 'LMAMMCE',
    INTERNATIONAL_FIXED_INC: 'LMAINTFXIC',
    ALLOCATION_FUND_BUCKET: 'ALLOCATIONFUND',
    OTHER: 'OTHER'
};
