import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { GridState } from './common/models/grid-state';
import { SpDesk, SpProgram } from './structured-products/common/models/SpDesk';
import { StructuredProductsExternalFilterValue } from './structured-products/structured-products-2.0/structured-products-grid/structured-products-external-filters';
import { OrderPreferences } from '../marketplace-settings/order-preferences/order-preferences-model';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceStateService {
  $SPFilterValues = new BehaviorSubject(null);
  $SPFilterValues2 = new BehaviorSubject(null);
  desksAndPrograms: SpDesk[];
  $desksAndPrograms: ReplaySubject<SpDesk[]> = new ReplaySubject<SpDesk[]>(1);
  desk: BehaviorSubject<SpDesk> = new BehaviorSubject(null);
  deskOrderPreferences: OrderPreferences;
  programs: BehaviorSubject<SpProgram[]> = new BehaviorSubject([]);
  $showCanceledOrder: BehaviorSubject<boolean> = new BehaviorSubject(false);
  $orderCancelled: Subject<any> = new Subject();
  $marketplaceTabChange: Subject<number> = new Subject();

  historicalOrderSelect: string;
  historicalOrdersStartDate: string;
  historicalOrdersEndDate: string;
  saveEditsAndOpenOrderTicket: Subject<any> = new Subject();

  $structuredProductsFilterValues: BehaviorSubject<StructuredProductsExternalFilterValue> = new BehaviorSubject(null);
  $structuredProductsGridProductData: BehaviorSubject<any> = new BehaviorSubject(null);
  $resetFilterStructuredProductsGrid: Subject<null> = new Subject();

  oldGridState: GridState = null;
  newGridState: GridState = null;
  editGridState: GridState = null;

  updateDesksAndPrograms(desksAndPrograms) {
    for (const entry of desksAndPrograms) {
        for (const x of entry.programs) {
          x.checked = (this.desk.value?.id === entry.id && this.programs.value) ? (this.programs.value.find(y => y.id === x.id)?.checked || true): true;
        }
    }
    if(desksAndPrograms.length === 1 && (!this.desk.value || this.desk.value?.id !== desksAndPrograms[0].id)) {
      this.desk.next(desksAndPrograms[0]);
      this.programs.next(desksAndPrograms[0].programs);
    }
    return desksAndPrograms;
  }
}


export enum BasketType {
  EQUAL = 'Equal',
  CUSTOM = 'Custom',
  WOF = 'WoF',
  BOF = 'BoF',
  OUT_PERFORMANCE = 'Out Performance',
  SINGLE = 'Single'
}
