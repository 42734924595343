import {
  AfterViewInit,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  HostListener,
  QueryList,
} from '@angular/core';
import { CarouselItemDirective } from './carousel-item.directive';

@Directive({
  selector: '[appCarouselController]',
  exportAs: 'appCarouselController',
})
export class CarouselControllerDirective implements AfterViewInit {
  @ContentChildren(CarouselItemDirective, {
    read: ElementRef,
    descendants: true,
  })
  items: QueryList<CarouselItemDirective>;

  @ContentChild('carouselRow') row!: ElementRef;
  disablePrevious = true;
  disableNext = false;
  // tslint:disable-next-line:variable-name
  _currentIndex = 0;
  // tslint:disable-next-line:variable-name
  _currentOffSet = 0;
  // Hook
  showButton = false;
  //
  previousLength = 0;

  get currentOffSet() {
    return this._currentOffSet;
  }

  // Since everytime we set the offset, we want to transform the style
  set currentOffSet(value: number) {
    this._currentOffSet = value;
    this.row.nativeElement.scrollLeft = this._currentOffSet;
    if (this.row.nativeElement.scrollWidth <= this.currentOffSet + this.row.nativeElement.offsetWidth) {
      this._currentOffSet = this.row.nativeElement.scrollLeft;
      this._currentIndex = this.items.length - 1;
      this.disableNext = true;
    }
  }

  get currentIndex() {
    return this._currentIndex;
  }

  set currentIndex(value: number) {
    this._currentIndex = value;

    if (this.currentIndex <= 0) {
      this.currentOffSet = 0;
      this._currentIndex = 0;
      this.disablePrevious = true;
    } else {
      this.disablePrevious = false;
    }

    this.disableNext = this.currentIndex + 1 >= this.items.length;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.showButton = this.row.nativeElement.scrollWidth > this.row.nativeElement.offsetWidth;
      // TODO - Make Better
      this.currentIndex = 0;
      // if (!this.showButton) {
      //   this.currentIndex = 0;
      // }
    });
  }

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.items.changes?.subscribe({
      next: value => {
        if (this.previousLength !== value.length) {
          // TODO - Spend time fixing - ZF
          // if (this.previousLength > 0 && this.currentIndex === this.previousLength - 1) {
          //   this._currentIndex--;
          //   this.next();
          // }
          // if (this.previousLength > value.length && this.previousLength >= this.currentIndex) {
          //   this.currentIndex = this.previousLength - 1;
          //   this.setLeftMost();
          // }
          this.previousLength = value.length;
        }
        this.onResize(null);
      }
    });
    this.onResize(null);
  }

  previous() {
    if ((this.items.length - 1) === this.currentIndex ) {
      this.setLeftMost();
    } else {
      this.currentOffSet -= this.getElementWidth(--this.currentIndex);
    }
  }

  next() {
    this.currentOffSet += this.getElementWidth(++this.currentIndex);
  }

  getElementWidth(index: number) {
    const nativeElement = (this.items?.get(index) as any)?.nativeElement;
    return this.getWidth(nativeElement);
  }

  getWidth(nativeElement, includePadding = false): number {
    const style = this.getStyle(nativeElement);
    const width = nativeElement.offsetWidth;
    const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
    // TODO - figure out when to need this based on styling, etc.
    return includePadding ? width + margin + padding + border : width + margin + border;
  }

  getStyle(nativeElement) {
    return nativeElement.currentStyle || window.getComputedStyle(nativeElement);
  }

  setLeftMost(): void {
    if (this.currentIndex > 0) {
      const leftMost = this.getLeftMost();
      if (leftMost > 0) {
        this.currentOffSet = this.items
          .toArray()
          .slice(0, leftMost)
          .reduce((previousValue: number, currentItem: any) => {
            return previousValue + this.getWidth(currentItem.nativeElement);
          }, 0) as number;
      }
      this.currentIndex = leftMost;
    }
  }

  getLeftMost(): number {
    const index = this.items.toArray().findIndex((item: any) => {
      const currentItemRect = item.nativeElement.getBoundingClientRect();
      const rowRect = this.row.nativeElement.getBoundingClientRect();
      return (currentItemRect.left === rowRect.left) || (currentItemRect.left < rowRect.left && currentItemRect.right  > rowRect.left);
    });
    return index < 0 ? 0 : index;
  }
}
