import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { Subscription } from "rxjs";
import { WhiteLabelUtils } from "../../../admin/company-configuration/white-label.utils";
import { IncompleteTrainingsDialogComponent } from "../../../dashboard/incomplete-trainings-dialog/incomplete-trainings-dialog.component";
import { ConfirmOrCancelModalComponent } from "../../confirm-or-cancel-modal/confirm-or-cancel-modal.component";
import { User } from "../../models/user";
import { NavService } from "../../services/nav.service";
import { WidgetPermissions } from "../../services/ui.widget.permissions";
import { UserService } from "../../services/user.service";
import { FrontEndLibraries, NavTabType } from "../global-nav/global-nav.types";
import { buildAdminOptions } from "../global-nav/utils/buildAdminOptions";
import { buildNavTabs, loadDefaultNavTabs } from "../global-nav/utils/build-nav-tabs";

@Component({
  selector: "app-global-nav-container",
  templateUrl: "./global-nav-container.component.html",
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalNavContainerComponent implements OnDestroy, OnInit {
  userSub: Subscription;

  tabs = loadDefaultNavTabs(FrontEndLibraries.ANGULAR);
  adminOptions = null;
  user: User = null;
  contactEmail: string;
  issuerLogo: string;

  constructor(
    private userService: UserService,
    private navService: NavService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.handleNavigate = this.handleNavigate.bind(this);
    this.logout = this.logout.bind(this);
    this.userSub = this.userService.$user.subscribe((user: User) => {
      this.user = user;
      if (!!user) {
        this.buildNav();
      }
    });
  }

  ngOnInit(): void {
    this.initializeValues();
  }

  private buildNav() {
    // we do the following because at build time environment.production gets replaced with a string to be replaced at deploy-time,
    // so it would otherwise fail the boolean check in the following functions
    const isProduction = typeof environment.production === "boolean" ? environment.production : false;
    const userPermissions = this.userService.getUserPermissions();
    const company = this.userService.getCompany();
    const permissions = this.userService.getPermissions();
    if (this.user?.userDisplayConfig?.displayLegacyLifecycleManager) {
      userPermissions.push("custom.menu.lifecycle_manager.legacy");
    }
    this.tabs = buildNavTabs(
      this.user?.userDisplayConfig,
      userPermissions,
      this.user.trainingData.productTypes,
      FrontEndLibraries.ANGULAR,
      isProduction,
      company,
      permissions
    );
    this.adminOptions = buildAdminOptions(this.user.permissions, FrontEndLibraries.ANGULAR, isProduction);
  }

  public handleNavigate(navTab: NavTabType) {
    const { title, route, url } = navTab;

    if (title === "LEARN") {
      this.learningCenterNavigation(route);
      return;
    }

    if (title === "Structured Products" && !url) {
      this.marketplaceNavigation(route);
      return;
    }

    if (route) {
      this.router.navigate([route]);
      return;
    } else if (url) {
      if (url.includes("portal.lumafintech.com") || url.includes("portal.naviancapital.com")) {
        this.navService.navigateWithCst(url);
      } else {
        window.location.href = url;
      }
    }
  }

  learningCenterNavigation(route: string) {
    if (this.user?.canAccess(WidgetPermissions.AccessLearningCenter)) {
      this.router.navigate([route]);
    } else {
      const dialogConfig: MatDialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: "Your account does not have access to the learning center.",
        title: "No Access",
        singleButton: true,
      };
      dialogConfig.panelClass = ["confirmation-dialog", "l-w400"];
      this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig);
    }
  }

  marketplaceNavigation(route: string) {
    const trainingData = this.userService.getUser().trainingData;
    if (trainingData?.complete) {
      this.router.navigate([route]);
      this.navService.closeSideNav.next(true);
    } else {
      const dialogConfig: MatDialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ["confirmation-dialog", "l-w500"];
      this.dialog.open(IncompleteTrainingsDialogComponent, dialogConfig);
    }
  }

  ngOnDestroy() {
    this.userSub?.unsubscribe();
  }

  logout() {
    this.userService.logout();
  }

  initializeValues() {
    this.issuerLogo = WhiteLabelUtils.getWhiteLabelLogo(this.user?.whiteLabelInfo, this.user?.defaultProgramId);
    this.contactEmail = WhiteLabelUtils.getContactEmail(this.user?.whiteLabelInfo);
  }
}
