import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogProgressSpinnerComponent } from '@common/dialog-progress-spinner/dialog-progress-spinner.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinner = new Subject<boolean>();
  spinnerDialog: MatDialogRef<DialogProgressSpinnerComponent>;

  constructor(private dialog: MatDialog) { }

  showSpinner(restoreFocus: boolean = false) {
    if(!this.spinnerDialog) {
      this.spinnerDialog = this.openSpinnerDialog(restoreFocus);
    }
  }

  hideSpinner() {
    if(this.spinnerDialog) {
      this.spinnerDialog.close();
      this.spinnerDialog = null;
    }
  }

  private openSpinnerDialog(restoreFocus: boolean): MatDialogRef<DialogProgressSpinnerComponent> {
    return this.dialog.open(DialogProgressSpinnerComponent, {
      panelClass: 'transparent',
      disableClose: true,
      restoreFocus
    });
  }
}
