import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderDetails } from '@product-marketplace/common/models/order-details';


@Component({
  selector: 'app-order-form-dialog',
  templateUrl: './order-form-dialog.component.html',
  styleUrls: ['./order-form-dialog.component.scss']
})
export class OrderFormDialogComponent {

  configOptions: any;
  message: string;
  newOrder = false;
  orderResponse: OrderDetails;
  status: string;
  title: string;

  constructor(private dialogRef: MatDialogRef<OrderFormDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.message = data.message;
    if (data.newOrder) {
      this.newOrder = data.newOrder;
    }
    this.orderResponse = data.orderResponse;
    this.status = data.status;
    this.title = data.title;
    this.configOptions = data.configOptions;
  }

  close() {
    if (this.orderResponse?.orderApprovalResults?.length > 0 && this.orderResponse.orderApprovalResults[0]?.approvalType === 'KYC') {
      this.dialogRef.close('viewOrder');
    } else {
      this.dialogRef.close();
    }
  }

  resetForm() {
    this.dialogRef.close('newOrder');
  }
}
