// Angular  imports
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MaterialModule } from '@common/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';

// Dev generated components/modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from '@common/navigation/footer/footer.component';
import { HeaderComponent } from '@common/navigation/header/header.component';
import { GlobalNavContainerComponent } from './common/navigation/globalNav/global-nav-container.component';
import { GlobalNavComponent } from '@common/navigation/global-nav/global-nav.component';
import { SideMenuModule } from '@common/navigation/side-menu/side-menu.module';
import { UserMenuModule } from '@common/navigation/user-menu/user-menu.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './company-modules/login/login.module';
import { DocumentService } from '@common/services/document.service';
import { NavService } from '@common/services/nav.service';
import { UpdateSnackbarComponent } from '@common/update-snackbar/update-snackbar.component';
import { ErrorModalComponent } from '@common/error-modal/error-modal.component';
import { DialogProgressSpinnerComponent } from '@common/dialog-progress-spinner/dialog-progress-spinner.component';
import { AuthInterceptor } from '@common/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiMonthPickerComponent } from '@common/multi-month-picker/multi-month-picker.component';
import { AuctionMultiMonthPickerComponent } from '@common/multi-month-picker/auction-multi-month-picker.component';
import { NotFoundComponent } from '@common/not-found/not-found.component';
import { InternalErrorInterceptor } from '@common/internal-error.interceptor';
import { SsoRequiredComponent } from './company-modules/sso-required/sso-required.component';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';
import { IconsModule } from './icons.module';
import { SessionService } from './session.service';
import { InitializeService } from './initializeService';
import { NoScrollInputDirective } from '@common/directives/prevent-number-scroll.directive';
import { ToastrModule } from 'ngx-toastr';
import { MobileMenuComponent } from './common/navigation/global-nav/mobile-menu/mobile-menu.component';
import { NavTabButtonComponent } from './common/navigation/global-nav/nav-tab-button/nav-tab-button.component';
import { NavTabBarComponent } from './common/navigation/global-nav/nav-tab-bar/nav-tab-bar.component';
import { DropdownNavTabComponent } from './common/navigation/global-nav/dropdown-nav-tab/dropdown-nav-tab.component';
import { MobileDropdownComponent } from './common/navigation/global-nav/mobile-dropdown/mobile-dropdown.component';
import { MobileTabButtonComponent } from './common/navigation/global-nav/mobile-tab-button/mobile-tab-button.component';
import { PoweredByLumaLogoComponent } from './common/navigation/global-nav/powered-by-luma-logo/powered-by-luma-logo.component';
import { ProfileDropdownComponent } from './common/navigation/global-nav/profile-dropdown/profile-dropdown.component';
import { ProfileHeaderDropdownComponent } from './common/navigation/global-nav/profile-header-dropdown/profile-header-dropdown.component';
import { ProfileMobileDropdownComponent } from './common/navigation/global-nav/profile-mobile-dropdown/profile-mobile-dropdown.component';
import { TopNavBarComponent } from './common/navigation/global-nav/top-nav-bar/top-nav-bar.component';
import { SessionIdInterceptor } from '@common/interceptors/session-id-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    AuctionMultiMonthPickerComponent,
    DialogProgressSpinnerComponent,
    FooterComponent,
    HeaderComponent,
    GlobalNavContainerComponent,
    GlobalNavComponent,
    MultiMonthPickerComponent,
    NotFoundComponent,
    UpdateSnackbarComponent,
    SsoRequiredComponent,
    ErrorModalComponent,
    NoScrollInputDirective,
    MobileMenuComponent,
    NavTabButtonComponent,
    NavTabBarComponent,
    DropdownNavTabComponent,
    MobileDropdownComponent,
    MobileTabButtonComponent,
    PoweredByLumaLogoComponent,
    ProfileDropdownComponent,
    ProfileHeaderDropdownComponent,
    ProfileMobileDropdownComponent,
    TopNavBarComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DashboardModule,
    FlexLayoutModule,
    IconsModule,
    FormsModule,
    HttpClientModule,
    LoginModule,
    MaterialModule,
    SideMenuModule,
    UserMenuModule,
    HammerModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-position',
      preventDuplicates: true,
      enableHtml: true,
    })
  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [InitializeService],
      useFactory: initializeUser,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternalErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionIdInterceptor,
      multi: true,
    },
    DocumentService,
    NavService,
    CookieService,
    SessionService,
  ],
  bootstrap: [AppComponent],
  exports: [MaterialModule],
})
export class AppModule {}

// Due to environment variables being set during the deployment, we need to run this to trigger angular to re-evaluate the (now updated) environment variables
// sorry for the hack. -- jh 6/25/2021
function initializeApp(): Promise<any> {
  return new Promise((resolve, reject) => {
    for (const foo in environment) {
      if (environment.hasOwnProperty(foo)) {
        environment[foo] = environment[foo];
      }
    }
    resolve(null);
  });
}

export function initializeUser(
  initializeService: InitializeService
): () => Promise<void> {
  return (): Promise<void> => {
    return initializeService.initUser();
  };
}
