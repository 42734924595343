import { Component, Input } from '@angular/core';
import { FilterGroup } from '../../../../filter-lib/base-filter.model';
import { Subscription } from 'rxjs';
import { Utilities } from '@common/utilities/utilities';
import { NumberInputFilter } from '../../../../filter-lib/number-input-filter';

@Component({
  selector: 'app-number-input-filter',
  templateUrl: './number-input-filter.component.html',
  styleUrls: ['./number-input-filter.component.scss']
})
export class NumberInputFilterComponent {
  currentValue: number;
  formattedValue: string;
  filterModel: FilterGroup<any>;
  changeSub: Subscription;
  label: string;
  filterValue: number;
  private _filter: NumberInputFilter<any>;
  get filter() {
    return this._filter;
  }

  @Input() set filter(filterInput: NumberInputFilter<any>) {
    this._filter = filterInput;
    this.label = this._filter.baseFilterModel.displayName;
    this.filterValue = this._filter.baseFilterModel.filterValues?.[0] || null;
    this.formattedValue = this.filterValue?.toString();
  }

  onValueChange(event) {
    this.filter.valueUpdated(+Utilities.stripCurrencyFormatting(event));
  }
}
