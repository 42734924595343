enum ErrorModalType {
    DEFAULT = 'Default',
    CONCURRENCY = 'Concurrency',
    QUOTE_REJECTION ='Quote Rejection',
    OFFERING_ERROR_RESPONSE = 'Offering Error Response',
    DEFAULT_WITH_NAVIGATION = 'Default With Navigation',
    DEADLINE_TIME = 'Deadline Time',
    CONFIRM_OFFERING = 'Confirm Offering'
}

export default ErrorModalType;
