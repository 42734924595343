import {
  FilterGroup, FilterOption,
  GenericFilterTypes,
  TwoFieldFilterType,
} from './base-filter.model';
import { BaseFilter } from './base-filter';
import { FilterMethods } from './filter-methods.utilities';

export interface RangeTwoFieldFilterModel<U extends GenericFilterTypes = TwoFieldFilterType> extends FilterGroup<U> {
  // Overrides
  searchType: 'inclusive' | 'exclusive';
  showSlider?: boolean;
}

export class NumberRangeTwoFields<U extends GenericFilterTypes = TwoFieldFilterType> extends BaseFilter<U> {
  // tslint:disable-next-line:variable-name
  constructor(protected _baseFilterModel: RangeTwoFieldFilterModel<U>) {
    super(_baseFilterModel);
    _baseFilterModel.filters?.forEach((filter: FilterOption<U> ) => {
      const searchValue = filter.searchValue as TwoFieldFilterType;
      if (searchValue.currentVal == null) {
        searchValue.currentVal = searchValue.min;
        this.valueUpdated();
      }
    });
  }

  applyFilter<T>(dataToBeFiltered: T[]): T[] {
    return FilterMethods.applySingleRangeFilter(this._baseFilterModel, dataToBeFiltered);
  }

  doesFilterPass(dataToBeFiltered: any[]): boolean {
    return FilterMethods.doesSingleRangeFilterApply(this._baseFilterModel, dataToBeFiltered);
  }

  clearFilters(closeExpansion: boolean) {
    if (closeExpansion) {
      this._baseFilterModel.expanded = false;
    }

    this.baseFilterModel.filters?.forEach((filter: FilterOption<any>) => {
      const searchValue = filter.searchValue as TwoFieldFilterType;
      searchValue.currentVal = searchValue.min;
      filter.selected = true;
    });
    this.filterApplied = false;
    this.filterValuesChanged();
  }

  applyFilterView(filters: FilterGroup<any>) {
    for (const [index, filter] of filters.filters?.entries()) {
      if (!this._baseFilterModel.filters?.[index]) {
        this._baseFilterModel.filters[index] = filter;
      } else {
        const currentFilterValue = this._baseFilterModel.filters[index].searchValue as TwoFieldFilterType;
        const incomingFilterValue = filters.filters[index].searchValue as TwoFieldFilterType;
        if (currentFilterValue.max < incomingFilterValue.max) {
          currentFilterValue.max = incomingFilterValue.max;
        }
        if (currentFilterValue.min > incomingFilterValue.min) {
          currentFilterValue.min = incomingFilterValue.min;
        }
        if (incomingFilterValue.currentVal !== incomingFilterValue.currentVal) {
          currentFilterValue.currentVal = incomingFilterValue.currentVal;
        }
      }
    }
  }

  valueUpdated() {
    this._baseFilterModel.filters.forEach((filter) => {
      const searchValue = filter.searchValue as TwoFieldFilterType;
      filter.selected = searchValue.currentVal !== searchValue.min;
    });
    this.filterValuesChanged();
  }
}
