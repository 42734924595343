import { BasketType } from '@product-marketplace/marketplace-state.service';

export const NO_CALL_PERIOD_CALL_TYPE_LIST = ['AUTO', 'AUTOCALL STEP', 'ISSUER'];
export class MarketplaceUtilities {

  static getNoCallPeriod(product) {
    const noCallPeriod = MarketplaceUtilities.calculateNoCallPeriodInMonths(product);
    if(noCallPeriod) {
      return noCallPeriod === 1 ? noCallPeriod + ' Month' : noCallPeriod + ' Months';
    } else {
      return null;
    }
  }

  static calculateNoCallPeriodInMonths(product) {
    const noCallPeriod = product?.noCallPeriod;
    const callObservationFrequency = product?.callObservationFrequency?.toUpperCase();
    const redemptionType = product?.redemptionType?.toUpperCase();
    if (isNaN(noCallPeriod) || !callObservationFrequency || !NO_CALL_PERIOD_CALL_TYPE_LIST.includes(redemptionType)) {
      return noCallPeriod;
    }
    let callObservationFrequencyInMonths;
    switch (callObservationFrequency) {
      case 'ANNUALLY':
        callObservationFrequencyInMonths = 12;
        break;
      case 'SEMI-ANNUALLY':
        callObservationFrequencyInMonths = 6;
        break;
      case 'QUARTERLY':
        callObservationFrequencyInMonths = 3;
        break;
      case 'MONTHLY':
        callObservationFrequencyInMonths = 1;
        break;
      case 'WEEKLY':
        callObservationFrequencyInMonths = .230769;
        break;
      case 'DAILY':
        callObservationFrequencyInMonths = .032877;
        break;
      default:
        return noCallPeriod;
    }
    const numVal = (parseInt(noCallPeriod, 10) + 1) * callObservationFrequencyInMonths;
    const displayNumber = Number.isInteger(numVal) ? numVal : numVal.toFixed(2);
    return displayNumber;
  }

  static getProductIdentifier(registrationType: string, cusip: string, isin: string) {
    return registrationType?.toLowerCase() === 'reg s' ? isin : (cusip || '');
  }

  static getSettlementParty(wholesaler: string,issuer: string, overrideToDirect: boolean ) {
    return (!wholesaler || overrideToDirect || wholesaler === 'Direct')
    ? issuer : wholesaler;
  }

  static getUnderlierModifier(underliers: string[], basketType: BasketType) {
    if (underliers.length) {
      if (basketType === BasketType.WOF) {
        return 'Lesser of';
      } else if (basketType === BasketType.BOF) {
        return 'Greater of';
      }
      return null;
    }
  }

  static convertPercentRangeToFIxedDecimalPlaces(value: string, decimalPLaces: number): string {
    if(!value || value === '' || value === '-') { return value;}
    const splitString = value.split('-');
    for(let i=0; i < splitString.length; i++) {
      splitString[i] = (+(splitString[i].replace('%', ''))).toFixed(decimalPLaces) + '%';
    }
    return splitString.length > 1 ? `${splitString[0]} - ${splitString[1]}` : splitString[0];
  }
}
