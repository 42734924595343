import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProductInfo } from '@common/components/product-details-modal/models/product-info';
import { getProductDetailConfigsOrder } from '@common/components/product-details-modal/models/product-details-order';
import { isErrorCusip } from '@common/components/product-details-modal/models/error-product';
import { ComponentEventsService } from '@common/components/product-details-modal/services/component-events.service';

@Component({
  selector: 'app-product-details-modal-core',
  templateUrl: './product-details-modal-core.component.html',
  styleUrls: ['./product-details-modal-core.component.scss']
})
export class ProductDetailsModalCoreComponent implements OnInit, AfterViewInit {
  @ViewChild('productDetailsCardContent') productDetailsCardContent: ElementRef;
  @Input() productInfo: ProductInfo;
  productDetailsMapSelected;

  constructor( private componentEventService: ComponentEventsService ) {}

  ngOnInit() {
    this.productDetailsMapSelected = getProductDetailConfigsOrder(this.productInfo);
    if(isErrorCusip(this.productInfo.productIdentifier)) {
      return;
    }
  }

  ngAfterViewInit() {
    this.componentEventService.updateProductDetailsHeight(this.productDetailsCardContent.nativeElement.clientHeight);
  }

  convertRedemptionType(type: string): string {
    switch(type) {
      case 'Issuer':
        return 'Issuer Callable';
      case 'Auto':
        return 'Auto Callable';
      case 'Auto Step':
        return 'Auto Callable';
      default:
        return type;
    }
  }
}
