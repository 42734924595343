import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ErrorModalType from '@creation-hub-ubs/common/enums/ErrorModalType';
import { environment } from '@environments/environment';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit, OnDestroy {

  routerSub: Subscription;
  type = ErrorModalType.DEFAULT;
  public isProdEnv: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private router: Router,
              private dialogRef: MatDialogRef<ErrorModalComponent>) {
    this.type = this.data.type;
  }

  ngOnInit(): void {
    if (environment?.environmentName?.toLocaleLowerCase() === 'prod') {
      this.isProdEnv = true;
    }
    this.routerSub = this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public get ErrorModalType() {
    return ErrorModalType;
  }

  ngOnDestroy() {
    this.routerSub?.unsubscribe();
  }
}
