export const COLUMN_IDS = {
  PRODUCT: 'product',
  UNDERLIER: 'underlier',
  TERM: 'term',
  PAYMENT_DETAILS: 'paymentDetails',
  PROTECTION: 'protection',
  DATES: 'dates',
  CALL_PARAMETERS: 'callParameters',
  PRICING: 'pricing',
  CREATED: 'created',
  ACTIONS: 'actions',
  SELECT: 'select'
};

export const NO_CALL_TYPE = 'No Call Type';
export const NO_SETTLEMENT_PARTY = 'No Settlement Party';
export const NO_ISSUER = 'No Issuer';
export const NO_PROTECTION = 'No Protection';
export const NO_REGISTRATION_TYPE = 'No Registration Type';
export const INCOMPLETE_PRODUCT_WARNING = 'Not all product features are present in the display, please reference the term sheet for full product details.';
