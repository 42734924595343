import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-simple-tooltip-dialog',
    template: '<span>{{value}}</span>'
  })
  export class SimpleTooltipDialogComponent {
    value;

    constructor(
        @Inject(MAT_DIALOG_DATA) data) {
        this.value = data.value;
      }
  }
