export const CSS_COLORS = {
  BUTTON_HOVER: '#4594B0',
  CONFIRM_GREEN: '#46B11D',
  CTA_TABS_FILL_ACTIVE: '#CBE880',
  DARKENING_BACKGROUND: 'RGBA(0, 0, 0, 0.75)',
  DISABLED_GREY: '#E8E8E8',
  DISABLED_TEXT_GREY: '#868686',
  ENTRY_FIELD_GREY: '#F1F1F1',
  ERROR_RED: '#AF2A2A',
  GRAPHING_BLUE_GRAY_1: '#435560',
  GRAPHING_BLUE_GRAY_2: '#546A78',
  GRAPHING_BLUE_GRAY_3: '#647F90',
  GRAPHING_BLUE_GRAY_4: '#7A92A2',
  GRAPHING_BLUE_GRAY_5: '#93A7B4',
  GRAPHING_LUMA_DAINTREE_1: '#113D55',
  GRAPHING_LUMA_DAINTREE_2: '#185677',
  GRAPHING_LUMA_DAINTREE_3: '#1E6E99',
  GRAPHING_LUMA_DAINTREE_4: '#2585B9',
  GRAPHING_LUMA_DAINTREE_5: '#339DD7',
  GRAPHING_LUMA_HALF_BAKED_1: '#2F6274',
  GRAPHING_LUMA_HALF_BAKED_2: '#3B7A91',
  GRAPHING_LUMA_HALF_BAKED_3: '#4792AE',
  GRAPHING_LUMA_HALF_BAKED_4: '#5FA5BE',
  GRAPHING_LUMA_HALF_BAKED_5: '#7DB5CA',
  GRAPHING_LUMA_SELECTIVE_YELLOW_1: '#876708',
  GRAPHING_LUMA_SELECTIVE_YELLOW_2: '#AE850A',
  GRAPHING_LUMA_SELECTIVE_YELLOW_3: '#D4A20C',
  GRAPHING_LUMA_SELECTIVE_YELLOW_4: '#F2BB17',
  GRAPHING_LUMA_SELECTIVE_YELLOW_5: '#F4C63E',
  GRAPHING_LUMA_YELLOW_GREEN_1: '#434F17',
  GRAPHING_LUMA_YELLOW_GREEN_2: '#5D6E21',
  GRAPHING_LUMA_YELLOW_GREEN_3: '#788D2A',
  GRAPHING_LUMA_YELLOW_GREEN_4: '#93AE34',
  GRAPHING_LUMA_YELLOW_GREEN_5: '#AAC743',
  GRID_BLUE_1: '#7F929E',
  GRID_BLUE_2: '#D9DFE2',
  GRID_BLUE_3: '#BFC9CF',
  HOVER_GREY: '#DADADA',
  INPUT_FIELD_GREY: '#CDCDCD',
  LINK_BLUE: '#2A86D1',
  LUMA_DAINTREE: '#00263E',
  LUMA_HALF_BAKED: '#93C3D5',
  LUMA_JET: '#292929',
  LUMA_ORANGE: '#FF961C',
  LUMA_SELECTIVE_YELLOW: '#F5B901',
  LUMA_YELLOW_GREEN: '#C9DE7D',
  MEDIUM_GREY: '#717171',
  PLATFORM_NAVIGATION_REGULAR_USER_ADMIN_DESELECTED: '#033956',
  PRODUCT_INFORMATION_PRODUCT_SECTION_BACKGROUND_ACTIVE: '#E5E5E5',
  PRODUCT_INFORMATION_PRODUCT_SECTION_BACKGROUND_HOVER: '#D2D2D2',
  SELECTED_GREY: '#BBB',
  TAG_BLUE: '#B6D6E2',
  TAG_GREEN: '#D7E79D',
  TAG_GREY: '#EFF2F4',
  TEXT_GREY: '#333538',
  WHITE: '#FFF',
  LUMA_LINK: '#1A75E0'
};
