import { Component, OnInit, Input } from '@angular/core';
import { ClientTraining, DashboardService } from '../dashboard.service';
import { environment } from '@environments/environment';
import { UserService } from '@common/services/user.service';

export class ClientTrainingElement {
  name: string;
  type: string;
  score: string;
  action: string;
  status: string;

  constructor(x: ClientTraining) {
    this.name = x.testName;
    this.type = x.productType;
    this.score = x.trainingScore;
    this.action = x.complianceLinkName;
    this.status = x.trainingStatus;
    /* todo finish button
    complianceLinkName?: string;
    expiryDate?: string;
    productType?: string;
    testName?: string;
    trainingDate?: string;
    trainingId?: number;
    trainingScore?: string;
    trainingStatus?: string;
    trainingType?: string;
    userId?: string;
    */
  }
}

@Component({
  selector: 'app-compliance-training',
  templateUrl: './compliance-training.component.html',
  styleUrls: ['../mat-card-table.scss', './compliance-training.component.scss']
})
export class ComplianceTrainingComponent implements OnInit {
  @Input() newLC: boolean;
  coldFusionHost = environment.hosts.portal;
  columns: string[] = ['testName', 'testType', 'testScore', 'testAction'];
  data: ClientTrainingElement[];
  apiError: any;

  constructor(private service: DashboardService, private userService: UserService) { }

  ngOnInit() {
    this.service.clientTraining().subscribe(
      (data: ClientTraining[]) => {
        if (data != null) {
          this.data = data.map(x => new ClientTrainingElement(x));
        } else {
          this.data = [];
        }
        this.apiError = null;
      },
      error => {
        this.data = null;
        this.apiError = error;
      }
    );
  }

  accessToken(): string {
    return this.userService.getToken();
  }

  refreshToken(): string {
    return this.userService.getRefreshToken();
  }
}
