import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterComponents } from '../../../../filter-lib/filter-types.enum';
import { FilterGroup } from '../../../../filter-lib/filter-group';
import { FilterGroup as FG } from '../../../../filter-lib/base-filter.model';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-filter-set',
  templateUrl: './filter-set.component.html',
  styleUrls: ['./filter-set.component.scss']
})
export class FilterSetComponent implements OnInit {
  @Input() numberOfVisibleFilters = 4;
  @Input() filterGroup: FilterGroup;
  @Input() externalFilterApplied = false;
  @Input() disableMoreFilters = false;
  @Output() resetFilters = new EventEmitter<null>();
  isOpen = false;
  filterTypes = FilterComponents;

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 0,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
    ];

  ngOnInit(): void {
    this.numberOfVisibleFilters = this.numberOfVisibleFilters > this.filterGroup.filters.length
      ? this.filterGroup.filters.length : this.numberOfVisibleFilters;
  }

  open() {
    if(!this.disableMoreFilters) {
      this.isOpen = !this.isOpen;
    }
  }

  close() {
    this.isOpen = false;
  }

  toggleFilterExpand(filter: FG<any>) {
    filter.expanded = !filter.expanded;
  }

  resetFilterValues() {
    if (this.filterGroup.someFiltersApplied || this.externalFilterApplied) {
      this.resetFilters.emit();
    }
  }
}
