import {
  MisfitRate,
  MisFitStrategy
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';
import { Utilities } from '@common/utilities/utilities';

export class AnnuityMisfitUtils {
  static formatAllocatedMisfit(misFitRate: MisfitRate) {
    return `${misFitRate.underlieIndex}: ${Utilities.formatPercentageFromDecimal(misFitRate.weight)}`;
  }

  static formatThresholdMisfit(misFitRate: MisfitRate) {
    const maxPercentRange = 999.9999;
    const minPercentRange = 0;
    const startingRange = +misFitRate.percentRangeMin;
    const endingRange = +misFitRate.percentRangeMax;
    let range = '';
    if (startingRange === minPercentRange && endingRange === maxPercentRange) {
      range = 'Any';
    } else if(startingRange > minPercentRange && endingRange === maxPercentRange) {
      range = `${startingRange}%+`;
    } else if(startingRange === minPercentRange && endingRange < maxPercentRange) {
      range = `Up to ${endingRange}%`;
    } else {
      range =`${startingRange}% - ${endingRange}%`;
    }

    return `${misFitRate.underlieIndex} - ${range}`;
  }

  static formatLayeredMisfit(misFitRate: MisfitRate) {
    const formattedString = `${misFitRate.underlieIndex} - `;

    if (misFitRate.periodRangeMin > misFitRate.periodRangeMax) {
      return misFitRate.underlieIndex;
    }

    if (misFitRate.periodRangeMin === misFitRate.periodRangeMax) {
      return formattedString + `Year ${misFitRate.periodRangeMin}`;
    }

    if (misFitRate.periodRangeMin === 0 && misFitRate.periodRangeMax === 999) {
      return formattedString + `All Years`;
    }

    return formattedString + `Years ${misFitRate.periodRangeMin}-${misFitRate.periodRangeMax}`;
  }

  static formatMisfitUnderlier(misFitRate: MisfitRate, strategyType?: MisFitStrategy): string {
    if (strategyType === 'Allocated' || misFitRate.weight != null) {
      return this.formatAllocatedMisfit(misFitRate);
    } else if (strategyType === 'Threshold' || misFitRate.percentRangeMin != null || misFitRate.percentRangeMin != null) {
      return this.formatThresholdMisfit(misFitRate);
    } else if (strategyType === 'Layered' || misFitRate.periodRangeMin != null || misFitRate.periodRangeMax != null) {
      return this.formatLayeredMisfit(misFitRate);
    } else if (strategyType === 'Better Of') {
      console.warn('Better Of is not yet implemented');
    }

    return misFitRate.underlieIndex;
  }

  static isMisfitRate(rate: MisfitRate): boolean {
    return typeof rate === 'object'
      && null != (rate.weight
        ?? rate.periodRangeMax ?? rate.periodRangeMin
        ?? rate.percentRangeMin ?? rate.percentRangeMax);
  }
}
