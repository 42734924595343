import {CSS_COLORS} from "@common/models/css-colors";

export const GRID_LINE_COLOR_ORDER = [
  CSS_COLORS.GRAPHING_LUMA_DAINTREE_1,
  CSS_COLORS.GRAPHING_LUMA_DAINTREE_5,
  CSS_COLORS.GRAPHING_LUMA_HALF_BAKED_2,
  CSS_COLORS.GRAPHING_BLUE_GRAY_5,
  CSS_COLORS.GRAPHING_LUMA_YELLOW_GREEN_1,
  CSS_COLORS.GRAPHING_LUMA_YELLOW_GREEN_4,
  CSS_COLORS.GRAPHING_LUMA_SELECTIVE_YELLOW_2,
  CSS_COLORS.GRAPHING_LUMA_SELECTIVE_YELLOW_4,
]
