import { FormGroup } from '@angular/forms';
import { FormSnapshot } from '@common/utilities/form-utilities';

export interface AnnuityExchangeDateRange {
  earliestDate: string;
  latestDate: string;
}

export interface ExchangeFormPanel {
  header: string;
  expanded: boolean;
  key: string;
  form: FormGroup;
  previouslySaved?: boolean;
  currentChanges?: boolean;
  enabled?: boolean;
  formSnapshot?: FormSnapshot;
  formDefaultValues?: any;
  subsequentPanels: SubsequentPanel[];
  onClose?(): void;
}

export interface ExchangeRightSidePanelField {
  title: string;
  info: string;
}

export interface SubsequentPanel {
  panel: ExchangeFormPanel;
  resetOnly?: boolean;
  resetConditions?: 'NONE' | 'ANY' | 'FIELDS';
  fields?: string[];
}

export interface ExchangeRightSidePanel {
  header: string;
  fields: ExchangeRightSidePanelField[];
  key: string;
  panelKeys: string[];
  inProgress?: boolean;
  inProgressMessage?: {text: string; bold: boolean;}[];
  fieldGenerator(): (ExchangeRightSidePanelField[]);
}

export interface AnnuityDefinedAttributes {
  productAttributes: {
    meFee;
    meFeeFrequency;
    adminFee;
    adminFeeFrequency;
  };

  lbAttributes: {
    rollUpRate;
    rollUpPeriod;
    rollUpStopAge;
    type;
    rollUpBase;
    rollUpPeriodRemaining;
    stepUpFrequency;
    riderFee;
    riderFeeFrequency;
    withdrawalRateAtAge;
  };

  dbAttributes: {
    riderFee;
    feeFrequency;
    rollUpRate;
    rollUpFrequency;
    stepUpFrequency;
  };
}
export enum EXCHANGE_SUMMARIES {
  SUMMARY = 'Summary',
  ZERO = 'Minimum',
  BEAR = 'Bear',
  MODERATE = 'Moderate',
  BULL = 'Bull',
}

export const EXCHANGE_SUMMARY_DETAILS = [
  {id: EXCHANGE_SUMMARIES.SUMMARY, name: 'Summary', position: -1},
  {id: EXCHANGE_SUMMARIES.ZERO, name: '0% Return', position: 0},
  {id: EXCHANGE_SUMMARIES.BEAR, name: 'Bear', position: 1},
  {id: EXCHANGE_SUMMARIES.MODERATE, name: 'Moderate', position: 2},
  {id: EXCHANGE_SUMMARIES.BULL, name: 'Bull', position: 3}
];


export type AgeValue = {
  age: number;
  value: number;
};
export type ExchangeSummary = {
  dbPaymentEv: number;
  incomeEv: number;
  feesTotalEv: number;
  totalEv: number;
  incomeAnnualEv: number;
};

export interface ExchangeScenario {
  scenario: string;
  description: string;
  exchangeInSummary: ExchangeSummary;
  exchangeOutSummary: ExchangeSummary;
  exchangeInValues: AgeValue[];
  exchangeInIb: AgeValue[];
  exchangeInDb: AgeValue[];
  exchangeOutValues: AgeValue[];
  exchangeOutIb: AgeValue[];
  exchangeOutDb: AgeValue[];
}

export interface MappedExchangeScenario  {
  exchangeScenarios: {
    exchangeScenario: ExchangeScenario;
    exchangeDetails: any; // TODO - DEFINE
  }[];
  exchangeBenefits: any; // TODO - DEFINE
}
