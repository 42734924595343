import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "@common/services/user.service";
import { Utilities } from "@common/utilities/utilities";
import { environment } from "@environments/environment";
import { CookieService } from "ngx-cookie-service";
import { takeWhile } from "rxjs/operators";

const USER_INFO_COOKIE = "user_info";
const CLIENT_TOKEN = "client_token"; // token used for temporary access tokens used for password reset/update
const MAINTENANCE_MODE_COOKIE_NAME = "mm";

const clientNamesForSimpleLogin = ["tsaf-geneve"];
export interface LoginUserInfo {
  username: string;
  firstName: string;
  lastName: string;
  companyName: string;
  rememberMeIndicator: boolean;
}

@Component({
  selector: "app-login-page",
  templateUrl: "./login.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  alive = true;
  userInfo: LoginUserInfo;
  isMaintenanceMode: string;

  // potential query params:
  username;
  isMaintenanceModeBypass = false;
  isPasswordUpdate = false;
  verificationCode: string;
  isPasswordReset = false;

  environmentName: string;
  tempAccessToken: string;
  displayMessage = {
    message: "",
    icon: "",
    color: "",
  };

  showBanners = false;
  firstAndLastName;
  dynamicTextTop;
  dynamicTextBottom;
  loginDestination: string;
  showSimpleLogin = false;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private cookieService: CookieService
  ) {
    this.environmentName = environment.environmentName;
    if (this.environmentName?.toLocaleLowerCase() !== "prod") {
      this.showBanners = true;
    }
  }

  ngOnInit(): void {
    const queryParamName = this.route.snapshot.queryParams.name;
    this.showSimpleLogin = clientNamesForSimpleLogin.includes(queryParamName);
    this.getTextFromS3();

    if (this.route.snapshot.queryParams.loginMessage) {
      this.displayMessage = {
        message: this.route.snapshot.queryParams.loginMessage,
        icon: "clock",
        color: "#868686",
      };
    }
    if (this.route.snapshot.queryParams.r) {
      this.loginDestination = this.route.snapshot.queryParams.r;
    }

    Utilities.clearQueryParams();

    this.tempAccessToken = this.cookieService.get(CLIENT_TOKEN);
    this.cookieService.delete(
      CLIENT_TOKEN,
      "/",
      window.location.hostname.replace("app", ""),
      true,
      "None"
    );

    const userInfoCookie = this.cookieService.get(USER_INFO_COOKIE);
    this.cookieService.delete(
      USER_INFO_COOKIE,
      "/",
      window.location.hostname.replace("app", ""),
      true,
      "None"
    );

    this.isMaintenanceMode = this.cookieService.get(
      MAINTENANCE_MODE_COOKIE_NAME
    );
    this.cookieService.delete(
      MAINTENANCE_MODE_COOKIE_NAME,
      "/",
      window.location.hostname.replace("app", ""),
      true,
      "None"
    );

    if (userInfoCookie.length > 0) {
      this.userInfo = JSON.parse(userInfoCookie);
      this.firstAndLastName = `${this.userInfo.firstName} ${this.userInfo.lastName}`;
    }

    this.route.queryParams
      .pipe(takeWhile((x) => this.alive))
      .subscribe((params) => {
        if (params.username) {
          this.username = params.username;
          this.displayMessage = {
            message: "An email to recover your account has been sent.",
            icon: "check-square",
            color: "#46b11d",
          };
          this.cdr.detectChanges();
        }
        if (params.logout === "1") {
          this.userService.triggerLogoutAcrossTabs();
        }
        this.verificationCode = params.verificationCode;
        this.isPasswordReset = params.passwordReset;
        this.isPasswordUpdate = params.passwordUpdate;
        this.isMaintenanceModeBypass = params.mmb;
      });
  }

  setError(event) {
    this.displayMessage = { ...event };
  }

  getTextFromS3() {
    this.http
      .get(
        `https://cms.lumafintech.com/cms-assets/${environment.environmentName.toUpperCase()}/assets/dashboard-text.txt`,
        { responseType: "text" }
      )
      .subscribe(
        (text) => {
          const splitText = text.replace("\r", "").split("\n");
          this.dynamicTextTop = splitText[0];
          if (splitText.length > 1) {
            this.dynamicTextBottom = splitText[1];
          }
        },
        (err) => {
          this.dynamicTextBottom = "Welcome to Luma";
          this.dynamicTextTop = "Product Simplification Starts Here";
        }
      );
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
