import { Component } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '@common/models/user';
import { SpinnerService } from '@common/services/spinner.service';

@Component({
    selector: 'app-login-dialog',
    templateUrl: 'login-dialog.html'
})
export class LoginDialogComponent {
    username: string;
    password: string;

    constructor(public loginDialogRef: MatDialogRef<LoginDialogComponent>,
                private userService: UserService,
                private spinnerService: SpinnerService) {
    }

    login() {
        if (this.username && this.password) {

            this.spinnerService.showSpinner();

            this.userService.login(this.username, this.password)
              .subscribe( () => {
                    // console.log('Successfully logged in ' + user.name);
                    this.clearInput();
                    this.loginDialogRef.close();
                    this.spinnerService.hideSpinner();
                },
                (error) => {
                    // console.log('Unable to log in: ' + error);
                    this.spinnerService.hideSpinner();
                });

        } else {
            console.log('Username and password are required'); // todo add toaster warning to loginDialog
        }
    }

    private clearInput() {
        this.username = null;
        this.password = null;
    }
}


@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
    private loggedIn = false;

    constructor(private userService: UserService, public loginDialog: MatDialog) {

        setTimeout(() => {this.userService.$user.subscribe(user => this.loggedIn = !!user );}, 50);

    }

    isLoggedIn(): boolean {
        return this.loggedIn;
    }

    showLogin() {
        this.loginDialog.open(LoginDialogComponent, {
            width: '25em'
        });
    }

    logout() {
        this.userService.logout();
    }

    getUserName(): string {
        return this.userService.getName();
    }
}

