import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnderlineTabComponent } from '@common/components/underline-tabs/underline-tab/underline-tab.component';

/*
 * This component is used in conjunction with underline-tab to have a standard tab setup
 * Please review the similar comment in that component as it has important usage details
 *
 * This provides and output called tabSwitched that provides the tab that has been switched to whenever a switch occurs
 *
 * This takes an input of noLeftMargin which will get rid of the left margin on the leftmost tab and
 * dontNavigateOnTabClick which I will explain below
 *
 * In the case that a workflow is desired where one might want to stop the propagation of tab navigation then one can use the following workflow
 * first set dontNavigateOnTabClick to true. This will case the tab to emit a tabSwitched event but not actually preform the action.
 * second sub to tabSwitched on the component holding the tabs. When doing so check whether you want to preform the tab switched.
 * third if you decide you do want to preform the tab switched then use a reference of this component and call selectTab with the tab to be switched to and override as true
 * This will then switch the tab while skipping the emission of the tabSwitched event
 *
 */
@Component({
  selector: 'app-underline-tabs',
  templateUrl: './underline-tabs.component.html',
  styleUrls: ['./underline-tabs.component.scss']
})
export class UnderlineTabsComponent {
  @Output() tabSwitched: EventEmitter<UnderlineTabComponent> = new EventEmitter<UnderlineTabComponent>();
  @Input() noLeftMargin = true;
  @Input() dontNavigateOnTabClick = false; // this input can be used if you want to confirm the selection of the tab

  tabs: UnderlineTabComponent[] = [];

  selectTab(selectedTab: UnderlineTabComponent, override = false) {
    if(this.dontNavigateOnTabClick && !override) {
      this.tabSwitched.emit(selectedTab);
      return;
    }
    this.tabs.forEach((tab: UnderlineTabComponent) => {
      tab.active = false;
    });
    selectedTab.active = true;
    if(!this.dontNavigateOnTabClick) { // in the case where they are handling confirmation on their end we don't want to send a change tab event directly after changing tabs
      this.tabSwitched.emit(selectedTab);
    }
  }

  addTab(newTab: UnderlineTabComponent) {
    if (this.tabs.length === 0) {
      newTab.active = true;
    }
    this.tabs.push(newTab);
  }
}
