import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '@common/models/product';
import { ProductDetails } from '@common/models/product-details';
import { StructureTypeData } from '@common/models/structureTypeData';

@Injectable({
  providedIn: 'root'
})

export class CusipService {
  PRODUCT_API_ROOT = `${environment.hosts.api_gateway}/api/product-service`;
  PRODUCT_METRIC_API_ROOT = `${environment.hosts.api_gateway}/api/product-metric-service`;

  constructor(private http: HttpClient) { }

  getProduct(cusipIdentifier: string): Observable<Product[]> {
    return this.http.post<Product[]>(this.PRODUCT_API_ROOT + '/products/' + cusipIdentifier, null);
  }
  getProductDetails(cusipIdentifier: string): Observable<ProductDetails> {
    return this.http.get<ProductDetails>(this.PRODUCT_API_ROOT + '/product/' + cusipIdentifier + '/details');
  }
  getStructureTypeData(cusipIdentifier: string): Observable<StructureTypeData> {
    return this.http.get<StructureTypeData>(this.PRODUCT_API_ROOT + '/product/structureTypeDisplayData?cusip=' + cusipIdentifier);
  }
  getCanShowCalcs(cusipIdentifier: string): Observable<boolean> {
    return this.http.get<boolean>(this.PRODUCT_API_ROOT + '/product/allowsCalcs?cusip=' + cusipIdentifier);
  }
  getProductPerformancePayload(underliers: string[], strikeDate: string, maturityDate: string): Observable<any> {
    return this.http.post(this.PRODUCT_METRIC_API_ROOT + '/underliers/productPerformancePayloads?strikeDate=' + strikeDate + '&maturityDate=' + maturityDate, underliers);
  }
  getUnderlierPerformancePayload(underliers: string[]): Observable<any> {
    return this.http.post(this.PRODUCT_METRIC_API_ROOT + '/underliers/performancePayloads', underliers);
  }
  getHockeystickChartDetails(cusipIdentifier: string): Observable<any> {
    return this.http.get<StructureTypeData>(this.PRODUCT_API_ROOT + '/product/hockeystickChartDetails?cusip=' + cusipIdentifier);
  }
  getMarkToMarketData(cusipIdentifier: string): Observable<Array<Array<number>>> {
    return this.http.get<Array<Array<number>>>(this.PRODUCT_API_ROOT + '/products/' + cusipIdentifier + '/mark-to-market/graph');
  }
  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.PRODUCT_API_ROOT + '/portfolios/events');
  }
}
