import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@common/services/user.service';
import { DashboardMessage } from '@common/models/dashboard-message';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: 'disclaimer-dialog.html'
})
export class DisclaimerDialogComponent {
  hideMessage: boolean;
  dashboardMessage: DashboardMessage;

  constructor(
    public disclaimerDialogComponenRef: MatDialogRef<DisclaimerDialogComponent>,
    private userService: UserService,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data) {
    this.hideMessage = false;
    this.dashboardMessage = data.dashboardMessage;
  }

  ok() {
    if (this.hideMessage && this.dashboardMessage) {
      this.userService.addPreference(this.dashboardMessage.messageKey, true);
    }
    this.disclaimerDialogComponenRef.close();
  }
}
