import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@common/services/user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';

@Injectable({
    providedIn: 'root'
})
export class AnnuityRouteGuardService implements CanActivate {

    constructor(private userService: UserService,
                public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return of(this.userService.getUserPermissions()).pipe(map((permissions: string[]) => {
            if(permissions.includes(WidgetPermissions.MenuMarketplaceAnnuity)) {
                return true;
            } else {
                this.router.navigateByUrl('/dashboard');
                return false;
            }
        }));
    }
}
