import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterOption, RangeFilterType, ValueFormat } from '../../../../filter-lib/base-filter.model';
import { RangeFilter } from '../../../../filter-lib/range-filter';
import { FilterTypesEnum } from '../../../../filter-lib/filter-types.enum';
import { LabelType } from '@angular-slider/ngx-slider';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FilterUtilities } from '@common/components/filters/filter.utilities';


export interface SliderFilterValue {
  floor: number;
  ceil: number;
}
@Component({
  selector: 'app-slider-filter',
  templateUrl: './slider-filter.component.html',
  styleUrls: ['./slider-filter.component.scss']
})
export class SliderFilterComponent implements AfterViewInit {
  step = 1;
  @Input() filter: RangeFilter<any>;
  @Output() filterValueChange = new EventEmitter();

  constructor(private currencyPipe: CurrencyPipe,
              private decimalPipe: DecimalPipe) { }

  addPercentFormat = false;
  ngxSliderOptions;
  filterValue: RangeFilterType;

  ngAfterViewInit(): void {
    this.filterValue = this.singleFilter?.searchValue;
    if(!this.filterValue) { return; }
    this.addPercentFormat = this.filter.baseFilterModel.filterType === FilterTypesEnum.PERCENT_RANGE;
    if (this.filterValue.step == null) {
      FilterUtilities.completeFilterConfigExisting([this.filter]);
    }
    this.ngxSliderOptions = {
      floor: this.filterValue.min,
      ceil: this.filterValue.max,
      step: this.filterValue.step ?? this.step
    };

    this.ngxSliderOptions.translate = (value: number, label: LabelType): string => {
      switch(this.filter?.baseFilterModel?.formatting) {
        case ValueFormat.CURRENCY:
          return this.currencyPipe.transform(value, 'USD', 'symbol','1.0-2');
          break;
        case ValueFormat.PERCENT:
          return this.decimalPipe.transform(value, '1.0-3') + ' %';
          break;
        case ValueFormat.NUMBER: // fallthrough
        default:
          return this.decimalPipe.transform(value, '1.0-3');
          break;
      }
    };
  }

  sliderValueChange() {
    this.filter?.rangeUpdated(this.singleFilter);
  }

  private get singleFilter(): FilterOption<RangeFilterType | any > {
    return this.filter?.baseFilterModel?.filters?.[0];
  }
}
