import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Utilities } from '@common/utilities/utilities';

@Component({
  selector: 'app-multi-chip-filter',
  templateUrl: './multi-chip-filter.component.html',
  styleUrls: ['./multi-chip-filter.component.scss']
})
export class MultiChipFilterComponent {
  constructor(private cdr: ChangeDetectorRef) {
  }
  activeItems: any[] = [];
  @Input() items: any[];
  @Input() label;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _filterValue = [];
  get filterValue() { return this._filterValue; }
  @Input() set filterValue(value) {
    this.activeItems = value || [];
    if(value?.length === this.items?.length) {
      this._filterValue = [];
    } else {
      this._filterValue = value || [];
    }
  }
  @Output() filterValueChange = new EventEmitter();

  onChange(item) {
    if(this.filterValue.length === 0) {
      this.filterValue = [item];
    } else if(this.filterValue.includes(item)) {
      Utilities.removeFromArray(this.filterValue, item);
    } else {
      this.filterValue.push(item);
    }
    this.activeItems = [...this.filterValue];
    if(this.filterValue.length === 0) {
      this.filterValueChange.emit(null);
      // this.filterValueChange.emit(this.items);
    } else {
      this.filterValueChange.emit(this.filterValue);
      this.cdr.detectChanges();
    }

  }
}
