import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { DashboardService, IssuerVolume } from '../dashboard.service';
import { ChartUtilService } from '../chart-util.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-volume-by-issuer',
  templateUrl: '../mat-card-bar-chart.html',
  styleUrls: ['../mat-card-bar-chart.scss']
})
export class VolumeByIssuerComponent implements OnInit {
  title = 'Volume by Issuer';
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          fontSize: 10,
          autoSkip: false
        },
        title: {
          display: true,
          text: 'Issuer'
        }
      },
      yAxes: {
        grid: {
          display: true
        },
        ticks: {
          callback: ChartUtilService.millionsLabel,
          beginAtZero: true
        },
        title: {
          display: true,
          text: 'Quantity'
        }
      }
    },
    tooltips: {
      callbacks: {
        title: items => {
          return items[0].xLabel.join(' ');
        },
        label: item => {
          return formatNumber(item.yLabel as number, this.locale);
        }
      }
    }
  };

  labels: string[][];

  chartData = [
    {
      label: 'Issuer',
      data: null,
      backgroundColor: 'rgba(26, 117, 224, 1)',
    }
  ];

  apiError: any;

  constructor(
    private service: DashboardService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit() {
    this.service.volumeIssuerDetails().subscribe({
      next: (data: IssuerVolume[]) => {
        if (data != null) {
          this.labels = data.map(x => ChartUtilService.convertLabelToArray(x));
          this.chartData[0].data = data.map(x => x.quantity);
        } else {
          this.labels = [];
          this.chartData[0].data = [];
        }
        this.apiError = null;
      },
      error: err => {
        this.chartData[0].data = null;
        this.apiError = err;
      }
    });
  }

  showSpinner() {
    return this.chartData[0].data == null && this.apiError == null;
  }

  hasData() {
    return this.chartData[0].data != null && this.chartData[0].data.length > 0;
  }
}
