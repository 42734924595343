import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnnuitySessionIdService {
  private sessionId: string = this.generateSessionId();

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.resetSessionId();
    });
  }

  private generateSessionId(): string {
    return uuidv4();
  }

  public getSessionId(): string {
    return this.sessionId;
  }

  resetSessionId(): void {
    this.sessionId = this.generateSessionId();
  }
}
