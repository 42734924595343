export class OrderFile {
    documentCompanyKey: string;
    documentFileName: string;
    documentKey: string;
    documentName: string;
    documentType: string;
    documentUploadTimestamp: any;
    documentUploadedBy: string;
    documentUrl: string;
    documentWrapperType: string;

    constructor(orderFile: OrderFile) {
        this.documentCompanyKey = orderFile.documentCompanyKey;
        this.documentKey = orderFile.documentKey;
        this.documentName = orderFile.documentName;
        this.documentFileName = orderFile.documentFileName;
        this.documentType = orderFile.documentType;
        this.documentUploadTimestamp = orderFile.documentUploadTimestamp;
        this.documentUploadedBy = orderFile.documentUploadedBy;
        this.documentUrl = orderFile.documentUrl;
        this.documentWrapperType = orderFile.documentWrapperType;
    }
}
