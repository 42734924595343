import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@common/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {
  constructor(private userService: UserService,
              private router: Router) {
  }

  /**
   * <p>This guard is in place to prevent a user sitting on the logout component if navigated to directly.</p>
   * <p>Will reject route and user will be logged out.</p>
   * <p>Assumption is that UserService.logout handles logging out the user AND navigating to LogoutComponent.</p>
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Get current navigation details - time sensitive
    const details = this.router.getCurrentNavigation();
    // Checking to see if there is any previous navigation
    const previousNavigation = details.previousNavigation != null;
    // Being ID 1 means that this is the first route for the current initialized router
    const firstNavigationId = details.id === 1;

    if (firstNavigationId && !previousNavigation) {
      this.userService.logout();
      return false;
    }

    return true;
  }
}
