import { ProductInfo } from '@common/components/product-details-modal/models/product-info';
import { LONG_DASH, Utilities } from '@common/utilities/utilities';
import { MarketplaceUtilities } from '@common/utilities/marketplace-utilities';

class ProductDetailConfig {
  fieldName: string;
  fieldValue: (ProductInfo) => string;
}

class ProductDetail {
  fieldName: string;
  fieldValue: string;
}

const COUPON_RATE_PER_ANNUM: ProductDetailConfig = {fieldName: 'Annualized Coupon', fieldValue: (productInfo: ProductInfo) => productInfo.annualizedCoupon};
const COUPON_TYPE_FREQ: ProductDetailConfig = {
  fieldName: 'Coupon Type / Frequency',
  fieldValue: (productInfo: ProductInfo) => Utilities.aSlashBWithOrLongDash(productInfo.couponType, productInfo.paymentFrequency)
};
const COUPON_TYPE_FREQ_BARRIER: ProductDetailConfig = {fieldName: 'Coupon Type / Frequency / Barrier', fieldValue: (productInfo: ProductInfo) => {
  return Utilities.aSlashBWithOrLongDash(productInfo.couponType, productInfo.paymentFrequency) + ' / ' + Utilities.orLongDash(productInfo.paymentBarrier);
}};
const TENOR: ProductDetailConfig =  {
  fieldName: 'Tenor',
  fieldValue: (productInfo: ProductInfo) => Utilities.orLongDash(productInfo.tenor) + ' ' + productInfo.tenorUnit
};
const CDS_NAME: ProductDetailConfig = {
  fieldName: 'Reference Entity',
  fieldValue: (productInfo: ProductInfo) => productInfo.cdsName
};
const TRADE_SETTLEMENT_DATE: ProductDetailConfig = {fieldName: 'Trade Date / Settlement Date',
  fieldValue: (productInfo: ProductInfo) => Utilities.aSlashBWithOrLongDash(Utilities.getFormattedDateStr(productInfo.tradeDate), Utilities.getFormattedDateStr(productInfo.settlementDate))};
const FINAL_OBS_MATURITY_DATE: ProductDetailConfig = {fieldName: 'Final Obs. / Maturity Date',
  fieldValue: (productInfo: ProductInfo) => Utilities.aSlashBWithOrLongDash(Utilities.getFormattedDateStr(productInfo.finalValuationDate), Utilities.getFormattedDateStr(productInfo.maturityDate))};
const CURRENCY: ProductDetailConfig = {fieldName: 'Currency', fieldValue: (productInfo: ProductInfo) => productInfo.currency};
const CUSIP_ISIN: ProductDetailConfig = {fieldName: 'CUSIP / ISIN', fieldValue: (productInfo: ProductInfo) => productInfo.productIdentifier};
const MAXIMUM_MINIMUM_RETURN: ProductDetailConfig = {fieldName: 'Max Return / Min Return', fieldValue: (productInfo: ProductInfo) => {
  if(productInfo.maximumReturn) {
    return Utilities.aSlashBWithOrLongDash(productInfo.maximumReturn, productInfo.minimumReturn);
  }
  return 'Uncapped';
}};
const PARTICIPATION_RATE: ProductDetailConfig = {fieldName: 'Participation Rate', fieldValue: (productInfo: ProductInfo) => productInfo.participationRate};
const DIGITAL_BARRIER: ProductDetailConfig = {fieldName: 'Digital Barrier Level', fieldValue: (productInfo: ProductInfo) => productInfo.digitalBarrier};
const DIGITAL_COUPON_RATE: ProductDetailConfig = {fieldName: 'Digital Coupon Rate', fieldValue: (productInfo: ProductInfo) => productInfo.digitalReturn};
const BUFFER_LEVEL: ProductDetailConfig = {fieldName: 'Buffer Level', fieldValue: (productInfo: ProductInfo) => productInfo.principalBufferLevel};
const BARRIER_LEVEL: ProductDetailConfig = {fieldName: 'Barrier Level', fieldValue: (productInfo: ProductInfo) => productInfo.principalBarrierLevel};
const PROTECTION_LEVEL: ProductDetailConfig = {fieldName: 'Protection Level', fieldValue: (productInfo: ProductInfo) => {
  switch (productInfo.protectionType?.toUpperCase()) {
    case 'FULL':
      return 'Fully Protected';
    case 'PARTIAL':
      return 'Partial Protection';
    case 'NO PRINCIPAL PROTECTION':
      return 'No Protection';
    default:
      return null;
  }
}};

export function getProductDetailConfigsOrder(productInfo: ProductInfo): ProductDetail[] {
  const detailsArray: ProductDetailConfig[] = [];

  if(productInfo.returnType === 'Growth') {
    detailsArray.push(barrierBufferOrProtectionLevel(productInfo), PARTICIPATION_RATE, MAXIMUM_MINIMUM_RETURN);
  } else if(productInfo.returnType === 'Income') {
    detailsArray.push(COUPON_RATE_PER_ANNUM, getCorrectCouponType(productInfo), barrierBufferOrProtectionLevel(productInfo));
  } else if(productInfo.returnType === 'Growth and Income') {
    detailsArray.push(
      COUPON_RATE_PER_ANNUM,
      getCorrectCouponType(productInfo),
      barrierBufferOrProtectionLevel(productInfo),
      PARTICIPATION_RATE,
      MAXIMUM_MINIMUM_RETURN,
      DIGITAL_COUPON_RATE,
      DIGITAL_BARRIER
    );
  } else if(productInfo.returnType === 'Digital') {
    detailsArray.push(barrierBufferOrProtectionLevel(productInfo), DIGITAL_COUPON_RATE, DIGITAL_BARRIER, MAXIMUM_MINIMUM_RETURN);
  }

  const detailsInAll = [
    callTypeLogic(productInfo),
    TENOR,
    TRADE_SETTLEMENT_DATE,
    FINAL_OBS_MATURITY_DATE,
    CURRENCY,
    CUSIP_ISIN,
    CDS_NAME
  ];

  detailsArray.push(...detailsInAll);

  return detailsArray.map((detail: ProductDetailConfig) => initDetail(productInfo, detail)).filter((detail: ProductDetail) => detail.fieldValue != null);
}

function initDetail(productInfo: ProductInfo, detail: ProductDetailConfig): ProductDetail {
  return {
    fieldName: detail.fieldName,
    fieldValue: detail.fieldValue(productInfo)
  };
}

function barrierBufferOrProtectionLevel(productInfo: ProductInfo): ProductDetailConfig {
  if(['Barrier', 'Geared Barrier'].includes(productInfo.protectionType)) {
    return BARRIER_LEVEL;
  } else if(['Buffer', 'Geared Buffer'].includes(productInfo.protectionType)) {
    return BUFFER_LEVEL;
  }
  return PROTECTION_LEVEL;
}

function getCorrectCouponType(productInfo: ProductInfo): ProductDetailConfig {
  if(productInfo.couponType === 'Contingent') {
    return COUPON_TYPE_FREQ_BARRIER;
  }

  return COUPON_TYPE_FREQ;
}

function callTypeLogic(productInfo: ProductInfo): ProductDetailConfig {
  if(!productInfo.callType) {
    return {
      fieldName: 'Call Type',
      fieldValue: () => 'Not Callable'
    };
  } else if(productInfo.noCallPeriod) {
    return {
      fieldName: 'Call Type / No Call Period / Frequency',
      fieldValue: (productInfoObj) => {
        const noCall = MarketplaceUtilities.getNoCallPeriod(productInfoObj);
        return productInfoObj.callType + ' / ' +
              (noCall || LONG_DASH)
              + ' / ' + Utilities.orLongDash(productInfoObj.callObservationFrequency);
      }
    };
  } else {
    return {
      fieldName: 'Call Type / Frequency',
      fieldValue: (productInfoObj) => {
        return productInfoObj.callType + ' / ' + Utilities.orLongDash(productInfoObj.callObservationFrequency);
      }
    };
  }
}
