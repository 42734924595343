import { Component, Input } from '@angular/core';
import { FaProps } from '@fortawesome/angular-fontawesome';

export interface NotificationInfoModel {
  message: string;
  icon: FaProps;
  color: string;
}

const ERROR_MESSAGE_SHELL: NotificationInfoModel = {
  message: '',
  icon: 'exclamation' as FaProps,
  color: '#af2a2a'
};

const SUCCESS_MESSAGE_SHELL: NotificationInfoModel = {
  message: '',
  icon: 'check-square' as FaProps,
  color: '#46b11d'
};

const INFO_MESSAGE_SHELL: NotificationInfoModel = {
  message: '',
  icon: 'info' as FaProps,
  color: '#868686'
};

const EMPTY_NOTIFICATION: NotificationInfoModel = {
  message: '',
  icon: '' as FaProps,
  color: ''
};

@Component({
  selector: 'app-mini-notification',
  templateUrl: './mini-notification.component.html',
  styleUrls: ['./mini-notification.component.scss']
})
export class MiniNotificationComponent {

  @Input() message;
  @Input() icon;
  @Input() color;

  static getErrorMessage(message) {
    const result = {...ERROR_MESSAGE_SHELL};
    result.message = message;
    return result;
  }

  static getSuccessMessage(message) {
    const result = {...SUCCESS_MESSAGE_SHELL};
    result.message = message;
    return result;
  }

  static getInfoMessage(message) {
    const result = {...INFO_MESSAGE_SHELL};
    result.message = message;
    return result;
  }

  static getEmptyNotification() {
    return {...EMPTY_NOTIFICATION};
  }
}
