import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent, UserMenuComponent } from './user-menu.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@common/material.module';

@NgModule({
    declarations: [UserMenuComponent, LoginDialogComponent],
    exports: [
        UserMenuComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        FontAwesomeModule
    ]
})
export class UserMenuModule {
}
