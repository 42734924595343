import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Utilities } from '@common/utilities/utilities';

@Component({
  selector: 'app-global-bread-crumb',
  templateUrl: './global-bread-crumb.component.html'
})

export class GlobalBreadCrumbComponent {

  public baseCrumb = 'Dashboard';
  public currentCrumb = '';
  public crumbPath: any[] = [];
  @Input() public altText?: string;
  @Input() public imageUrl?: string;
  @Input() public valueToAppend?: string;
  @Input() public previousCrumbRoute?: string;
  @Input() public previousCrumb?: string;
  @Input() public crumbLimit?: number;
  @Input() public carat?: string;

  constructor(public router: Router) {
    this.buildBreadCrumbs(router);
  }

  buildBreadCrumbs(router, thatCrumbLimit?) {
    router.events.subscribe(() => {
      const key = (router.url.split('/')[1].split('-'));
      let crumb = '';
      this.crumbLimit = thatCrumbLimit;

      for (let i = 0; i < key.length; i++) {
        crumb += key[i].charAt(0).toUpperCase().concat(key[i].slice(1)).concat(' ');
        if (i === (key.length - 1)) {
          crumb = crumb.substring(0, crumb.length - 1);
        }
      }

      if (!Utilities.foundInArray(this.crumbPath, crumb) && crumb !== 'Dashboard') {
        this.crumbPath.push(crumb);
      }
      this.currentCrumb = this.crumbPath[this.crumbPath.length - 1];
    });
  }

}
