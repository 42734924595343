import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

export abstract class HttpServiceUtils {

  static Options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  static handleError(res: HttpErrorResponse | any) {
    return throwError(res || 'Server error');
  }
}
