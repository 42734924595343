import { BaseFilter } from './base-filter';
import { FilterGroup, GenericFilterTypes } from './base-filter.model';
import { FilterMethods } from './filter-methods.utilities';


export enum FilterNumberIs {
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  BETWEEN = 'between',
}

export interface NumberInputFilterModel<U extends GenericFilterTypes = number> extends FilterGroup<U> {
  // Overrides
  searchType: FilterNumberIs
}

export class NumberInputFilter<U extends GenericFilterTypes = number> extends BaseFilter<U> {
  constructor(protected _baseFilterModel: NumberInputFilterModel<U>) {
    super(_baseFilterModel);
  }

  get filterValue(): number {
    return this._baseFilterModel.filterValues?.[0] as number
  }

  applyFilter<T>(dataToBeFiltered: T[]): T[] {
    return FilterMethods.applyNumberInputFilter(this.filterValue, dataToBeFiltered, this.baseFilterModel);
  }

  doesFilterPass(dataToBeFiltered: any[]): boolean {
    return FilterMethods.doesNumberInputFilterApply(this.filterValue, dataToBeFiltered, this.baseFilterModel);
  }

  valueUpdated(updatedValue: U | null) {
    this._baseFilterModel.filterValues = updatedValue ? [updatedValue] : [];
    this.filterValuesChanged(true);
  }

  clearFilters(close = false) {
    if (close) {
      this._baseFilterModel.expanded = false;
    }
    this._baseFilterModel.filterValues = null;
    this.filterApplied = false;
    this.filterValuesChanged(true);
  }

  // this function is only relevant for structured products filters currently:
  applyFilterView(filters: FilterGroup<any>) {
    this._baseFilterModel.filterValues = filters.filterValues;
  }
}
