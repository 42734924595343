import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { NavService } from '@common/services/nav.service';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  environmentName = environment.environmentName;
  showEnvironmentBanner = environment.showEnvBanner;

  @Input() whiteLabelInfo: WhiteLabelInfo;
  defaultHeader;

  constructor(public navService: NavService) {
    this.navService.defaultHeaderSub.subscribe(res => {
      this.defaultHeader = res;
    });
  }

  goToContact() {
    const contactUri = this.whiteLabelInfo?.contactEmail ? 'mailto:' + this.whiteLabelInfo?.contactEmail : environment.hosts.navian + '/contactus/';
    window.open(contactUri, '_blank');
  }
}
