export const disableSignatureStatuses: string[] = [
  'Terminated',
  'Canceled',
  'ReviewQueue',
  'ReviewQueuePending',
  'ReviewQueueInProgress',
  'PendingAgentReview',
  'ReviewQueueRejected',
  'ReviewQueueFinished',
  'ReviewQueueApproved',
  'ReviewQueueMoreInfo',
  'Submitted',
  'PendingTransmission',
  'Complete',
  'InCarrierReview',
  'CarrierReviewComplete',
  'Purged'
];

export const enableUnlockButtonStatuses: string[] = [
  'SignatureFinished',
  'Signature',
  'SignaturePending',
  'SignatureInProgress',
  'SignatureAgentPending',
  'SignatureAgentInProcess',
  'ManualReview',
  'ManualReviewPending',
  'ManualReviewInProgress',
  'ManualReviewRejected',
  'ManualReviewFinished',
  'ManualReviewApproved',
  'ManualReviewMoreInfo',
  'Terminated',
  'Canceled',
  'ClientSignaturesComplete',
  'ClientSignatureComplete',
  'ReviewQueue',
  'ReviewQueuePending',
  'ReviewQueueInProgress',
  'PendingAgentReview',
  'ReviewQueueRejected',
  'ReviewQueueFinished',
  'ReviewQueueApproved',
  'ReviewQueueMoreInfo',
];
