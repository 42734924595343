import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';

export function shouldAddToken(request) {
  return (
    !request.headers.has('authorization') &&
    !request.url.includes('oauth/token') &&
    (
      request.url.includes(environment.hosts.api_gateway) ||
      request.url.includes('api.lumafintech.com') ||
      request.url.includes(environment.hosts.pdf_gateway)
      // for testing locally running node server:
      // || request.url.includes(3000)
    )
  );
}


export function addToken(req: HttpRequest<any>, token: string) {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`
    },
    withCredentials: true
  });
}

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private userService: UserService) { }

  handleUnauthorized(req: HttpRequest<any>, nxt: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);
      return this.userService.refreshAccessToken().pipe(
        catchError(err => {
          this.isRefreshing = false;
          return of(null);
        }),
        filter(token => token != null),
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(token.access_token);
          return nxt.handle(addToken(req, token.access_token));
        }));
    } else {
      return this.accessTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(refreshedToken => {
          return nxt.handle(addToken(req, refreshedToken));
        }));
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(shouldAddToken(request)) {
      request = addToken(request, this.userService.getToken());
    }
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (
        error.status === 401 &&
        this.userService.getRefreshToken() &&
        !(request.url.includes('oauth/token')) &&
        !(request.url.includes('rmt/logout')) &&
        !(request.url.includes('tokens/current')) &&
        (request.url.includes(environment.hosts.api_gateway) || request.url.includes(environment.hosts.pdf_gateway))
      ) {
        return this.handleUnauthorized(request, next);
      } else { return throwError(error.error); }
    }));
  }
}
