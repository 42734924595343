import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Class to represent confirm dialog model.
export class ConfirmDialogModel {

  constructor(
    public title: string,
    public message: string,
    public type?: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    public onConfirmCallback?: Function,
    public caller?: any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    public onCompletedAndCloseCallback?: Function,
    public confirmButtonText?: string,
    public cancelButtonText?: string
  ) {}
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  static DIALOG_CONFIG: any = {
    width: '400px',
    disableClose: true,
    panelClass: 'confirmation-dialog'
  };

  title: string;
  messages: Array<string> = [];
  type: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onConfirmCallback: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onConfirmAltActionCallback: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCompletedAndCloseCallback: Function;
  caller: any;
  loading = false;
  completed = false;
  disableConfirm = false;
  altAction: string;
  confirmAction = 'Confirm';
  cancelAction = 'Cancel';
  response: any;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.title = data.title;
    this.messages = [data.message];
    if (data.type) {
      this.type = data.type;
      if (this.type === 'info') { this.completed = true; }
    }
    if (data.onConfirmCallback) { this.onConfirmCallback = data.onConfirmCallback; }
    this.onCompletedAndCloseCallback = data.onCompletedAndCloseCallback;

    if (data.caller) { this.caller = data.caller; }

    if (data.confirmButtonText) {this.confirmAction = data.confirmButtonText;}
    if (data.cancelButtonText) {this.cancelAction = data.cancelButtonText;}

  }


  set message(message: string) {
    this.messages = [message];
  }

  onConfirm(): void {
    this.loading = true;
    if (this.onConfirmCallback) {
      this.onConfirmCallback();
    } else {
      this.dialogRef.close(true);
    }
  }

  onDismiss(): void {

    if (this.onCompletedAndCloseCallback && this.completed) {
      this.onCompletedAndCloseCallback();
    }

    this.dialogRef.close(false);
  }

  showInfoMessage(message) {
    this.type = 'success';
    this.showMessage(message);
  }

  showErrorMessage(message) {
    this.type = 'error';
    this.showMessage(message);
  }

  generalOnError(callback?) {
    const onError = (resp) => {
      const errors = [];
      errors.push(resp.message);
      if (resp.errors) {
        Object.keys(resp.errors).map( k => errors.push(resp.errors[k]));
      }
      this.showErrorMessage(errors);
    };
    if(callback) {
      callback(this);
    }
    return onError;
  }

  generalOnSuccess(callback) {
    const onSuccess = (res) => {
      if (res.code === 'failure') {
        this.showErrorMessage([res.message]);
      } else {
        this.showInfoMessage([res.message]);
        this.response = res;
        callback(this);
      }
    };
    return onSuccess;
  }

  private showMessage(messages) {
    this.completed = true;
    this.messages = messages;
  }
}
