import { formatCurrency, getCurrencySymbol } from '@angular/common';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { NotificationSnackbarComponent } from '@common/components/snackbar-components/notification-snackbar/notification-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationInfoModel } from '@common/components/mini-notifcation/mini-notification.component';
import { fromEvent, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const LESS_THAN_ONE_MONTH = '< 1 Month';
export const LONG_DASH = '—';
const DATE_FORMAT_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'} as const;


export class Utilities {

  static getExportDisclaimer() {
    return 'DISCLAIMER: The information contained within this exported document is intended only for the individual that initiated the export, ' +
      'and is not to be forwarded or shared with other parties.';
  }

  static currencyFormatter(params) {
    if (params === null) {
      return;
    }
    if (params.value) {
      return '$' + Utilities.addCommas(params);
    }
  }

  static stringifyObjectProperties(obj) {
    let returnString = '';
    for(const prop in obj) {
      if(obj.hasOwnProperty(prop) && obj[prop] != null) {
        returnString += obj[prop]?.toString();
      }
    }
    return returnString;
  }

  static timeToMaturityCalc(maturityDateValue: string, productStatusValue: string) {
    if (maturityDateValue == null) {
      return;
    }

    const differenceInMonths = moment(maturityDateValue.replace(/\-/g, '/'), 'MM/DD/YYYY').diff(moment(), 'months');

    if (differenceInMonths >= 1) {
      return Math.round(differenceInMonths);
    } else if (differenceInMonths < 0) {
      return productStatusValue;
    } else {
      return LESS_THAN_ONE_MONTH;
    }
  }

  static addCommas(params) {
    if (params === null) {
      return;
    }
    if (params.value) {
      if (typeof params.value === 'string') {
        return params.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      } else {
        return params.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    }
    return;
  }

  static addSpaceAfterCommas(stringWithCommas) {
    if (stringWithCommas && stringWithCommas.length > 0) {
      return stringWithCommas.replace(/,/g, ', ');
    }
    return;
  }

  static agComparatorController(comparatorToCall) {
    return this[comparatorToCall];
  }

  static agAggregateFunctionAlpha(params) {
    if (params.values.length > 0) {
      return params.values[0];
    }
    return;
  }

  static allEqual(params) {
    const values = params.values;
    if (values.every(v => v === values[0])) {
      return values[0];
    } else {
      return null;
    }
  }

  static cleanVariableKey(keyToClean: string) {
    return keyToClean.match(/^[a-z]+|[A-Z][a-z]*/g).map((char) => {
      return char[0].toUpperCase() + char.substr(1).toLowerCase();
    }).join(' ');
  }

  static cleanVariableKeys(keysToClean: Array<string>) {
    keysToClean.forEach((aKey, idx) => {
      keysToClean[idx] = this.cleanVariableKey(aKey);
    });

    return keysToClean;
  }

  static dateComparator(date1, date2) {
    const date1Number = Utilities.monthToComparableNumber(date1);
    const date2Number = Utilities.monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }

  static dayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
  }

  static getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static foundInArray(arr: any[], lookFor: string | number | boolean | {}): boolean {
    for (const someItem of arr) {
      if (someItem === lookFor) {
        return true;
      }
    }
    return false;
  }

  static removeFromArray(arr: any[], value: any) {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  static monthOfYear(date) {
    const monthOfYear = new Date(date).getMonth();
    return isNaN(monthOfYear) ? null : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][monthOfYear];
  }

  static daysUntilComparator(val1, val2) {
    if (val1 === null && val2 === null) {
      return 0;
    }
    val1 = val1 === 'Date in the past' ? 0 : val1;
    val2 = val2 === 'Date in the past' ? 0 : val2;
    if (val1 === null) {
      return -1;
    }
    if (val2 === null) {
      return 1;
    }
    return val1 - val2;
  }

  static delayedArray(array, delegate, delay) {
    array.forEach((el, i) => {
      setTimeout(() => {
        delegate(array[i]);
      }, i * delay);
    });
  }

  static insertUrlParam(key, value) {
    if (history.pushState) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + searchParams.toString();
      window.history.pushState({path: newUrl}, '', newUrl);
    }
  }

  static removeUrlParameter(paramKey) {
    const url = window.location.href;
    const r = new URL(url);
    r.searchParams.delete(paramKey);
    const newUrl = r.href;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }


  static monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }
    const yearNumber = date.substring(6, 10);
    const monthNumber = date.substring(0, 2);
    const dayNumber = date.substring(3, 5);
    return yearNumber * 10000 + monthNumber * 100 + dayNumber;
  }

  static displayCusipOrIsin(product: string) {
    if (product.length === 9) {
      return 'CUSIP';
    }
    if (product.length === 12) {
      return 'ISIN';
    }
    return 'CUSIP/ISIN';
  }

  static productNotFound(product: string) {
    return 'Error Retrieving '.concat('Product', ' - ', product);
  }

  static getISODateWithoutTime(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  static getISODateUSA() {
    return this.formatISODateUSA(new Date());
  }

  static getDateDashed(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  static getCurrent12HourFormatTime() {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  static formatISODateUSA(date: Date, options?: Intl.DateTimeFormatOptions): string {
    return new Date(date.toISOString()).toLocaleDateString('en-US', options);
  }

  static formatPercentageFromDecimal(num: number, digits = 2) {
    if(num == null) { return; }
    return (num * 100).toFixed(digits).concat('%');
  }

  static formatPercentageFromDecimalYieldRates(num: number, digits = 2) {
    if(num == null || num === 0) { return null; }
    return (num * 100).toFixed(digits).concat('%');
  }

  static formatDecimalFromPercentage(num: number | string) {
    if (typeof num === 'string') {
      num = Number(this.stripCurrencyFormatting(num));
    }
    return (num / 100);
  }

  static formatCurrency(amount, digits: number | string = 2, locale = 'en-us', currencyCode = 'USD') {
    const digitsInfo = `1.${digits}-${digits}`;
    const currencySymbol = getCurrencySymbol(currencyCode, 'wide');
    return formatCurrency(amount, locale, currencySymbol, currencyCode, digitsInfo);
  }

  static stripCurrencyFormatting(formattedCurrency: string) {
    return formattedCurrency?.replace(/[^0-9.]/g, '') || '';
  }

  static stripAndFormatCurrency(amount, digits: number | string = 2) {
    const strippedAmount = this.stripCurrencyFormatting(amount);
    return this.formatCurrency(Number(strippedAmount), digits);
  }

  static removeLeadingZeros(value) {
    return value.replace(/^\b0+/g, '');
  }

  static setCookie(cname, cvalue, exdays, secure = false) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const valueStr = `${cname}=${cvalue};`;
    const expireStr = `expires=${d.toUTCString()};`;
    const secureStr = secure ? 'secure;sameSite=strict;' : '';
    document.cookie = `${valueStr}${expireStr}${secureStr}path=/`;
  }

  static deleteCookie(cname) {
    this.setCookie(cname, '', -1);
  }

  static reflowHighChartOnSideNavToggle(highChartRef) {
    setTimeout(() => {
      highChartRef.reflow();
    }, 350);
  }

  static capitalizeFirstLetter(str: string) {
    return str ? str[0].toUpperCase() + str.substr(1) : '';
  }

  static lowercaseFirstLetter(str: string) {
    return str ? str[0].toLowerCase() + str.substr(1) : '';
  }

  static formatHolidayField(holidayField) {
    return holidayField?.includes('_') ?
      holidayField.split('_').map((regionWord) => {
        return regionWord.charAt(0).toUpperCase() + regionWord.slice(1).toLowerCase();
      }).join(' ') : holidayField;
  }

  static formatHolidayFilterField(holidayField) {
    return holidayField.replace('_', ' ');
  }

  static formControlRemoveErrorKeepingOldOnes(formControl: FormControl, errorCode: string) {
    if (!formControl.hasError(errorCode)) { // if the control doesn't have the error already we don't need to add it
      return;
    }

    const errors: any = formControl.errors || {};
    delete errors[errorCode];

    formControl.setErrors(Object.keys(errors).length === 0 ? null : errors);
  }

  static formControlAddErrorKeepingOldOnes(formControl: FormControl, errorCode: string) {
    if (formControl.hasError(errorCode)) { // if the control has the error we don't need to add it
      return;
    }

    const errors: any = formControl.errors || {};
    errors[errorCode] = true;

    formControl.setErrors(errors);
  }

  static stripTheYearFromDateString(dateString) {
    return dateString && dateString.startsWith(new Date().getFullYear())
      ? dateString.substring(5) : dateString; // 2021-
  }

  static objectArrayStringSortComparator(a, b, propToSortBy) {
    return a[propToSortBy].toLowerCase().localeCompare(b[propToSortBy].toLowerCase());
  }

  static cleanUrl(url: string) {
    return url.split('?')[0];
  }

  static clearQueryParams() {
    history.pushState(null, '', location.href.split('?')[0]);
  }

  static newUuid() { // copied off internet... hopefully never repeats
    function _p8(s?) {
      const p = (Math.random().toString(16) + '000000000').substr(2, 8);
      return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    }

    return _p8() + _p8(true) + _p8(true) + _p8();
  }

  static formatBytesToUnits(bytes: any, decimals = 2): string {
    if (bytes <= 0) {
      return `Invalid: ${bytes} Bytes`;
    }

    const baseK = 1024;
    const units = ['B', 'KB', 'MB', 'GB'];

    const dm = Number.isInteger(decimals) ? decimals : 0;

    const unit = Math.floor(Math.log(bytes) / Math.log(baseK));

    return `${parseFloat((bytes / Math.pow(baseK, unit)).toFixed(dm))} ${units[unit]}`;
  }

  static convertUnitToBytes(amount: number, unit: 'B' | 'KB' | 'MB' | 'GB') {
    if (amount <= 0) {
      return amount;
    }

    const baseK = 1024;
    const power = ['B', 'KB', 'MB', 'GB'].indexOf(unit);
    return Math.pow(baseK, power) * amount;
  }

  /**
   * Utility method used to get the number of chips that can fit into a space. Used to determined when to show a '+ x more' chip
   * @param chipOptions
   * pixelLimit: number; // the number of pixels available in a given space for chips
   * chipHorizontalPadding: number; // the amount of horizontal padding for each chip
   * chipMargin: number; // the amount of margin between each chip
   * chipStrings: string[]; // the strings within the chips that need their width calculated
   * font: string; // the font(for the accurate calculation via canvas + the getTextWidth() utility method.
   */
  static getChipCount(chipOptions: ChipCountOptions) {
    // creating one instance of canvas to use in the utility method rather than many instances each time the utility method runs
    const canvas = document.createElement('canvas');
    let count = 0;
    let currentSizeTotal = 0;
    for (const [i, val] of chipOptions.chipStrings?.entries()) {
      const size = this.getTextWidth(val, canvas, chipOptions.font || 'normal 12px montserrat')
        + chipOptions.chipHorizontalPadding;
      if (i === chipOptions?.chipStrings.length - 1) {
        currentSizeTotal -= 70; // remove the width that the +n more would take up
      }
      if ((currentSizeTotal + size <= chipOptions.pixelLimit)) {
        count++;
        currentSizeTotal += size + chipOptions.chipMargin;

      } else {
        return count;
      }
    }
    return count;
  }

  static getTextWidth(text: string, canvas: HTMLCanvasElement, font: string) {
    // re-use canvas object for better performance
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    const spaceCount = (text.split(' ').length - 1);
    return metrics.width + (spaceCount ? spaceCount * 4 : 0);
  }

  static aSlashBWithOrLongDash(a: string, b:string): string {
    return this.aSlashB(this.orLongDash(a), this.orLongDash(b));
  }

  static aSlashB(a: string, b: string): string {
    return a + ' / ' + b;
  }

  static orLongDash(str: string): string {
    if(str === null || str === undefined || str === '') {
      return LONG_DASH;
    }
    return str;
  }

  static getFormattedDateStr(date: string): string {
    const formattedDate = new Date(date).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS);
    return formattedDate === 'Invalid Date'? null : formattedDate;
  }

  static launchSnackBar(messageInfo: NotificationInfoModel, matSnackBar: MatSnackBar): void {
    matSnackBar.openFromComponent(NotificationSnackbarComponent, {
      duration: 5000,
      panelClass: ['no-padding-snackbar'],
      data: {
        messageInfo: {
          ...messageInfo
        }
      }
    });
  }

  static getObjectValuesAsString(obj: object) {
    if(typeof obj === 'string') { return obj; }
    if(!obj) { return ''; }
    let resultString = '';
    for(const [key, value] of Object.entries(obj)) {
      resultString += (value + ',');
    }
    return resultString;
  }

  static weekendsDatesFilter = (d: Date): boolean => {
    const day = moment(d).weekday();
    /* Prevent Sunday (0) and Saturday (6) for select. */
    return day !== 0 && day !== 6;
  }

  static isLumaAppDestination(urlString: string): boolean {
    const isLumaDomainRegex = new RegExp('((app|pm|an|ch|sp|lm|pl|dn).)((bdev|bqa|buat|staging).)?lumafintech.com');
    const url = new URL(urlString);
    return isLumaDomainRegex.test(url.hostname);
  }

}

export interface ChipCountOptions {
  pixelLimit: number;
  chipHorizontalPadding: number;
  chipMargin: number;
  chipStrings: string[];
  font?: string;
}

export function blobToBase64(blob: Blob): Observable<string> {
  if (!blob) {
    return of(null);
  }
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return fromEvent(reader, 'load').pipe(
    map(() => reader.result as string)
  );
}
