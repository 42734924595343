export interface AnnuityIllustrationParams {
    parameters: any | {
        id: string
        jurisdiction: number
        acordTypeCode: number;
        cusip: string
        activityName: string;
        carrierCode: string
    };
    uri: string;
    vendor: 'imagisoft' | 'FireLight';
    vendorUniqueId?: string; // added from front end
}

export class AnnuityIllustrationSummaryModel {
    cusip;
    name;
    agentName: string;
    carrier;
    product;
    initialPremium;
    age;
    sex;
    incomeRider;
    incomeRiderCode;
    incomeStartAge;
    payoutType;
    jointSex;
    jointAge;
    incomeStartDate;
    allocations;
    illustrationParams;
    state;
    type;
    annuityParams: AnnuityIllustrationParams;
}

