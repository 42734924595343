import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChartUtilService {
    public static millionsLabel(label, index, lables) {
        if (label > 0 && label / 1000000 > 1) {
            return `${label / 1000000}M`;
        } else {
            return label;
        }
    }

    public static convertLabelToArray(x) {
        const result = [];
        if (x.issuer != null) {
            if (x.issuer.split(' ').length > 1) {
                const parts = x.issuer.split(' ');
                for (let i = 0; i < parts.length; i++) {
                    if (i < parts.length - 1 && parts[i].length + parts[i + 1].length < 6) {
                        parts[i] = `${parts[i]} ${parts[i + 1]}`;
                        result.push(parts[i]);
                        i++;
                    } else {
                        result.push(parts[i]);
                    }
                }
            } else {
                result.push(x.issuer);
            }
        }
        return result;
    }
}
