import { AfterViewInit, Directive, ElementRef, Optional } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

@Directive({
  selector: '[appOutlineGapDirective],mat-form-field.luma-input,mat-form-field.luma-input-chart'
})
export class OutlineGapDirective implements AfterViewInit {

  constructor(@Optional() private matFormField: MatFormField,
              private elRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.matFormField?.updateOutlineGap();}, 500);
  }
}
