import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-yes-no-toggle',
  templateUrl: './yes-no-toggle.component.html',
  styleUrls: ['./yes-no-toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YesNoToggleComponent),
    multi: true
  }]
})
export class YesNoToggleComponent implements ControlValueAccessor {
  onChange;
  value: boolean;
  disabled;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // we don't need
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  toggleClicked(buttonValue) {
    if(this.value === buttonValue) {
      return;
    } else {
      this.value = buttonValue;
      this.onChange(buttonValue);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
