import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ComponentEventsService } from '@common/components/product-details-modal/services/component-events.service';
import { CSS_COLORS } from '@common/models/css-colors';

@Component({
  selector: 'app-product-details-modal-metrics',
  templateUrl: './product-details-modal-metrics.component.html',
  styleUrls: ['./product-details-modal-metrics.component.scss']
})
export class ProductDetailsModalMetricsComponent implements OnInit, AfterViewInit {
  @Input() demoMode: boolean;
  @Input() hockeystickPlotPoints: Array<Array<number>>;
  @Input() fallbackImageUrl: string;

  loadComplete = false;
  graphingLumaHalfBaked5 = CSS_COLORS.GRAPHING_LUMA_HALF_BAKED_5;
  cardContentHeight;
  showErrorModal = false;

  constructor(
      private cdr: ChangeDetectorRef,
      private componentEventsService: ComponentEventsService,
  ) { }

  ngOnInit() {
    this.loadComplete = true;
  }

  ngAfterViewInit() {
    this.componentEventsService.getProductDetailsHeight().subscribe(height => {
      this.cardContentHeight = height;
      this.cdr.detectChanges();
    });
  }
}
