import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-exit-firelight-modal',
  templateUrl: './confirm-go-to-signatures-modal.component.html',
  styleUrls: ['./confirm-go-to-signatures-modal.component.scss']
})
export class ConfirmGoToSignaturesModalComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmGoToSignaturesModalComponent>) { }

  confirm() {
    this.dialogRef.close('confirm');
  }
  close() {
    this.dialogRef.close();
  }
}
