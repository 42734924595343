import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { FilterGroup, FilterOption, GenericFilterTypes, RangeFilterType, } from './base-filter.model';
import { RangeFilterModel } from './range-filter';
import { TextSearchFilterModel } from './text-filter';
import { FilterComponents, FilterTypesEnum } from './filter-types.enum';

export abstract class BaseFilter<U extends GenericFilterTypes> {
  filterApplied: boolean;
  displayName: string;

  protected multiFilters = [
    FilterComponents.MULTI_TEXT_SEARCH,
    FilterComponents.MULTI_CHIP_FILTER,
    FilterComponents.MULTI_SELECT_SEARCH,
  ];

  // tslint:disable-next-line:variable-name
  protected constructor(protected _baseFilterModel: FilterGroup<U>) {}

  // tslint:disable-next-line:variable-name
  protected _onFilterChange: Subject<FilterOption<U>[]> = new Subject<FilterOption<U>[]>();

  get onFilterChange(): Observable<FilterOption<U>[]> {
    return this._onFilterChange.asObservable();
  }


  // TODO
  toHttpParams: () => HttpParams;

  abstract applyFilterView(filters: FilterGroup<any>);
  abstract applyFilter<T>(dataToBeFiltered: T[]): T[];
  abstract doesFilterPass(dataToBeFiltered: any[]): boolean;
  abstract clearFilters(closeExpansion: boolean);

  toggleFilter(filterOption: FilterOption<U>, emitEvent?: boolean) {
    filterOption.selected = !filterOption.selected;
    if (!this.multiFilters.includes(this.baseFilterModel?.componentType)) {
      this._baseFilterModel.filters
        .filter(filter => filter.searchValue !== filterOption.searchValue)
        .forEach(filter => filter.selected = false);
    }

    this.setFilters(emitEvent);
  }

  filterValuesChanged(emitEvent = true) {
    this.setFilters(emitEvent);
  }

  getSelectedFilters(): FilterOption<U>[] {
    return this._baseFilterModel.filters?.filter(filter => filter.selected);
  }

  setSelectedFilters(filterOptions: FilterOption<U>[], emitEvent = true) {
    this._baseFilterModel.filters?.forEach(filter => filter.selected = false);
    filterOptions.forEach(filterOption => filterOption.selected = true);
    this.setFilters(emitEvent);
  }

  selectAll(emitEvent = true) {
    this._baseFilterModel.filters?.forEach(filterOption => filterOption.selected = true);
    this.setFilters(emitEvent);
  }

  deselectAll(emitEvent = true) {
    this._baseFilterModel.filters?.forEach(filterOption => filterOption.selected = false);
    this.setFilters(emitEvent);
  }

  protected setFilters(emitEvent: boolean) {
    // for NUMBER_SEARCH: the value is set inside number search valueUpdated function.
    if(this._baseFilterModel.filterType !== FilterTypesEnum.NUMBER_SEARCH) {
      this._baseFilterModel.filterValues = this._baseFilterModel.filters?.filter(f => f.selected).map(f => f.searchValue);
    }
    this.filterApplied = this.isFilterApplied();
    if (emitEvent) {
      this._onFilterChange.next(this._baseFilterModel?.filters);
    }
  }

  protected isFilterApplied(): boolean {
    switch (this.baseFilterModel.filterType) {
      case FilterTypesEnum.PERCENT_RANGE: // fallthrough
      case FilterTypesEnum.NUMBER_RANGE:
        if (this.baseFilterModel.componentType === FilterComponents.SLIDER_FILTER) {
          return this.baseFilterModel.filters?.length === 1
            && ((this.baseFilterModel.filters[0].searchValue as RangeFilterType).min !== (this.baseFilterModel.filters[0].searchValue as RangeFilterType).currentFloor
              || (this.baseFilterModel.filters[0].searchValue as RangeFilterType).max !== (this.baseFilterModel.filters[0].searchValue as RangeFilterType).currentCeiling
          );
        }

        return this.baseFilterModel.filters.some(filter => !filter.selected);
      case FilterTypesEnum.NUMBER_RANGE_TWO_FIELDS:
        return this.baseFilterModel.filters?.some(filter => filter.selected && filter.searchValue.min !== filter.searchValue.currentVal);
      case FilterTypesEnum.NUMBER_SEARCH:
        return this._baseFilterModel.filterValues?.[0] != null;
      default:
        return this._baseFilterModel.filterValues?.length > 0 && this._baseFilterModel.filterValues?.length !== this._baseFilterModel.filters?.length;
    }
  }

  destroy() {
    this._onFilterChange.complete();
  }

  get baseFilterModel(): FilterGroup<U> | RangeFilterModel | TextSearchFilterModel {
    return this._baseFilterModel;
  }
}
