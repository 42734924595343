import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-luma-loading-grid-overlay',
  templateUrl: './luma-loading-grid-overlay.component.html',
  styleUrls: ['./luma-loading-grid-overlay.component.scss']
})
export class LumaLoadingGridOverlayComponent implements ILoadingOverlayAngularComp {
  params: any;
  agInit(params): void {
    this.params = params;
  }
}
