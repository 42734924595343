import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { CustomFormValidatorsService } from '@product-marketplace/common/custom-form-validators.service';
@Injectable({
    providedIn: 'root'
})
export class OrderFormDynFieldService implements OnDestroy {

    alive = true;
    orderId: number = null;
    fullPayloadData: any;
    constructor(private fb: FormBuilder,
                private customFormValidatorsService: CustomFormValidatorsService) { }

    addDynamicFields(data: any, form: any, spec: any, configOptions: any) {
        const dynamicFieldsList = [];
        spec.forEach(control => {
            if (control.itemType === 'dynamic') {
                dynamicFieldsList.push(control.itemDetails.sourcePath);
                form.addControl(control.itemDetails.sourcePath, this.createControl(control, data));
            } else {
                configOptions[control.itemDetails.bindProperty] = { value: control.itemDetails.bindValue || null, state: control.itemDetails.state || '' };
                if (control.itemDetails.state === 'disabled') {
                    form.get(control.itemDetails.bindProperty).disable();
                } else if (control.itemDetails.validators && control.itemDetails.validators.length > 0 && control.itemDetails.state !== 'hidden') {
                    form.get(control.itemDetails.bindProperty).setValidators(this.addControlValidation(control.itemDetails.validators));
                }
                if (control.itemDetails.bindValue && form.get(control.itemDetails.bindProperty)) {
                    form.get(control.itemDetails.bindProperty).setValue(control.itemDetails.bindValue);
                }
            }
        });
        spec.forEach(control => {
            if (control.itemDetails.changeDetection) {
                this.addChangeDetection(control, form, configOptions);
            }
        });
        return dynamicFieldsList;
    }

    createControl(config: any, data: any) {
        let validation = null;
        if (config.itemDetails.validators && config.itemDetails.state !== 'hidden') {
            validation = this.addControlValidation(config.itemDetails.validators);
        }
        const control = this.fb.control({ disabled: false, value: null }, validation);
        control.setValue(this.getDynamicControlValueFromData(config.itemDetails.sourcePath, data));
        return control;
    }

    getDynamicControlValueFromData(path: string, data) {
        const pathVariables = path.split('.');
        let temp: any = JSON.parse(JSON.stringify(data));
        try {
            for (const pathVar of pathVariables) {
                temp = temp[pathVar];
            }
        } catch {
            temp = null;
        }
        return temp;
    }

    getControlFromSourcePath(formControl: any, sourcePath: string) {
        const controlPath = sourcePath.split('.');
        let newControl = formControl;
        for (const c of controlPath) {                                      // getting the control designated by changesOn
            if (c === controlPath[controlPath.length - 1]) {
                newControl = newControl.get(c);
            } else {
                newControl = newControl.get(c) as FormGroup;
            }
        }
        return newControl;
    }

    addControlValidation(validators: any[]) {
        const validatorList: any[] = [];
        for (const val of validators) {
            if (val.custom) {
                if (val.validatorArgument) {
                    validatorList.push(this.customFormValidatorsService[val.validator](val.validatorArgument));
                } else {
                    validatorList.push(this.customFormValidatorsService[val.validator]());
                }
            } else {
                if (val.validatorArgument) {
                    validatorList.push(Validators[val.validator](val.validatorArgument));
                } else {
                    validatorList.push(Validators[val.validator]);
                }
            }
        }
        return Validators.compose(validatorList);
    }

    addChangeDetection(control: any, form: any, configOptions: any) {
        if (control.itemDetails.changeDetection) {
            for (const changeDetector of control.itemDetails.changeDetection) { // looping through all possible change detectors
                let formControl = form;
                formControl = this.getControlFromSourcePath(formControl, changeDetector.bindProperty);      // getting the control to watch for changes on
                if (formControl) {
                    let currentControl: any;
                    if (control.itemType === 'dynamic') {                                                           // getting our current control
                        currentControl = form;
                        currentControl = this.getControlFromSourcePath(currentControl, control.itemDetails.sourcePath);

                    } else {
                        currentControl = form.get(control.itemDetails.bindProperty);
                    }
                    formControl.valueChanges.pipe(takeWhile(x => this.alive)).subscribe(value => {          // react to changes on the 'bindproperty' control
                        if (changeDetector.valueMapping) {
                            // console.log('value changed to ', formControl.value);
                            if(changeDetector.valueMapping[value]) {
                                currentControl.setValue(changeDetector.valueMapping[value]);
                            } else if(changeDetector.valueMapping._defaultValue) {
                                currentControl.setValue(changeDetector.valueMapping._defaultValue);
                            }
                        }
                        if (changeDetector.validationMapping) {
                            currentControl.clearValidators();
                            if (changeDetector.validationMapping[value]) {
                                currentControl.setValidators(this.addControlValidation(changeDetector.validationMapping[value].validators));
                                // console.log('validation for ', formControl.value);
                            }
                            currentControl.updateValueAndValidity();
                        }
                        if (changeDetector.stateMapping) {
                            // console.log('state change for ', formControl.value);
                            configOptions[control.itemDetails.bindProperty].state = changeDetector.stateMapping[value];
                            if (changeDetector.stateMapping[value] === 'hidden') {
                                currentControl.reset(control.itemDetails.bindValue || null);
                            }
                        }
                    });
                }
            }
        }
    }


    ngOnDestroy() {
        this.alive = false;
    }
}
