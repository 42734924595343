import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { OverlayRef, Overlay, ConnectionPositionPair, ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

interface OverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  origin?;
}

@Injectable({
  providedIn: 'root'
})
export class MultiMonthPickerService {
  calChange$ = new BehaviorSubject(null);
  validValuesArr = [];
  selectedArr = [];
  defaultArr = [];
  defaultIndex = 0;

  public ref: OverlayRef;

  constructor(public overlay: Overlay) { }

  open(config: OverlayConfig = {}, componentType: ComponentType<any>) {
    const positionStrategy = this.overlay
                                .position()
                                .flexibleConnectedTo(config.origin)
                                .withPositions(this.getPositions())
                                .withFlexibleDimensions(false)
                                .withPush(false);

    this.ref = this.overlay.create({
      height: '260px',
      width: '296px',
      hasBackdrop: true,
      panelClass: 'cal-month-panel',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy,
    });

    const filePreviewPortal = new ComponentPortal(componentType);

    // Attach ComponentPortal to PortalHost
    this.ref.attach(filePreviewPortal);
    this.ref.backdropClick().subscribe(() => this.close());
  }

  close(): void {
    this.ref.dispose();
  }

  reset() {
    this.selectedArr = [...this.defaultArr];
    this.calChange$.next(this.selectedArr);
  }

  calChanged() {
    this.calChange$.next(this.selectedArr);
  }

  public getPositions(): ConnectionPositionPair[] {
    return [
      { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
      { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top'},
    ];
  }
}
