import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, share, startWith, switchMap, switchMapTo, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-luma-tooltip',
  templateUrl: './luma-tooltip.component.html',
  styleUrls: ['./luma-tooltip.component.scss']
})
export class LumaTooltipComponent implements OnInit, OnDestroy {

  @Input() origin: CdkOverlayOrigin;
  @Output() closeTooltip = new EventEmitter<any>();
  @Output() openTooltip = new EventEmitter<any>();

  @ViewChild('dialog') dialog: ElementRef;

  alive = true;
  isOpened = false;
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const CdkOverlayOriginEl = this.origin?.elementRef?.nativeElement;
    // open popup if mouse stopped in CdkOverlayOriginEl (for short time).
    // If user just quickly got over CdkOverlayOriginEl element - do not open
    const open$ = fromEvent(CdkOverlayOriginEl, 'mouseenter').pipe(
      switchMap(enterEvent =>
        fromEvent(document, 'mousemove').pipe(
          startWith(enterEvent),
          debounceTime(300),
          filter((event: any) => {
            return CdkOverlayOriginEl === event?.target;
          })
        ),
        ),
        share()
    );


    open$.pipe(
      takeWhile(x => this.alive)
    ).subscribe(() => this.changeState(true));

    // close if mouse left the CdkOverlayOriginEl and dialog(after short delay)
    const close$ = fromEvent(document, 'mousemove').pipe(
      takeWhile(x => this.alive),
      debounceTime(100),
      filter(() => this.isOpened),
      filter(event => this.isMovedOutside(CdkOverlayOriginEl, this.dialog, event))
    );

    open$.pipe(
      takeWhile(x => this.alive),
      switchMapTo(close$)
    ).subscribe(() => {
      this.changeState(false);
    });
  }


  connectedOverlayDetach() {
    this.changeState(false);
  }

  private changeState(isOpened: boolean) {
    this.isOpened = isOpened;
    isOpened ? this.openTooltip.emit() : this.closeTooltip.emit();
    this.cdr.markForCheck();
  }

  private isMovedOutside(CdkOverlayOriginEl, dialog, event): boolean {
    return !(CdkOverlayOriginEl.contains(event?.target) ||     dialog.nativeElement.contains(event?.target));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
