import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output, Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utilities } from '@common/utilities/utilities';
import { Optional } from 'ag-grid-community';
import { distinctUntilChanged, filter } from 'rxjs/operators';


/* TODO - Extend Functionality
* Add Support for Numpad input
* Add support for number type; Min/Max
* Add Support back in for for boundaries
* Add Unit Tests (srs)
*  */


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[inputNumberFormatter]'
})
export class NumberFormatterDirective implements AfterViewInit {
    @Input() updateControl = true;
    @Input() formatCurrency = false;
    @Input() stripLeadingZeros = true;
    @Input() decimalPlacesAllowed: number;
    @Input() allowNull = false;
    @Input() enableNumberFormatter = true;
    @Input() min;
    @Input() max;
    @Input() millionairize = false; // add 000,000 when typing m or double shift  if current value between 1 and 99
    @Input() allowCopyPaste = true;
    @Input() stripPastedCommas = false;
    @Input() retainFocus = false;
    @Input() chipInput = false; // whether input is used as a select for chip elements
    @Output() valueFormatted = new EventEmitter();
    @Output() boundaryStatus = new EventEmitter();
    isMatInput = false;

    private lastKey: string;

    private readonly EVENT_CODES = {
        BACKSPACE: 'Backspace',
        TAB: 'Tab',
        ARROW_UP: 'ArrowUp',
        ARROW_RIGHT: 'ArrowRight',
        ARROW_DOWN: 'ArrowDown',
        ARROW_LEFT: 'ArrowLeft',
        DELETE: 'Delete',
        PERIOD: 'Period',
        NUMPAD_DECIMAL: 'NumpadDecimal',
        MINUS: 'Minus',
        NUMPAD_SUBTRACT: 'NumpadSubtract',
        C: 'KeyC',
        V: 'KeyV',
    };

    private readonly allowedEventCodes = [
        this.EVENT_CODES.BACKSPACE,
        this.EVENT_CODES.TAB,
        this.EVENT_CODES.ARROW_UP,
        this.EVENT_CODES.ARROW_RIGHT,
        this.EVENT_CODES.ARROW_DOWN,
        this.EVENT_CODES.ARROW_LEFT,
        this.EVENT_CODES.DELETE,
        this.EVENT_CODES.MINUS,
        this.EVENT_CODES.NUMPAD_SUBTRACT,
    ];

    constructor(private el: ElementRef,
                @Optional() @Self() private ngControl: NgControl) {
    }

    ngAfterViewInit() {
        this.isMatInput = this.el.nativeElement?.id?.includes('mat-input');
        if (this.min == null && this.el?.nativeElement?.min) {
            this.min = +this.el.nativeElement.min;
        }
        if (this.max == null && this.el?.nativeElement?.max) {
            this.max = +this.el.nativeElement.max;
        }
        if(this.allowCopyPaste) {
            this.allowedEventCodes.push(this.EVENT_CODES.C, this.EVENT_CODES.V);
        }
        if(this.decimalPlacesAllowed) {
            this.allowedEventCodes.push(this.EVENT_CODES.PERIOD, this.EVENT_CODES.NUMPAD_DECIMAL );
        }

        this.ngControl?.valueChanges?.pipe(distinctUntilChanged(), filter(val => val)).subscribe({
            next: value => this.onKeyUp(null, value?.toString()),
        });

    }

    @HostListener('paste', ['$event'])
    onPaste(event: any) {
        if(!this.enableNumberFormatter) {
            return;
        }
        if(!this.allowCopyPaste) {
            event.preventDefault();
            return;
        }

        const paste = this.stripPastedCommas ? event.clipboardData.getData('text').replaceAll(',', '').trim() : event.clipboardData.getData('text');
        const newPaste = Utilities.stripCurrencyFormatting(paste);
        if(paste.length !== newPaste.length) {
            event.preventDefault();
            return;
        }
        if(paste.split('').filter(char => char === '.').length > 1) {
            event.preventDefault();
            return;
        }
        const index = paste.indexOf('.');
        if(index !== -1 && paste.length - (index + 1) > this.decimalPlacesAllowed) {
            event.preventDefault();
            return;
        }
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event: any) {
        const keyAllowed = this.allowedEventCodes.includes(event.code);
        if(!this.enableNumberFormatter) {
            return;
        }
        if (event.key === 'Enter') {
            if(this.retainFocus === false) {
                event.target.blur();
            } else {
                event.target.focus();
            }
            return true;
        }
        if(!keyAllowed && !isFinite(event.key)) {
            event.preventDefault();
            return;
        }

        if(this.allowCopyPaste && (event.key === 'Control' || event.key === 'Meta')) {
            return;
        }
        if(this.allowCopyPaste && (event.key === 'c' || event.key === 'v' && (event.ctrlKey || event.metaKey))) { // will allow copy and paste to propagate
            return;
        }

        if (this.isDecimalEvent(event.code) && (event.target.value.includes('.') || !this.decimalPlacesAllowed)) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }

        if(isFinite(event.key)) {
            const newValue = this.sliceEventSelection(event);
            const aboveMax = +newValue > +this.max;
            const belowMin = +newValue < +this.min;
            if (newValue != null && (aboveMax || belowMin)) {
                this.boundaryStatus.emit({
                    aboveMax: aboveMax,
                    belowMin: belowMin
                });

                event.stopPropagation();
                event.preventDefault();
                return;
            }

            this.boundaryStatus.emit({
                aboveMax: false,
                belowMin: false
            });

            const fixedEvent = Utilities.stripCurrencyFormatting(event.key);
            const decimalPlacesExceed = this.decimalPlacesAllowed && this.limitDecimalPlaces(newValue);
            const selectAll = event.metaKey && event.code === 'KeyA';
            if (!(keyAllowed || fixedEvent || selectAll) || decimalPlacesExceed) {
                event.stopPropagation();
                event.preventDefault();
            } else {
                if (this.stripLeadingZeros && fixedEvent) {
                    const selectionStart = event.target.selectionStart;
                    event.target.value = Utilities.removeLeadingZeros(newValue);
                    event.target.selectionStart = event.target.selectionEnd = selectionStart + 1;
                    event.preventDefault();
                }
            }
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: any, fromEventValue?) {
        if (!(this.enableNumberFormatter) || this.isDecimalEvent(event?.code)) {
            return;
        }
        let val = fromEventValue ?? event.target.value;
        let updateControl;
        val = val.charAt(0) === '-' ? '-' + Utilities.stripCurrencyFormatting(val.substring(1, val.length)): Utilities.stripCurrencyFormatting(val);

        if (+val < +this.min) {
            val = this.min;
        } else if (+val > +this.max) {
            val = this.max;
        }
        if (this.formatCurrency) {
            if (val.length > 0) {
                val = Utilities.formatCurrency(val, 0);
            }
            const controlVal = Utilities.stripCurrencyFormatting(this.ngControl?.control?.value);
            updateControl = fromEventValue != null || (controlVal === '' && val === controlVal);
        }

        if (event != null) {
            event.target.value = val;
            this.processMillionairize(event);
        }
        if (event?.key) {
            this.lastKey = event.key;
        }
        if (event?.key === 'Backspace' && this.el.nativeElement.value.length === 0) {
            this.el.nativeElement.value = '';
            this.lastKey = '';
            return true;
        }

        if (updateControl || (this.updateControl && this.isMatInput)) {
            this.ngControl?.control?.setValue(event?.target?.value ?? val);
            this.ngControl?.control?.updateValueAndValidity();
        }

        if (event != null && !this.allowNull && !event.target.value && this.min != null && !this.chipInput) {
            event.target.value = this.min;
        }

        this.valueFormatted.emit(event?.target?.value ?? val);
    }

    // returns true if Propagation should be stopped
    // Does not work for number type inputs
    private limitDecimalPlaces(val): boolean {
        if (val === null) {
            return false;
        }
        const currentDecimalPlaces = val?.toString()?.split('.')[1]?.length;
        return currentDecimalPlaces && currentDecimalPlaces > this.decimalPlacesAllowed;
    }

    private sliceEventSelection(event): number { // Only works with input types of text/textarea
        if(this.stripLeadingZeros && event.target.value === '0') {
            return event.key;
        }
        if (event.target.selectionStart != null && event.target.selectionEnd != null) {
            const beforeSelectionStart = event.target.value.slice(0, event.target.selectionStart);
            const afterSelectionEnd = event.target.value.slice(event.target.selectionEnd, event.target.value.length);
            return (beforeSelectionStart + event.key + afterSelectionEnd);
        }
        return;
    }

    private processMillionairize(event) {
        if(!this.millionairize) {
          return;
        }
        if (event.code === 'KeyM' || event.key === 'Shift' && this.lastKey === 'Shift') {
          let current = Number(this.el.nativeElement.value);
          if (current < 100 && current > 0) {
            current = current * 1_000_000;
            this.el.nativeElement.value = event.target.value = current;
          }
        }
      }

    isDecimalEvent(eventCode): boolean {
        switch (eventCode) {
            case this.EVENT_CODES.PERIOD: // fall through
            case this.EVENT_CODES.NUMPAD_DECIMAL: // fall through
                return true;
        }
        return false;
    }
}
