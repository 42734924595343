import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="number"]',
})
export class NoScrollInputDirective {

  @HostListener('mousewheel', ['$event'])
  onWheel(event: Event) {
    event.preventDefault();
  }

}
