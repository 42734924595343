import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeskService {
  SERVICE_ROOT = `${environment.hosts.api_gateway}/api/desk-service/desk`;
  TRADE_DESK_FOR_NAMES = `${this.SERVICE_ROOT}/all/for-names`;
  constructor(private http: HttpClient) { }

  getTradeDesksForName(shortName: string) {
    return this.http.post(this.TRADE_DESK_FOR_NAMES, [shortName]).pipe(map(result => result?.[0]));
  }
}
