import moment, { Duration, unitOfTime } from 'moment';
export type UnitOfTime = unitOfTime.Base;

const TIME_UNITS_IN_MS = {
  millisecond: 1,
  second: 1000,
  minute: 60000,
  hour: 3600000,
  day: 24 * 3600000,
  week: 7 * 24 * 3600000,
  month: 28 * 24 * 3600000,
  year: 364 * 24 * 3600000
};

const TIME_UNITS: UnitOfTime[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute'
];

export class TimeFormattingUtilities {

  static FormatDateAndTimeHasPassedSinceNow(date: Date, units = ['year', 'month', 'week', 'day'], format = 'MM/DD/YYYY'): string {
    const duration = moment.duration(moment().diff(moment(date), 'ms', true));
    let timeHasPassed = this.TimeHasPassed(duration, units);

    if (!timeHasPassed) {
      timeHasPassed = this.TimeHasPassed(duration, ['hour', 'minute']);
    }

    return `${moment(date).format(format)}${timeHasPassed ? ` (${timeHasPassed} ago)` : ''}`;
  }

  static TimeHasPassedSinceNow(date: Date, units = ['year', 'month', 'week', 'day']): string {
    const duration = moment.duration(moment().diff(moment(date), 'ms', true));
    return this.TimeHasPassed(duration, units);
  }


  static TimeHasPassed(duration: Duration, units = ['year', 'month', 'week', 'day']): string {
    const durationClone = duration.clone();
    let output = '';
    TIME_UNITS.forEach(unit => {
      if (units.includes(unit)) {
        const unitGot = durationClone.get(unit);
        if (unitGot) {
          output += ` ${unitGot} ${unit}`;

          if (unitGot > 1) {
            output += 's';
          }

          durationClone.subtract(unitGot, unit);
        }
      }
    });
    return output.trim();
  }


  static addHours(time: string, hoursToAdd: number) {
    if(!time) {
      return time;
    }
    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':').map(Number);

    // Create a new Date object for the current day
    const date = new Date();
    date.setHours(hours, minutes);

    // Add 4 hours to the time
    date.setHours(date.getHours() + hoursToAdd);

    // Format the new time
    const newHours = String(date.getHours()).padStart(2, '0');
    const newMinutes = String(date.getMinutes()).padStart(2, '0');

    return `${newHours}:${newMinutes}`;
  }

  // Dont Use - PoC - but ZF is keeping
  // static TimeHasPassedRaw(value: number, units = ['month', 'week', 'day']): string {
  //   let outPut = '';
  //   Object.keys(TIME_UNITS_IN_MS).reverse().forEach((key) => {
  //     console.log(value, key, TIME_UNITS_IN_MS[key]);
  //     if (units.includes(key)) {
  //       const unit = Math.floor(value / TIME_UNITS_IN_MS[key]);
  //       console.log(unit);
  //       if (unit) {
  //         value = value % TIME_UNITS_IN_MS[key];
  //         outPut += `${unit} ${key}s `;
  //       }
  //     }
  //   });
  //   console.log(outPut);
  //   return outPut;
  // }
}
