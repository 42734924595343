import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-payload-error-card',
  templateUrl: './payload-error-card.component.html',
  styleUrls: ['./payload-error-card.component.scss']
})
export class PayloadErrorCardComponent {
  @Input() message = 'Error retrieving data.';
  @Input() showLoadingIcon = false;
  @Output() retry = new EventEmitter();

  constructor() { }


  retryEndpoint() {
    this.retry.emit();
  }
}


