import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  @Input() message: string;
  @Input() hasButton = false;
  @Input() buttonText = 'Refresh';
  @Input() iconName: IconProp = 'info-circle';
  @Input() centerErrorModalInWholePage = false;
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  handleButtonClick(event) {
    this.buttonClicked.emit(event);
  }
}
