import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { User } from "@common/models/user";
import { Subscription } from "rxjs";
import { DisplayDeskConfig } from "./global.nav.utils";
import { NavTabType, NavigateHandlerType } from "./global-nav.types";

@Component({
  selector: "app-global-nav",
  templateUrl: "./global-nav.component.html",
  styleUrls: ["./global-nav.component.scss"],
})
export class GlobalNavComponent implements OnInit, OnDestroy {
  @Input() displayConfig: DisplayDeskConfig;
  @Input() userName: string;
  @Input() logout: () => void;
  @Input() tabs: NavTabType[];
  @Input() issuerLogo?: string;
  @Input() logoWidth?: string;
  @Input() logoHeight?: string;
  @Input() accountAriaLabel: string;
  @Input() menuAriaLabel: string;
  @Input() handleNavigate: NavigateHandlerType;
  @Input() contactEmail?: string;
  isMobile = false;
  userSub: Subscription;
  breakpointSubscription: Subscription;
  activeTab: string;
  parentActiveTab?: string;
  variantType: string;

  constructor(private breakPointObserverService: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointSubscription = this.breakPointObserverService
      .observe("(max-width: 916px)")
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = breakpointState.matches;
      });

    this.defineVariantType();

    const pathName = this.getInitialPathName();
    this.setActiveTab(pathName);
  }

  defineVariantType() {
    this.variantType = this.issuerLogo ? "secondary" : "primary";
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  setActiveTab(value: string) {
    this.activeTab = value;
  }

  setParentActiveTab(value: string | undefined) {
    this.parentActiveTab = value;
  }

  getInitialPathName() {
    const pathname = window.location.pathname;

    for (let i = 0; i < this.tabs.length; i++) {
      const currentObj = this.tabs[i];
      if (currentObj.route && pathname.startsWith(currentObj.route)) {
        return currentObj.title;
      }

      if (currentObj.title === "TRADE" && pathname.split("/").includes("secondaries")) {
        return currentObj.title;
      }

      if (currentObj.title === "CREATE" && pathname.includes("creationhub")) {
        return currentObj.title;
      }

      if (currentObj.children) {
        for (let i = 0; i < currentObj.children.length; i++) {
          const currOptionObj = currentObj.children[i];
          if (currOptionObj?.route && pathname.startsWith(currOptionObj.route)) {
            this.setParentActiveTab(currentObj.title);
            return currOptionObj.title;
          }
        }
      }
      if (pathname.includes("product-details")) {
        return "LIFECYCLE";
      }
    }
    return "HOME";
  }
}
