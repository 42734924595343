import {
  PerformanceReportDisclaimer,
  WhiteLabelDisclaimerText,
  WhiteLabelDisclaimerUrl,
  WhiteLabelInfo,
  WhiteLabelProgramOverride
} from '@common/models/white-label-info';
import { SharedRegexUtility } from '@common/utilities/shared-regex.utility';

export class WhiteLabelUtils {
  static getProgramOverrideByProgramId(whiteLabelProgramOverrides: WhiteLabelProgramOverride[], programId: number): WhiteLabelProgramOverride {
    return whiteLabelProgramOverrides?.find(programOverride => programOverride.programId === programId);
  }

  static findOrCreateProgramOverride(whiteLabelProgramOverrides: WhiteLabelProgramOverride[], companyShortName: string, programId: number) {
    if ((!companyShortName || typeof companyShortName !== 'string') || (programId == null || isNaN(programId))) {
      return null;
    }

    let programOverride = this.getProgramOverrideByProgramId(whiteLabelProgramOverrides, programId);

    if (!programOverride) {
      programOverride = this.createEmptyOverride(programId, companyShortName);
    }

    return programOverride;
  }

  static createEmptyOverride(programId: number, companyShortName: string): WhiteLabelProgramOverride {
    return {
      programId,
      programLogo: '',
      shortName: companyShortName,
      isActive: false,
      disclaimerText: {
        disclaimerText: '',
        disclaimerTextEnabled: false,
      },
      disclaimerUrl: {
        disclaimerUrlAddress: '',
        disclaimerUrlText: '',
        disclaimerUrlEnabled: false,
      },
      performanceReportDisclaimer: {
        useDisclaimerText: false,
        perfDisclaimerText: '',
        hideLumaDisclaimerText: false,
      },
      eventNotificationDisclaimer: {
        disclaimerText: '',
        disclaimerTextEnabled: false,
      },
    };
  }


  static getDisclaimerText(whiteLabelInfo: WhiteLabelInfo, programId: number) {
    const override = this.getProgramOverrideByProgramId(whiteLabelInfo.whiteLabelProgramOverrides, programId);
    const isDisclaimerTextAvailableAndActive = (disclaimerText: WhiteLabelDisclaimerText) => {
      return disclaimerText?.disclaimerTextEnabled && disclaimerText?.disclaimerText;
    };

    if (override?.isActive && isDisclaimerTextAvailableAndActive(override?.disclaimerText)) {
      return override?.disclaimerText?.disclaimerText;
    } else if (isDisclaimerTextAvailableAndActive(whiteLabelInfo.whiteLabelDisclaimerText)){
      return whiteLabelInfo.whiteLabelDisclaimerText.disclaimerText;
    }

    return null;
  }

  static getDisclaimerUrl(whiteLabelInfo: WhiteLabelInfo, programId: number) {
    const override = this.getProgramOverrideByProgramId(whiteLabelInfo.whiteLabelProgramOverrides, programId);
    const isDisclaimerUrlAvailableAndActive = (disclaimerUrl: WhiteLabelDisclaimerUrl) => {
      return disclaimerUrl?.disclaimerUrlEnabled && disclaimerUrl?.disclaimerUrlAddress && disclaimerUrl.disclaimerUrlText;
    };

    const parseDisclaimer = (disclaimerUrl: WhiteLabelDisclaimerUrl) => {
      let link = disclaimerUrl.disclaimerUrlAddress;
      const title = disclaimerUrl.disclaimerUrlText;
      if (!link.match(SharedRegexUtility.urlStartWithNewRoot)) {
        link = `//${link}`;
      }
      return {title, link};
    };

    if (override?.isActive && isDisclaimerUrlAvailableAndActive(override?.disclaimerUrl)) {
      return parseDisclaimer(override.disclaimerUrl);
    } else if (isDisclaimerUrlAvailableAndActive(whiteLabelInfo.whiteLabelDisclaimerUrl)){
      return parseDisclaimer(whiteLabelInfo.whiteLabelDisclaimerUrl);
    }

    return null;
  }

  static getWhiteLabelLogo(whiteLabelInfo: WhiteLabelInfo, programId: number) {
    const override = this.getProgramOverrideByProgramId(whiteLabelInfo.whiteLabelProgramOverrides, programId);

    if (override?.isActive && override?.programLogo) {
      return override.programLogo;
    } else if (whiteLabelInfo?.companyUri){
      return whiteLabelInfo?.companyUri;
    }

    return '';
  }

  static getContactEmail(whiteLabelInfo: WhiteLabelInfo) {
    if (whiteLabelInfo?.contactEmail) {
      return whiteLabelInfo?.contactEmail;
    }
    return '';
  }
}
