import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.scss']
})
export class LoginFooterComponent {
  @Input() environmentName;
  @Input() isMaintenanceMode;
}
