import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-snackbar',
  templateUrl: './update-snackbar.component.html',
  styleUrls: ['./update-snackbar.component.scss']
})
export class UpdateSnackbarComponent {
  updateMessage = '';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackRef: MatSnackBarRef<UpdateSnackbarComponent>
    ) {
    if (data.msg) {
      this.updateMessage = data.msg;
    } else {
      this.updateMessage = 'Updates pending';
    }
  }

  reload() {
    this.snackRef.dismiss();
    this.data.reload();
  }
}
