import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { v1 as uuidv1 } from 'uuid';
import { CSS_COLORS } from '@common/models/css-colors';
import { Utilities } from '@common/utilities/utilities';

declare var require: any;

const boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const more = require('highcharts/highcharts-more');
const exporting = require('highcharts/modules/exporting');
const exportData = require('highcharts/modules/export-data');
const date = new Date(new Date().toISOString());
const exportProductReturn = 'Export_Product_Return_'.concat((date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear());

boost(Highcharts);
noData(Highcharts);
more(Highcharts);
exporting(Highcharts);
exportData(Highcharts);

@Component({
  selector: 'app-product-details-modal-payout-chart',
  templateUrl: './product-details-modal-payout-chart.component.html',
  styleUrls: ['./product-details-modal-payout-chart.component.scss']
})
export class ProductDetailsModalPayoutChartComponent implements OnInit, AfterViewInit {
  @Input() ourPayload: Array<Array<number>>;

  constructor() { }

  chartSeriesOptions;
  highChartsTemplateHook = '';
  seriesOptions = [];

  extractPayloadForChart(payloadToParse: Array<Array<number>>) {
    if (payloadToParse !== undefined) {
      this.seriesOptions.push( { type: 'line', name: 'Index Price Return (Product Return):', data: payloadToParse, marker: {enabled: false}, color: CSS_COLORS.GRAPHING_LUMA_HALF_BAKED_5});
    }
    Highcharts.chart(this.highChartsTemplateHook, this.chartSeriesOptions);
  }

  ngOnInit() {
    if ( this.ourPayload === null ) {
      throw new Error('A Luma payload of type: HCHockeyStickDetails is required to render this stock chart.');
    }
    this.highChartsTemplateHook = 'container_' + uuidv1();
    this.chartSeriesOptions =  {
      title: {
        text: null
      },
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            menuItems: Highcharts.getOptions().exporting.buttons.contextButton.menuItems.slice(0, 6)
          }
        },
        chartOptions: {
          credits: {
            text: Utilities.getExportDisclaimer(),
            enabled: true,
            position: {
              align: 'center',
              y: -5
            },
            style: {
              fontSize: '5pt',
            },
          },
        },
        filename: exportProductReturn,
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          softThreshold: false,
          label: {
            connectorAllowed: false
          },
          marker: {
            enabled: true
          }
        }
      },
      tooltip: {
        formatter() {
          const formatDecimal = (num) => Highcharts.numberFormat(num, 2, '.', ',');
          const formattedX = formatDecimal(this.x);
          const formattedY = formatDecimal(this.y);
          return `<span style="color:${this.series.color}">${this.series.name}</span>: <b>${formattedX}</b> (${formattedY}%)<br>`;
        },
        split: false,
        backgroundColor: 'rgba(247, 247, 247, 0.90)',
      },
      series: this.seriesOptions,
      xAxis: {
        title: {
          text: 'Index Price Return'
        },
        labels: {
          format: '{value} %'
        }
      },
      yAxis: {
        floor: -100,
        softMin: -10,
        title: {
          text: 'Indicative Note Return'
        },
      }
    };
  }

  ngAfterViewInit() {
    // TODO - Actually Data may need this
    setTimeout(() => {this.extractPayloadForChart(this.ourPayload);}, 100);
    // this.extractPayloadForChart(this.ourPayload);
  }
}
