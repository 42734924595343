import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Utilities } from '@common/utilities/utilities';

@Component({
  selector: 'app-file-drag-and-drop',
  templateUrl: './file-drag-and-drop.component.html',
  styleUrls: ['./file-drag-and-drop.component.scss']
})
export class FileDragAndDropComponent {

  @ViewChild('fileInput') fileInput;

  @Input() hideUpload: boolean;
  @Input() disabled: boolean;
  @Input() fileUpdateMode: boolean;
  @Input()    // passing in no extensions input allows any extension to be accepted
  get extensions(): string[] { return this._extensions; }
  set extensions(extensions: string[]) {
    this._extensions = extensions;
    this.extensionsString = this.createExtensionsString(extensions);
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _extensions;

  messageTimeout: NodeJS.Timeout;

  @Output() submitFile: EventEmitter<File> = new EventEmitter();
  @Output() selectFile: EventEmitter<File> = new EventEmitter();
  @Output() cancelEdits: EventEmitter<null> = new EventEmitter();
  extensionsString;
  file: File;
  spinnerActive = false;
  message = {
    show: false,
    text: '',
    error: false
  };

  constructor() { }

  setFile(file) {
    this.file = file;
    this.selectFile.emit(file);
  }

  onFileDrop($event) {
    if(this.checkFileExtension($event.name)) {
      this.setFile($event);
      this.message.show = false;
    } else {
      this.displayMessage({
        show: true,
        text: 'Invalid file type. Upload types allowed: ' + this.extensionsString,
        error: true
      });
    }
  }

  submitFileClicked() {
    if(!this.disabled) {
      this.submitFile.emit(this.file);
    }
  }

  addFile() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected($event) {
    const selectedFile: File = this.fileInput.nativeElement.files[0];
    this.fileInput.nativeElement.value = '';
    if(this.checkFileExtension(selectedFile.name)) {
      this.setFile(selectedFile);
      this.message.show = false;
    } else {
      this.displayMessage({
        show: true,
        text: 'Invalid file type. Upload type must be ' + this.extensionsString,
        error: true
      });
    }
  }

  checkFileExtension(filename: string): boolean {
    if(!this.extensions || this.extensions.length === 0) {
      return true;
    }
    const extension = filename.substring(filename.lastIndexOf('.'));
    return this.extensions.some(x => x === extension);
  }

  createExtensionsString(extensionsArray: string[]) {
      return extensionsArray.slice(0, extensionsArray.length - 1 || 1).join(', ') + ((extensionsArray.length <= 1)?'':' or ' + extensionsArray[extensionsArray.length-1]);
  }

  removeFile() {
    if(this.spinnerActive) {
      return;
    }

    this.setFile(null);
  }

  displayMessage(message) {
    this.message = message;
    if(this.messageTimeout) {
      clearTimeout(this.messageTimeout);
    }
    this.messageTimeout = setTimeout(()=>{this.message.show = false;}, 30000);
  }

  downloadFile() {
    const blob = new Blob([this.file], {type: 'application/pdf'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = `_blank`;
    link.click();
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }

  onCancelEdits() {
    this.file = null;
    this.cancelEdits.emit();
  }

  toggleSpinner(value) {
    this.spinnerActive = value;
  }
}
