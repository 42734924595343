import { FilterComponents, FilterTypesEnum } from './filter-types.enum';


export type RangeFilterType = {min: number, max: number, step?: number, currentFloor?: number, currentCeiling?: number};
export type TwoFieldFilterType = {currentVal?: number, step?: number, min?: number, max?: number};
export type GenericFilterTypes = string | number | RangeFilterType | boolean | TwoFieldFilterType;


export interface FilterOption <U extends GenericFilterTypes> {
  displayName?: string;
  searchValue: U;
  selected?: boolean;
}

export enum ValueFormat {
  PERCENT,
  CURRENCY,
  NUMBER
}

export type SingleNumberRangeType = {minField?: string, maxField?: string, maxAmount?: number, minAmount?: number, ignoreBoundaries?: boolean};

export type FilterFields = string | {arrayProperty?: string, arrayObjectField?: string} & SingleNumberRangeType;

export interface BaseFilterModel<U extends GenericFilterTypes> {
  // Filter Type - Enum
  filterType: FilterTypesEnum;
  // Dot Notated Fields
  fieldsToCheck: FilterFields[];
  // if true, use the first non-null field in 'fieldsToCheck' to filter.
  // ie: when fieldsToCheck: ['a', 'b', 'c'] and the object is { a: null, b: 4, c: 5 }, 'b' will be used for the filter match (not c)
  useFirstNonNullField?: boolean;
  // Search Type - Specific to Type
  searchType: any;
  // Filters
  filters: FilterOption<U>[];
  // Search Values
  filterValues: U[];
  // Formatting for the filter to reference
  formatting?: ValueFormat;
  // Whether the filter is applicable after hydration with data
  isApplicable?: boolean;
}

export interface FilterGroup<U extends GenericFilterTypes> extends BaseFilterModel<U> {
  displayName: string;
  static?: boolean; // property to tell whether the filter needs to be hydrated dynamically or if properties are given from config
  expanded?: boolean;
  componentType: FilterComponents;
}
