import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { ProductToolsModule } from '@common/product-tools.module';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { PasswordUpdateFormComponent } from './password-update-form/password-update-form.component';
import { PasswordResetFormComponent } from './password-reset-form/password-reset-form.component';
import { SharedComponentsModule } from '@common/components/shared-components-modules.module';
import { MaterialModule } from '@common/material.module';
import { LogoutPageComponent } from './logout/logout-page.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFooterComponent,
    LoginFormComponent,
    PasswordUpdateFormComponent,
    PasswordResetFormComponent,
    LogoutPageComponent
  ],
  imports: [
    ProductToolsModule,
    SharedComponentsModule,
    MaterialModule
  ]
})
export class LoginModule {
  constructor() {}
}
