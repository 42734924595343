import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appElipsesTooltip]'
})
export class ElipsesTooltipDirective {

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
      this.renderer.addClass(this.elementRef.nativeElement, 'no-word-wrap');
    }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        element.title = element.textContent;
      }
      else if (element.title) {
        element.removeAttribute('title');
      }
    }, 300);
  }
}
