import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MiniNotificationComponent } from '@common/components/mini-notifcation/mini-notification.component';

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {

  displayMessage: any;
  fontColor = '#333538';
  private readonly DEFAULT_MESSAGE = MiniNotificationComponent.getInfoMessage('');
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private snackRef: MatSnackBarRef<NotificationSnackbarComponent>) {
    this.displayMessage = {
      ...this.DEFAULT_MESSAGE,
      ...data.messageInfo
    };

    this.fontColor = data.fontColor ?? this.fontColor;
  }

}
