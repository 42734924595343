import { ActivatedRoute, Router } from '@angular/router';

export function clearAccessTokenFromUrl(router: Router, route: ActivatedRoute) {
  router.navigate(
    [],
    {
      relativeTo: route,
      queryParams: {access_token: null, refresh_token: null},
      replaceUrl: true,
      queryParamsHandling: 'merge'
    });
}
