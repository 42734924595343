import { Utilities } from '@common/utilities/utilities';

export const alignTextStart = {
    headerClass: ['align-header-start'],
    cellClass: ['align-cell-start']
};

export const alignTextCenter = {
    headerClass: ['align-header-center'],
    cellClass: ['align-cell-center']
};


/*
*
* These will be moved a shared Utilities when I'm able to better own any changes
*
*/

export const EMPTY_CELL_VALUE = '-';

export function percentFromDecimalFormatter(params) {
    if (params.value != null) {
        return Utilities.formatPercentageFromDecimal(params.value);
    }
    return EMPTY_CELL_VALUE;
}

export function formatPercentage(params) {
    let rawNum = params.value;
    if (typeof rawNum === 'string') {
        if (rawNum.includes('%')) {
            return rawNum;
        }

        rawNum = parseFloat(rawNum);
    }

    if (!rawNum) {
        return EMPTY_CELL_VALUE;
    }

    return Utilities.formatPercentageFromDecimal(rawNum);
}

// This is designed for single column/field value getter or formatters
export function formatBooleanYesNo(params) {
    return params.value ? 'Yes' : 'No';
}

export function formatCurrencyValue(value) {
    const val = value || 0;
    return Utilities.formatCurrency(val, 0);
}

export function formatCurrency(params) {
    const val = params.value || 0;
    return Utilities.formatCurrency(val, 0);
}


export function formatEmptyCell(params) {
    if (params.value == null || params.value === '') {
        return EMPTY_CELL_VALUE;
    }

    return params.value;
}

export function formatCellDate(params) {
    if (params.value != null && params.value !== '') {
        return Utilities.formatISODateUSA(new Date(params.value));
    }
    return EMPTY_CELL_VALUE;
}

export function formatSurrenderYears(params) {
    return params.value ? `${params.value}yrs` : 0;
}
