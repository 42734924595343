import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentEventsService {
  private productDetailsHeight: ReplaySubject<number> = new ReplaySubject(1);

  updateProductDetailsHeight(height: number) {
    this.productDetailsHeight.next(height);
  }

  getProductDetailsHeight(): ReplaySubject<number> {
    return this.productDetailsHeight;
  }
}
