import { Component } from '@angular/core';

@Component({
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent {

  constructor() { }
}
