import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Inject,
  OnDestroy,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AnnuityService } from '@common/services/annuity.service';
import { SpinnerService } from '@common/services/spinner.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  FirelightActivityRequestModel, FirelightActivityResponseModel,
  FL_ACORD_TYPES_CODES
} from '@product-marketplace/annuity-product/annuity-product-view/firelight/firelight-models/firelight-activity-request.model';
import { DOCUMENT } from '@angular/common';
import { ConfirmOrCancelModalComponent } from '@common/confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import {
  AnnuityOesRequestModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-oes-request.model';
import { UserService } from '@common/services/user.service';
import { ANNUITY_PRODUCT_TYPES } from '@product-marketplace/annuity-product/annuity-constants';
import { ISplitMenuItem } from '@common/components/split-button/split-button.component';
import { AnnuitySalesRep, AnnuityStateService } from '@product-marketplace/annuity-product/annuity-state.service';
import { ToastrService } from 'ngx-toastr';
import {
  ConfirmGoToSignaturesModalComponent
} from '@product-marketplace/annuity-product/annuity-product-view/firelight/confirm-exit-firelight-modal/confirm-go-to-signatures-modal.component';
import { Subscription } from 'rxjs';
import { disableSignatureStatuses, enableUnlockButtonStatuses } from '@product-marketplace/annuity-product/annuity-product-view/firelight/firelight-models/firelight-status-validation.model';
import { AnnuityMapperService } from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';

declare let FireLightAPI: any;

@Component({
  selector: 'app-firelight-embedded',
  templateUrl: './firelight-embedded.component.html',
  styleUrls: ['./firelight-embedded.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FirelightEmbeddedComponent implements OnDestroy, AfterViewInit {
  private FIRELIGHT_EAPP_SCRIPT_URL = '';
  private FIRELIGHT_EAPP_CSS_URL = '';
  private readonly FIRELIGHT_EAPP_SCRIPT_ID = 'eAppScript';
  private readonly FIRELIGHT_EAPP_CSS_ID = 'eAppCSS';

  private FIRELIGHT_ILLUSTRATION_SCRIPT_URL = '';
  private FIRELIGHT_ILLUSTRATION_CSS_URL = '';
  private readonly FIRELIGHT_ILLUSTRATION_ID = 'illustrationScript';
  private readonly FIRELIGHT_ILLUSTRATION_CSS_ID = 'illustrationCSS';

  private readonly FBG_CSS_URL = 'https://cdnjs.cloudflare.com/ajax/libs/flexboxgrid/6.3.1/flexboxgrid.min.css';
  private readonly FBG_CSS_ID = 'fbg';

  flattenedPageMap: any[];
  filteredNav: any[];
  currentPage = 0;
  showPreviousBtn = false;
  showNextBtn = true;

  readonly templates = {
    ILLUSTRATION: 'illustration',
    CLIENT_INTERFACE: 'eAppClientInterface'
  };

  readonly destinations = {
    ILLUSTRATION: 'firelightIllustrationApiDiv',
    CLIENT_INTERFACE: 'firelightClientInterfaceApiDiv'
  };

  loadInterface: string;

  // Init Params
  triggerClone = false; // this causes a clone action to be dispatched with the given reference ID
  errorToDisplay: string;

  // FireLight Illustration API Components
  @ViewChild('btnCreateApp') btnCreateApp: ElementRef;
  @ViewChild('btnUpdateApp') btnUpdateApp: ElementRef;
  @ViewChild('btnOpenQuickView') btnOpenQuickView: ElementRef;
  @ViewChild('firelightIllustrationApiDiv') firelightIllustrationApiDiv: ElementRef;


  // Firelight E-Application API Components
  @ViewChild('firelightClientInterfaceApiDiv') firelightClientInterfaceApiDiv: ElementRef;

  @ViewChild('btnProceedToSignatures') btnProceedToSignatures: ElementRef;
  @ViewChild('btnShowDocumentsDialog') btnShowDocumentsDialog: ElementRef;
  @ViewChild('btnUnlockApplication') btnUnlockApplication: ElementRef;

  percentComplete: number;
  firelightActivityRequestModel: FirelightActivityRequestModel;
  initialStatusLoaded = false;
  initialDrawerOpened = false;
  annuityOesRequestModel: AnnuityOesRequestModel;
  customActivityName: string;
  repSelected: AnnuitySalesRep;
  preventStatusRefreshOnClose = false;
  responseFromFirelight: FirelightActivityResponseModel;
  // only used for copy to new product
  financialAdviserId: string;
  isDisabledSignatureByStatus = false;
  isUnlockButtonEnable = false;
  activityStatusSubscription: Subscription;
  statusData: any;
  disableSignatureButtonMessage = 'You are no longer able to edit this application.\nThe application has been submitted for review and/or submitted to carrier.\nYou may unlock application by selecting “Unlock Application” under “Other Actions”.\nFor assistance, please contact Luma Support @844-844-LUMA.';

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private doc: Document,
              private dialogRef: MatDialogRef<FirelightEmbeddedComponent>,
              private dialog: MatDialog,
              private annuityService: AnnuityService,
              private annuityStateService: AnnuityStateService,
              private annuityMapperService: AnnuityMapperService,
              private userService: UserService,
              private spinnerService: SpinnerService) {
    this.preventStatusRefreshOnClose = this.data.preventStatusRefreshOnClose;
    this.errorToDisplay = annuityStateService.annuityBrokerDealerConfig.buySupportMsg ||
      'There was an error opening the application.\nPlease contact Luma’s Client Success Team for assistance.\n 844-844-5862';
    this.annuityOesRequestModel = this.data.annuityOesRequestModel;
    this.repSelected = this.data.repSelected;
    this.customActivityName = this.data.customActivityName?.trim();

    this.triggerClone = this.annuityOesRequestModel.triggerClone;
    this.responseFromFirelight = this.data.responseFromFirelight;
    // todo: cleanup probably, need to pass in reference accordingly


    this.loadInterface = this.data?.interface || this.templates.CLIENT_INTERFACE;
    if (this.loadInterface === this.templates.ILLUSTRATION) {
      // Hardcoded data
      this.firelightActivityRequestModel = FirelightActivityRequestModel.fromIllustrationSummary(this.annuityOesRequestModel?.annuityIllustrationSummaryModel);
    } else if (this.loadInterface === this.templates.CLIENT_INTERFACE) {
      this.firelightActivityRequestModel = FirelightActivityRequestModel.fromOesRequestModel(this.annuityOesRequestModel);
      this.firelightActivityRequestModel.acordTypeCode = FL_ACORD_TYPES_CODES.APPLICATION;
    }
  }

  otherActionItems: ISplitMenuItem[] = [
    {
      displayName: 'Upload Documents',
      val: 'docs'
    },
    {
      displayName: 'Print Documents',
      val: 'print'
    },
    {
      displayName: 'Unlock Application',
      val: 'unlock'
    }
  ];

  get firelightToken(): string {
    return this.responseFromFirelight?.AccessToken?.access_token;
  }

  get activityId(): string {
    return this.responseFromFirelight?.ActivityId ?? this.annuityOesRequestModel?.reference;
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const path = e.composedPath() as Array<any>;
    const firstAnchor = path.find(p => p.tagName?.toLowerCase() === 'a');
    if (firstAnchor && !firstAnchor.hasAttribute('routerlink')) {
      // Keep in for debugging
      // const href = firstAnchor.getAttribute('href');
      // console.log(href);
      e.preventDefault();
    }
  }

  private addEAppScript(dst, onLoad) {
    this.addCss(this.FBG_CSS_URL, this.FBG_CSS_ID, () => {
      this.addCss(this.FIRELIGHT_EAPP_CSS_URL, this.FIRELIGHT_EAPP_CSS_ID, () => {
        this.addScriptWithDestination(this.FIRELIGHT_EAPP_SCRIPT_URL, this.FIRELIGHT_EAPP_SCRIPT_ID, dst, onLoad);
      });
    });
  }

  private addIllustrationScript(dst, onLoad) {
    this.addCss(this.FBG_CSS_URL, this.FBG_CSS_ID, () => {
      this.addCss(this.FIRELIGHT_ILLUSTRATION_CSS_URL, this.FIRELIGHT_ILLUSTRATION_CSS_ID, () => {
        this.addScriptWithDestination(this.FIRELIGHT_ILLUSTRATION_SCRIPT_URL, this.FIRELIGHT_ILLUSTRATION_ID, dst, onLoad);
      });
    });
  }


  private addScriptWithDestination(src: string, dstId: string, srcId: string, onLoad) {
    const newSrc = this.buildScriptSrcUrl(src, srcId);
    this.addScript(newSrc, dstId, onLoad);
  }

  private addScript(src: string, id: string, onLoad) {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.onload = onLoad;
      document.head.appendChild(script);
    } else {
      onLoad();
    }
  }

  private addCss(href: string, cssId: string, onLoad) {
    if (!document.getElementById(cssId)) {
      const link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = href;
      link.media = 'all';
      link.onload = onLoad;
      document.head.appendChild(link);
    } else {
      onLoad();
    }
  }

  ngAfterViewInit() {
    this.spinnerService.showSpinner();
    this.annuityService.getFirelightBaseUrl().subscribe(baseUrl => {
      this.FIRELIGHT_EAPP_SCRIPT_URL = baseUrl + '/Resource/Scripts/eApp';
      this.FIRELIGHT_EAPP_CSS_URL = baseUrl + '/Resource/CSS/eApp';
      this.FIRELIGHT_ILLUSTRATION_SCRIPT_URL = baseUrl + '/Resource/Scripts/illustration';
      this.FIRELIGHT_ILLUSTRATION_CSS_URL = baseUrl + '/Resource/CSS/illustration';

      const oesVendorName = this.annuityOesRequestModel?.annuityProduct?.oesVendorName;
      const carrier = this.annuityOesRequestModel?.annuityProduct?.carrier;
      this.annuityService.getCarrierInfoFromVendor(oesVendorName, carrier).subscribe(carrierInfo => {
        this.firelightActivityRequestModel.carrierCode = carrierInfo[carrier];
        this.firelightActivityRequestModel.setActivityNameWithCarrier(carrier, this.customActivityName); // keep here because of timestamp
        this.firelightActivityRequestModel.productType = this.annuityOesRequestModel.annuityProduct.productType === ANNUITY_PRODUCT_TYPES.DIA_SPIA ? ANNUITY_PRODUCT_TYPES.DIA_SPIA : null;

        const actionType = this.activityId
          ? this.triggerClone
            ? 'copy'
            : 'continue'
          : 'create';

        this.activityStatusSubscription = this.annuityStateService.activityStatus.subscribe((status: any) => {
          this.statusData = status;
          this.isDisabledSignatureByStatus = disableSignatureStatuses.includes(status?.status);
          this.isUnlockButtonEnable = enableUnlockButtonStatuses.includes(status?.status);
          this.otherActionItems = [
            {
              displayName: 'Upload Documents',
              val: 'docs'
            },
            {
              displayName: 'Print Documents',
              val: 'print'
            },
            {
              displayName: 'Unlock Application',
              val: 'unlock',
              disabled: !this.isUnlockButtonEnable
            }
          ];
        });
        if (this.responseFromFirelight?.AccessToken?.access_token) {
          try {
            if (this.firelightIllustrationApiDiv) {
              this.subscribeToFirelightIllustrationEvents();
            } else if (this.firelightClientInterfaceApiDiv) {
              this.subscribeToEApplicationClientInterface();
            } else {
              this.spinnerService.hideSpinner();
              this.dialogRef.close();
            }
          } catch (error) {
            this.processErrorEvent(error);
          }
        } else {
          this.annuityService.getFireLightTokenAndNewActivity(this.firelightActivityRequestModel, actionType, this.activityId, this.repSelected?.userName).subscribe(
            {
              next: resp => {
                this.responseFromFirelight = resp;
                this.statusData = {
                  reference: this.statusData?.reference || resp?.ActivityId,
                  brokerDealerName: this.statusData?.brokerDealerName || resp?.OesBdName,
                };
                if (this.firelightIllustrationApiDiv) {
                  this.subscribeToFirelightIllustrationEvents();
                } else if (this.firelightClientInterfaceApiDiv) {
                  this.subscribeToEApplicationClientInterface();
                } else {
                  this.spinnerService.hideSpinner();
                  this.dialogRef.close();
                }
              }, error: error => {
                console.log('error', error);
                this.processErrorEvent(error);
              }
            }
          );
        }
      }, error => {
        this.processErrorEvent(error);
      });

    }, error => {
      this.processErrorEvent(error);
    });
  }

  private getOesBrokerName() {
    return this.annuityOesRequestModel.annuityUserInput.oesBrokerDealerName ?? this.responseFromFirelight.OesBdName;
  }

  private removeScripts(...scriptIds: string[]) {
    scriptIds.forEach((scriptId: string) => {
      const script = document.getElementById(scriptId);
      if (script) {
        script.parentElement.removeChild(script);
      }
    });
  }

  ngOnDestroy(): void {
    /*
    * This is the only way we can do Applications & Illustrations within the same instance
    * The Illustration script causes issues with E-Application inits
    * */
    this.removeScripts(this.FIRELIGHT_EAPP_SCRIPT_ID, this.FIRELIGHT_ILLUSTRATION_ID);
    this.toastr.clear();
    this.activityStatusSubscription.unsubscribe();
    this.annuityStateService.activityStatus.next(null);
  }

  onFirelightScriptLoad = (e) => {
  };

  subscribeToFirelightIllustrationEvents() {
    this.firelightIllustrationApiDiv.nativeElement.addEventListener('fireLightLoaded', () => {
      FireLightAPI?.init(this.firelightToken, this.activityId, !!this.activityId);
      FireLightAPI?.linkedApplicationEvent.subscribe(this.linkedApplicationChange);
      FireLightAPI?.errorEvent.subscribe(this.handleErrorEvent);
      FireLightAPI?.notificationsEvent.subscribe(this.handleNotificationEvent);
      FireLightAPI?.statusEvent.subscribe((status) => {
        this.getLatestActivityStatus();
        this.percentComplete = status.percentComplete;
        this.spinnerService.hideSpinner();
      });
      FireLightAPI?.navigationEvent.subscribe(this.navigationChange);
    });

    this.addIllustrationScript(this.destinations.ILLUSTRATION, this.onFirelightScriptLoad);
  }

  linkedApplicationChange = (linkedApp) => {
    console.log('linkedApplicationChange - linkedApp = ' + JSON.stringify(linkedApp));
    if (linkedApp.LastActionSuccess) {
      console.log('Success! The last action: ' + linkedApp.LastAction + ' occurred on ' + linkedApp.LastActionDate);
    } else {
      console.log('Failure! The last action: ' + linkedApp.LastAction + ' occurred on ' + linkedApp.LastActionDate + '. The error message it generated was: ' + linkedApp.LastActionError);
    }
  };

  handleErrorEvent = (err) => {
    // catch CORS error
    if (err.error?.ErrorType === 1) {
      this.processErrorEvent(err);
    } else {
      const errMsgElement = document.getElementById('errMessage');
      if (errMsgElement) {
        const msg = err.error.ApiSource + ' - ' + err.error.ErrorMessage;
        errMsgElement.innerHTML = 'Error message = ' + msg;
      }
    }
  };
  handleNotificationEvent = (notification) => {
    switch (notification.severity) {
      case 0: // info
        this.toastr.info(notification.message, notification.title, {
          timeOut: 30000,
          extendedTimeOut: 30000,
          progressBar: true
        });
        break;
      case 1: // warning
        this.toastr.warning(notification.message, notification.title, {
          disableTimeOut: true,
          closeButton: true
        });
        break;
      case 3: // error
        this.toastr.error(notification.message, notification.title, {
          disableTimeOut: true,
          closeButton: true
        });
        break;
    }
  };

  private processErrorEvent(error) {
    this.spinnerService.hideSpinner();
    this.dialogRef.afterClosed().subscribe(event => {
        if (event) {
          this.dialog.open(ConfirmOrCancelModalComponent, {
            data: {
              message: this.errorToDisplay,
              lumaContact: false,
              singleButton: true,
              title: 'Application Error'
            },
            panelClass: ['confirmation-dialog', 'l-w400']
          });
        }
      }
    );

    this.dialogRef.close(error);

  }

  getLatestActivityStatus() {
    if(!this.statusData?.reference || !this.statusData?.brokerDealerName) {
      return;
    }
    this.annuityService.getLatestAnnuityOrderStatus(this.statusData?.reference, this.statusData?.brokerDealerName).subscribe((data) => {
      if (data?.length === 1) {
        data.forEach(d => this.annuityMapperService.mapOrderEvent(d)); // map/mutate data
      }

      this.isDisabledSignatureByStatus = disableSignatureStatuses.includes(data[0]?.eventRows[0]?.status);
      this.isUnlockButtonEnable = enableUnlockButtonStatuses.includes(data[0]?.eventRows[0]?.status);
      this.otherActionItems = [
        {
          displayName: 'Upload Documents',
          val: 'docs'
        },
        {
          displayName: 'Print Documents',
          val: 'print'
        },
        {
          displayName: 'Unlock Application',
          val: 'unlock',
          disabled: !this.isUnlockButtonEnable
        }
      ];
    });
  }

  subscribeToEApplicationClientInterface() {
    const statusChange = (status) => {
      this.getLatestActivityStatus();
      if (!this.initialStatusLoaded) {
        this.annuityOesRequestModel.activityName = this.customActivityName;
        const annuityOrderRequestModel = this.annuityService.createInitialOrderEventPayload({
          annuityOesRequestModel: this.annuityOesRequestModel,
          repSelected: this.repSelected,
          annuityFirelightResponse: this.responseFromFirelight
        });
        this.annuityService.createInitialOrderEvent(annuityOrderRequestModel).subscribe(ret => {
          this.initialStatusLoaded = true;
          this.spinnerService.hideSpinner();
        }, err => {
          this.spinnerService.hideSpinner();
        });
      }
      this.percentComplete = status.percentComplete;
    };

    const signatureStatusChange = (sig) => {
      if (sig.succeeded) {
        this.annuityService.firelightSingleSignOn(this.activityId, this.getOesBrokerName()).subscribe(resp => {
          const { samlUrl, responseBody } = resp;

          const formPost = document.createElement('form');
          formPost.action = samlUrl;
          formPost.method = 'post';
          formPost.target = '_blank';

          const samlInput = document.createElement('input');
          samlInput.type = 'hidden';
          samlInput.name = 'SAMLResponse';
          samlInput.value = responseBody;

          const relayStateInput = document.createElement('input');
          relayStateInput.type = 'hidden';
          relayStateInput.name = 'RelayState';
          relayStateInput.value = 'eSign';

          formPost.appendChild(samlInput);
          // commented out:
          // firelight had a defect on their side that made it impossible for people to visit application once signed
          // uncomment once they fix
          // formPost.appendChild(relayStateInput);
          document.body.appendChild(formPost);
          formPost.submit();
          document.body.removeChild(formPost);
        }, error => console.error(error));
      }
    };

    this.firelightClientInterfaceApiDiv.nativeElement.addEventListener('fireLightLoaded', () => {
      FireLightAPI.init(this.firelightToken, this.activityId, !!this.activityId);
      FireLightAPI.statusEvent.subscribe(statusChange);
      FireLightAPI.signatureStatusEvent.subscribe(signatureStatusChange);
      FireLightAPI.errorEvent.subscribe(this.handleErrorEvent);
      FireLightAPI?.notificationsEvent.subscribe(this.handleNotificationEvent);
      FireLightAPI?.navigationEvent.subscribe(this.navigationChange);
    });

    this.addEAppScript(this.destinations.CLIENT_INTERFACE, this.onFirelightScriptLoad);

  }

  private buildScriptSrcUrl(scriptUrl, renderDestination) {
    return `${scriptUrl}?destination=${renderDestination}`;
  }

  private renderPdf(event?) {
    FireLightAPI?.renderPDF();
  }

  private showUnlockDialog() {
    if (this.percentComplete === 100) {
      FireLightAPI?.showUnlockDialog();
    }
  }

  private showDocumentsDialog() {
    FireLightAPI?.showDocumentsDialog();
  }

  // TODO - Need more information from FL crew
  private proceedToSignatures() {
    if (this.percentComplete === 100) {
      this.dialog.open(ConfirmGoToSignaturesModalComponent, { panelClass: ['no-padding-dialog', 'l-w500'] }).afterClosed().subscribe(confirm => {
        if (confirm === 'confirm') {
          FireLightAPI?.proceedToSignatures();
        }
      });
    }
  }

  navigateToPage(pageNumber: number) {
    if (pageNumber !== this.currentPage) {
      FireLightAPI?.navigateToPage(pageNumber);
    }
  }

  navigationChange = (nav) => {
    this.flattenedPageMap = nav.navigationGraph.flatMap(navGroup => {
      return navGroup.IsEnabled ? navGroup.Pages.filter(page => page.IsEnabled) : [];
    });

    this.filteredNav = nav.navigationGraph.filter(navGroup => {
      return navGroup.IsEnabled && navGroup.Pages.filter(page => page.IsEnabled);
    }).map(navGroup => {
      return {
        ...navGroup,
        Pages: navGroup.Pages.filter(page => page.IsEnabled)
      };
    });

    const mapLength = this.flattenedPageMap.length;

    this.currentPage = nav.pageNumber;
    if (mapLength > 0) {
      const lastPageNumber = this.flattenedPageMap[mapLength - 1].PageNumber;
      const firstPageNumber = this.flattenedPageMap[0].PageNumber;

      this.showPreviousBtn = this.currentPage !== firstPageNumber;
      this.showNextBtn = this.currentPage !== lastPageNumber;

      if (!this.initialDrawerOpened) {
        this.initialDrawerOpened = false;
      }

    } else {
      this.showPreviousBtn = false;
      this.showNextBtn = false;
    }
  };

  // TODO - Use navigation calls
  previousPage() {
    if (this.showPreviousBtn) {
      FireLightAPI.save();
      FireLightAPI?.navigateToPagePrevious();
    }
  }

  // TODO - Use navigation calls
  nextPage() {
    if (this.showNextBtn) {
      FireLightAPI.save();
      FireLightAPI?.navigateToPageNext();
    }
  }

  private openQuickView() {
    FireLightAPI?.openQuickView();
  }

  private createApplication() {
    FireLightAPI?.createApplication();
  }

  private updateApplication() {
    FireLightAPI?.updateApplication();
  }

  pageNumber(page) {
    return page.PageNumber;
  }

  menuItemSelected(item: ISplitMenuItem) {
    switch (item.val) {
      case 'docs':
        this.showDocumentsDialog();
        break;
      case 'print':
        this.renderPdf();
        break;
      case 'unlock':
        FireLightAPI?.showUnlockDialog();
        break;
    }
  }

  close() {
    let message = 'Are you sure you would like to exit the Order Entry Screen?';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['confirmation-dialog', 'l-w500'];
    if (!!this.activityId) {
      message += `\nContinue in-progress applications by accessing Annuity 'Orders' in the top navigation under Marketplace.`;
    }
    dialogConfig.data = {
      title: 'Confirm Exit',
      message
    };
    this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig).afterClosed().subscribe(
      confirm => {
        if (confirm === 'confirm') {
          // trigger a refresh, but don't do anything with the response. This is just to trigger an update to keep backend firelight data in sync
          if (!this.preventStatusRefreshOnClose) {
            this.annuityService.getLatestAnnuityOrderStatus(this.activityId, this.getOesBrokerName()).subscribe(data => {
            });
          }
          this.dialogRef.close();
        }
      }
    );
  }
}
