import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@common/services/user.service';
import { SpinnerService } from '@common/services/spinner.service';
import { MarketplaceProductService } from '@common/services/marketplace-product.service';
import { Subscription } from 'rxjs';
import { OrderFormService } from '@product-marketplace/structured-products/order-purchase/order-form.service';

export interface OrderTicketParams {
  orderId?: number;
  productId: string;
  programId: number;
  tradeDeskId: number;
}

export enum stpActiveStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR'
}
@Component({
  selector: 'app-order-purchase-dialog',
  templateUrl: './order-purchase-dialog.component.html',
  styleUrls: ['./order-purchase-dialog.component.scss']
})
export class OrderPurchaseDialogComponent implements OnInit, OnDestroy {

  formData: any;
  permissions: any;
  showError: any;
  stpStatusActive: stpActiveStatus;
  updateFormDataSub: Subscription;
  orderTicketParams: OrderTicketParams;

  constructor(private userService: UserService,
              private cdr: ChangeDetectorRef,
              private marketplaceProductService: MarketplaceProductService,
              private dialogRef: MatDialogRef<OrderPurchaseDialogComponent>,
              private spinnerService: SpinnerService,
              private orderFormService: OrderFormService,
              @Inject(MAT_DIALOG_DATA) data: OrderTicketParams) {
    this.orderTicketParams = data;
  }

  ngOnInit() {
    this.permissions = this.userService.getUser()?.permissions;
    this.getOrderFormData();
    this.getStpStatus();
    this.updateFormDataSub = this.orderFormService.$orderFormData.subscribe(data => this.formData = data);
  }

  getOrderFormData() {
    this.spinnerService.showSpinner();
    if(this.orderTicketParams?.orderId) {
      this.marketplaceProductService.getOrderFormEdit(this.orderTicketParams?.orderId).subscribe(data => {
        if (!data) {
          this.showError = true;
        } else {
          this.formData = data;
        }
        this.spinnerService.hideSpinner();
        this.cdr.detectChanges();
      }, err => {
        this.showError = true;
        this.spinnerService.hideSpinner();
      });
    } else {
      this.marketplaceProductService.getOrderForm(this.orderTicketParams?.productId, this.orderTicketParams?.programId, this.orderTicketParams?.tradeDeskId).subscribe(data => {
        if (!data) {
          this.showError = true;
        } else {
          this.formData = data;
        }
        this.spinnerService.hideSpinner();
      }, err => {
        this.showError = true;
        this.spinnerService.hideSpinner();
      });
    }
  }

  getStpStatus() {
    this.marketplaceProductService.getSTPActiveStatus(this.orderTicketParams?.tradeDeskId, this.orderTicketParams?.programId).subscribe( (res: boolean) => {
      this.stpStatusActive = res ? stpActiveStatus.ACTIVE : stpActiveStatus.INACTIVE;
    }, err => {
      this.stpStatusActive = stpActiveStatus.ERROR;
    });
  }
  close(reset: string | null = null) {
    this.dialogRef.close(reset);
  }

  ngOnDestroy() {
    this.updateFormDataSub?.unsubscribe();
  }
}
